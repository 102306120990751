import { useCallback, useMemo } from 'react';
import { CARD_COVERS_TYPE } from '@atlassian/jira-business-constants/src/index.tsx';
import { FIELDS_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import {
	SUBTASKS_TYPE,
	DUE_DATE_TYPE,
	ASSIGNEE_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	PRIORITY_TYPE,
	SUMMARY_TYPE,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { BoardCardField } from '../../common/types.tsx';

// System fields' ids are the same as their types so using the type constants here
export const INITIAL_CARD_FIELDS_IDS: Set<string> = new Set([
	SUMMARY_TYPE,
	STATUS_TYPE,
	'key',
	ISSUE_TYPE,
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	SUBTASKS_TYPE,
	PRIORITY_TYPE,
	LABELS_TYPE,
	CARD_COVERS_TYPE,
]);

export const useFieldsPreference = () => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const [fieldPreferences, setFieldPreferences] = useViewPreference(FIELDS_PREFERENCE) as [
		BoardCardField[] | string[],
		(fields: BoardCardField[] | string[]) => void,
	];

	const fieldIds = useMemo(() => {
		const defaultValue = INITIAL_CARD_FIELDS_IDS;
		if (fieldPreferences == null || !Array.isArray(fieldPreferences)) {
			return defaultValue;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return new Set(fieldPreferences as string[]);
	}, [fieldPreferences]);

	const setFieldIds = useCallback(
		(value: Set<string>) => setFieldPreferences(Array.from(value)),
		[setFieldPreferences],
	);

	return [fieldIds, setFieldIds] as const;
};

export const useSelectedFields = (): Record<string, boolean> => {
	const [fieldIds] = useFieldsPreference();

	return useMemo(() => {
		const selectedFields: Record<string, boolean> = {};
		fieldIds.forEach((fieldId) => {
			selectedFields[fieldId] = true;
		});

		return selectedFields;
	}, [fieldIds]);
};
