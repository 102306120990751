import React, { useEffect, useMemo, useState, memo } from 'react';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { StatusCategoryIds } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Group, StatusGroup } from '../../../../../common/types.tsx';
import {
	useConfettiColumns,
	matchConfettiColumn,
} from '../../../../../controllers/column-confetti/index.tsx';
import { boardConfettiEntrypoint } from './entrypoint.tsx';

type Props = {
	group: Group;
};

const isStatusGroup = (group: Group): group is StatusGroup => group.type === GROUP_BY_STATUS;

const entryPointParams = {};
const ColumnConfettiAsync = ({ group }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		boardConfettiEntrypoint,
		entryPointParams,
	);
	const [animating, setAnimating] = useState(false);
	const { confettiColumns, removeConfettiColumn } = useConfettiColumns();

	const showConfetti =
		matchConfettiColumn(group.name) ||
		(isStatusGroup(group) && group.statusCategoryId === StatusCategoryIds.done);

	useEffect(() => {
		if (confettiColumns.has(group.id)) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'boardConfetti created', {
				columnType: matchConfettiColumn(group.name) ? 'emoji' : 'done',
				columnId: group.id,
			});

			setAnimating(true);
			removeConfettiColumn(group.id);
		}
	}, [confettiColumns, createAnalyticsEvent, group.id, group.name, removeConfettiColumn]);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (animating) {
			entryPointActions.load();
		}
	}, [animating, entryPointActions]);

	const runtimeProps = useMemo(
		() => ({
			onConfettiComplete: () => setAnimating(false),
		}),
		[setAnimating],
	);

	if (!showConfetti || !animating) {
		return null;
	}

	return (
		<JiraEntryPointContainer
			id="jwm-board-confetti"
			packageName="jiraWorkManagementBoard"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};

export default memo(ColumnConfettiAsync);
