import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import PeopleGroupIconLegacy from '@atlaskit/icon/glyph/people-group';
import {
	MULTI_GROUP_CF_TYPE,
	GROUP_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getGroupIcons: GetIconFunction = (size, spacing) => ({
	[GROUP_CF_TYPE]: (
		<PeopleGroupIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={PeopleGroupIconLegacy}
		/>
	),
	[MULTI_GROUP_CF_TYPE]: (
		<PeopleGroupIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={PeopleGroupIconLegacy}
		/>
	),
});
