import React from 'react';
import GoalIcon from '@atlaskit/icon/core/goal';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { GOALS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getGoalsIcons: GetIconFunction = (size, spacing) => ({
	[GOALS_CF_TYPE]: (
		<GoalIcon
			label=""
			spacing={spacing}
			LEGACY_size={size}
			color={
				isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')
					? 'currentColor'
					: token('color.icon')
			}
		/>
	),
});
