import { useEffect, useMemo } from 'react';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { SHOULD_SHOW_CONFIGURATION_MODAL } from '../../constants.tsx';

/**
 * Hook that returns whether the configuration modal should be shown or not.
 * @returns {boolean} - Returns true if the configuration modal should be shown, false otherwise.
 */
export const useShouldShowConfigurationModal = () => {
	const sessionStorageVal = useMemo(
		() => sessionStorageProvider.get(SHOULD_SHOW_CONFIGURATION_MODAL),
		[],
	);

	useEffect(() => {
		if (sessionStorageVal) {
			sessionStorageProvider.remove(SHOULD_SHOW_CONFIGURATION_MODAL);
		}
	}, [sessionStorageVal]);

	if (sessionStorageVal === undefined) {
		return false;
	}

	return sessionStorageVal === true;
};
