import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Sorting } from '../../../sorting/types.tsx';
import type { TransformedRadioSelectField } from '../types.tsx';

const getRadioSelectSorting: Sorting<TransformedRadioSelectField> = (field) =>
	field.value ? field.value.value : '';

export const radioSelectSortingHandler = {
	[RADIO_BUTTONS_CF_TYPE]: getRadioSelectSorting,
};
