import { useMemo, useCallback } from 'react';
import { ISSUEKEY_REGEX } from '@atlassian/jira-common-match-jira-url/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SIMPLE_SEARCH_PARAM } from '../../constants.tsx';

const sanitize = (value: string) =>
	value.replace(/\\/g, '\\\\').replace(/'/g, "\\'").replace(/\t/g, ' ');

const issueKeyRegex = new RegExp(`^${ISSUEKEY_REGEX}$`);
const japaneseAndChineseRegex =
	/[\p{Script_Extensions=Hiragana}\p{Script_Extensions=Katakana}\p{Script_Extensions=Han}]/u;

export const useSimpleSearch = (): [string, (searchText?: string | null | undefined) => void] => {
	const [simpleSearch, persistSimpleSearch] = useQueryParam(SIMPLE_SEARCH_PARAM);

	const setSimpleSearch = useCallback(
		(searchText?: string | null) => {
			searchText != null && searchText.trim() !== ''
				? persistSimpleSearch(searchText)
				: persistSimpleSearch(undefined);
		},
		[persistSimpleSearch],
	);
	return [simpleSearch ?? '', setSimpleSearch];
};

export const getTextHighlight = (text: string | null): string[] => {
	if (text == null) {
		return [];
	}
	return text.split(' ').filter((word) => word !== '');
};

export const useSimpleSearchHighlight = (): string[] => {
	const [simpleSearch] = useSimpleSearch();

	return useMemo(() => getTextHighlight(simpleSearch), [simpleSearch]);
};

export const getSearchTextJQL = (text: string) => {
	if (text == null || text.trim() === '') {
		return '';
	}

	let search = sanitize(text);

	if (issueKeyRegex.test(text)) {
		return `(summary ~ '${search}*' OR description ~ '${search}*' OR key = '${search}')`;
	}

	// if the text does not contain Chinese or Japanese characters (which are not tokenized),
	// and does not end with a space character
	// make it a wildcard search
	if (!japaneseAndChineseRegex.test(text) && !text.endsWith(' ')) {
		search = `${search}*`;
	}

	return `(summary ~ '${search}' OR description ~ '${search}')`;
};

export const useSearchText = (text: string) => {
	const jql = useMemo(() => getSearchTextJQL(text), [text]);
	const highlight = useMemo(() => getTextHighlight(text), [text]);

	return {
		jql,
		highlight,
	};
};

export const useSimpleSearchJQL = () => {
	const [simpleSearch] = useSimpleSearch();

	const jql = useMemo(() => getSearchTextJQL(simpleSearch), [simpleSearch]);

	const addSimpleSearchToJQL = useCallback(
		(currentJQL: string | null) => {
			if (jql === '') {
				return currentJQL;
			}

			if (currentJQL == null) {
				return jql;
			}

			return `${currentJQL} AND ${jql}`;
		},
		[jql],
	);

	return {
		jql,
		isSearching: jql !== '',
		addSimpleSearchToJQL,
	};
};

export default useSimpleSearch;
