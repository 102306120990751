import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueLinksTooltipLabel: {
		id: 'issue-links-stats-business.issue-links-tooltip-label',
		defaultMessage:
			'{issueLinksCount} linked {issueLinksCount, plural, one {issue} other {issues}}',
		description: 'Tooltip for linked issues stats',
	},
	genericMaxIssueLinksStatsTooltipLabel: {
		id: 'issue-links-stats-business.generic-max-issue-links-stats-tooltip-label',
		defaultMessage: '{maxIssueLinksStatsCount}+ linked issues',
		description: 'Tooltip for linked issues stats',
	},
	issueLinksPopup: {
		id: 'issue-links-stats-business.issue-links-popup',
		defaultMessage: 'Linked issues pop up',
		description: 'Label for linked issues popup',
	},
	issueLinksTooltipLabelIssueTermRefresh: {
		id: 'issue-links-stats-business.issue-links-tooltip-label-issue-term-refresh',
		defaultMessage:
			'{issueLinksCount} linked {issueLinksCount, plural, one {work item} other {work items}}',
		description: 'Tooltip for linked issues stats',
	},
	genericMaxIssueLinksStatsTooltipLabelIssueTermRefresh: {
		id: 'issue-links-stats-business.generic-max-issue-links-stats-tooltip-label-issue-term-refresh',
		defaultMessage: '{maxIssueLinksStatsCount}+ linked work items',
		description: 'Tooltip for linked issues stats',
	},
	issueLinksPopupIssueTermRefresh: {
		id: 'issue-links-stats-business.issue-links-popup-issue-term-refresh',
		defaultMessage: 'Linked work items pop up',
		description: 'Label for linked issues popup',
	},
});
