import gqlTagGira from 'graphql-tag';

export const CREATE_ISSUE_FRAGMENT = gqlTagGira`
fragment CreateIssueFragment on JiraBusinessCreateIssuePayload {
  itemIdentifiers: item @connection (key: "issue-create-ids") {
    issueId
    issueKey: fieldsByIdOrAlias(idsOrAliases: ["issuekey"]) {
      ... on JiraSingleLineTextField {
          text
      }
    }
  }
  success
  errors {
    message
    extensions {
      ...on MutationErrorExtension {
        statusCode
        errorType
      }
    }
  }
}`;
