import React, {
	type ReactNode,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import { useBusinessEntity } from '@atlassian/jira-business-entity-common/src/controllers/business-entity-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { setPreference, getPreference } from '../../services/view-preferences/index.tsx';
import type { Preference } from '../../types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Store = { [key: string]: any };

export type UpdateQueryParamAction = 'push' | 'replace' | 'none';

type ContextType = {
	store: Store;
	setPreferenceValue: <T>(
		preference: Preference<T>,
		value: T | undefined,
		action: UpdateQueryParamAction,
	) => void;
};

type ProviderProps = {
	view: JWMView;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	preferences: Preference<any>[];
	persistChanges?: boolean;
	children: ReactNode;
};

type UpdateQueryParam = (
	params: { [key: string]: string | undefined },
	action: UpdateQueryParamAction,
) => void;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const ViewPreferencesContext = createContext<ContextType>(null as any);

export const ViewPreferencesProvider = ({
	view,
	preferences,
	children,
	persistChanges = true,
}: ProviderProps) => {
	const { data: entity } = useBusinessEntity();
	const [{ query }, routerActions] = useRouter();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { updateQueryParam } = routerActions as unknown as { updateQueryParam: UpdateQueryParam };
	const prevQuery = usePreviousWithInitial(query);

	const [store, setStore] = useState<Store>(() =>
		preferences.reduce((acc, preference) => {
			let value;
			const { id, queryParameter } = preference;

			// if the URL has a preference value set
			// we prioritize those values over the persisted ones
			if (fg('jira-view-preferences-overwrite-via-url')) {
				// a preference value of an empty string, indicates that any value in local storage should be ignored and cleared
				const rawQueryValue = queryParameter && queryParameter.deserialize(query);
				const hasQueryValue = rawQueryValue !== undefined;

				if (hasQueryValue) {
					// if the value matches the clearable value, we treat the value as `undefined`
					value = rawQueryValue === '' ? undefined : rawQueryValue;
				} else if (persistChanges) {
					value = preference.deserialize(getPreference(entity.id, view, preference.id));
				}
			} else {
				const queryValue = queryParameter && queryParameter.deserialize(query);
				if (queryValue) {
					value = queryValue;
				} else if (persistChanges) {
					// if the view is persisting changes, restore the preferences from local storage
					value = preference.deserialize(getPreference(entity.id, view, preference.id));
				}
			}

			acc[id] = value;

			return acc;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		}, {} as Store),
	);

	const setPreferenceValue = useCallback(
		<T,>(preference: Preference<T>, value: T | undefined, action: UpdateQueryParamAction) => {
			// set the new preference value in the store
			setStore((prevStore) => {
				const newStore = { ...prevStore, [preference.id]: value };

				return newStore;
			});

			// if the preference serialises to the URL, update the URL
			if (preference.queryParameter && action !== 'none') {
				updateQueryParam(preference.queryParameter.serialize(value), action);
			}

			// if the view is persisting changes, update the preference in local storage
			if (persistChanges) {
				const serializedValue = preference.serialize(value);

				setPreference(entity.id, view, preference.id, serializedValue);
			}
		},
		[persistChanges, updateQueryParam, entity.id, view],
	);

	useEffect(() => {
		// if the view is persisting changes, on initial mount
		// check all preferences that serialise to the URL, and set their values to the URL
		if (!persistChanges) {
			return;
		}

		let restoredQuery: { [key: string]: string | undefined } = {};

		for (const preference of preferences) {
			const value = store[preference.id];
			if (value && preference.queryParameter) {
				restoredQuery = {
					...restoredQuery,
					...preference.queryParameter.serialize(value),
				};
			}
		}

		updateQueryParam({ ...restoredQuery, ...query }, 'replace');

		// only run this effect on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// this effect updates the store value only when the query has changed
		// we shouldn't run it when the component is mounting the first time
		// or when the query hasn't changed
		if (!isEqual(prevQuery, query)) {
			// update the store if the query is updated
			const newState = preferences.reduce((acc, preference) => {
				const { id, queryParameter } = preference;
				if (queryParameter) {
					const queryValue = queryParameter.deserialize(query);
					acc[id] = queryValue;
				}
				return acc;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			}, {} as Store);
			setStore({ ...store, ...newState });
		}
	}, [preferences, query, store, entity.id, view, prevQuery]);

	const contextValue = useMemo(() => ({ store, setPreferenceValue }), [store, setPreferenceValue]);

	return (
		<ViewPreferencesContext.Provider value={contextValue}>
			{children}
		</ViewPreferencesContext.Provider>
	);
};

export const useViewPreference = <T,>(preference: Preference<T>) => {
	const { store, setPreferenceValue } = useContext(ViewPreferencesContext);

	const setValue = useCallback(
		(value: T | undefined, action: UpdateQueryParamAction = 'push') => {
			setPreferenceValue<T>(preference, value, action);
		},
		[preference, setPreferenceValue],
	);

	const value: T | undefined = store[preference.id];

	return [value, setValue] as const;
};
