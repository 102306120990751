import React, { type ReactNode, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { di } from 'react-magnetic-di';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useIssueTypesAndFieldsService,
	IssueTypesAndFieldsContextView,
	IssueTypesAndFieldsContextCreate,
} from '../../services/issue-types-and-fields/index.tsx';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

// track Apollo cache watchers 1 sec after availableFields query finishes to make sure UI is fully rendered
const TRACKING_TIMEOUT = 1000;

export const IssueTypesAndFieldsContext = ({ children }: { children: ReactNode }) => {
	di(setTimeout);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const client = useApolloClient();

	const IssueTypesAndFieldsViewContextProps = useIssueTypesAndFieldsService({
		issueOperation: 'VIEW',
	});
	const IssueTypesAndFieldsCreateContextProps = useIssueTypesAndFieldsService({
		issueOperation: 'CREATE',
	});

	const loading =
		IssueTypesAndFieldsViewContextProps.loading || IssueTypesAndFieldsCreateContextProps.loading;

	useEffect(() => {
		if (loading) {
			return noop;
		}
		const timeoutId = setTimeout(() => {
			// tracking the number of Apollo cache watches
			// the lower the number, the faster UI
			fireTrackAnalytics(createAnalyticsEvent({}), 'apolloCacheWatches created', {
				// @ts-expect-error - TS2551 - Property 'watches' does not exist on type 'ApolloCache<object>'. Did you mean 'watch'?
				watches: client?.cache?.watches?.size,
			});
		}, TRACKING_TIMEOUT);

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createAnalyticsEvent, loading]);

	return (
		<IssueTypesAndFieldsContextView.Provider value={IssueTypesAndFieldsViewContextProps}>
			<IssueTypesAndFieldsContextCreate.Provider value={IssueTypesAndFieldsCreateContextProps}>
				{children}
			</IssueTypesAndFieldsContextCreate.Provider>
		</IssueTypesAndFieldsContextView.Provider>
	);
};
