import gqlTagGira from 'graphql-tag';

export const UPDATE_ASSIGNEE_MUTATION = gqlTagGira`
mutation BusinessBoardUpdateAssignee($input: JiraUpdateSingleSelectUserPickerFieldInput!) {
  jira {
    updateSingleSelectUserPickerField(input: $input) {
      success
      errors {
        message
        extensions {
          statusCode
          errorType
          __typename
        }
        __typename
      }
      __typename
    }
  }
}`;
