import {
	createContext,
	useContext,
	useMemo,
	useEffect,
	useState,
	useCallback,
	useRef,
} from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import type { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
	AVAILABLE_FIELDS_QUERY_START,
	AVAILABLE_FIELDS_QUERY_END,
} from '@atlassian/jira-business-performance/src/constants.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import type {
	JiraBusinessAvailableFieldsListView,
	JiraBusinessAvailableFieldsListViewVariables,
} from './__generated_apollo__/JiraBusinessAvailableFieldsListView';
import { DEFAULT } from './constants.tsx';
import type {
	UseIssueTypesAndFieldsInput,
	UseIssueTypesAndFieldsReturn,
	UpdateCache,
} from './types.tsx';
import { transformResult, transformResultToTypesWithFields, JWMQueryByRouteMap } from './utils.tsx';

export const PROJECT_NOT_FOUND_ERROR_MESSAGE = 'PROJECT_NOT_FOUND';
export const VIEW_PROJECT_PERMISSION_ERROR_MESSAGE = 'VIEW_PROJECT_PERMISSION_ERROR';

const isProjectPermissionError = (error: ApolloError) =>
	error.message.indexOf(PROJECT_NOT_FOUND_ERROR_MESSAGE) > -1 ||
	error.message.indexOf(VIEW_PROJECT_PERMISSION_ERROR_MESSAGE) > -1 ||
	// @ts-expect-error TS2339 - Property 'extensions' does not exist on type 'ApolloError'
	error.extensions?.statusCode === 403 ||
	// @ts-expect-error TS2339 - Property 'extensions' does not exist on type 'ApolloError'
	error.extensions?.statusCode === 404;

export const useIssueTypesAndFieldsQuery = (input: UseIssueTypesAndFieldsInput) => {
	const [routerState] = useRouter();
	const { projectKey } = getProjectKeyId(routerState?.match, routerState?.query);

	const variables = useMemo(
		() => ({
			projectKey,
			issueOperation:
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				input.issueOperation as JiraBusinessAvailableFieldsListViewVariables['issueOperation'],
			first: 100,
		}),
		[input.issueOperation, projectKey],
	);

	const { query, view } =
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "classic-business-form": { readonly view: "Form"; readonly query: DocumentNode; }; readonly "classic-business-form-submit": { readonly view: "FormSubmit"; readonly query: DocumentNode; }; ... 8 more ...; readonly default: { ...; }; }'.
		JWMQueryByRouteMap?.[routerState?.route?.name] ?? JWMQueryByRouteMap[DEFAULT];

	return useMemo(
		() => ({
			loading: false,
			query,
			view,
			variables,
		}),
		[query, view, variables],
	);
};

export const useIssueTypesAndFieldsCache = (input: UseIssueTypesAndFieldsInput): UpdateCache => {
	const client = useApolloClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { variables: queryVariables, query } = useIssueTypesAndFieldsQuery(input);
	const analyticsTaskName = 'useIssueTypesAndFieldsCacheApolloCacheActions';

	return useCallback<UpdateCache>(
		({ findField, updateItem }) => {
			const getCacheData = () => {
				try {
					const read = client.readQuery<
						JiraBusinessAvailableFieldsListView,
						JiraBusinessAvailableFieldsListViewVariables
					>({
						query,
						variables: queryVariables,
					});

					fireOperationalAnalytics(createAnalyticsEvent({}), 'apolloCacheRead taskSuccess', {
						task: analyticsTaskName,
					});

					return read;
				} catch (e) {
					fireErrorAnalytics({
						error: e instanceof Error ? e : new Error('Unknown cache read error'),
						meta: {
							id: 'useIssueTypesAndFieldsCache',
							packageName: 'jiraBusinessEntityProject',
							teamName: 'wanjel',
						},
						sendToPrivacyUnsafeSplunk: true,
						skipSentry: true,
						attributes: {
							task: analyticsTaskName,
							query: JSON.stringify(query),
							variables: JSON.stringify(queryVariables),
							eventName: 'apolloCacheRead taskFail',
							error: JSON.stringify(e),
						},
					});
					return null;
				}
			};

			const cacheData = getCacheData();

			const existingEdges = cacheData?.jiraBusinessAvailableFields?.edges;
			if (existingEdges == null) {
				return;
			}
			let shouldUpdate = false;
			const newEdges = existingEdges.map((edge) => {
				const existingFields = edge?.node?.fields?.edges;
				if (existingFields == null) {
					return edge;
				}

				const itemToUpdateIndex =
					existingFields.findIndex((fieldEdge) =>
						findField ? findField(fieldEdge?.node) : false,
					) ?? undefined;
				const existingField = existingFields[itemToUpdateIndex]?.node;

				if (itemToUpdateIndex === undefined || itemToUpdateIndex === -1 || existingField == null) {
					return edge;
				}

				const itemOverride = updateItem(existingField);
				if (itemOverride === undefined) {
					return edge;
				}

				const newFields = [...existingFields];
				newFields[itemToUpdateIndex] = {
					node: itemOverride,
					__typename: 'JiraIssueFieldEdge',
				};

				shouldUpdate = true;
				return {
					...edge,
					node: {
						...(edge?.node || {}),
						fields: {
							edges: newFields,
							__typename: 'JiraIssueFieldConnection',
						},
					},
				};
			});

			if (!shouldUpdate) {
				return;
			}
			const newData = {
				...cacheData,
				jiraBusinessAvailableFields: {
					...(cacheData?.jiraBusinessAvailableFields || {}),
					edges: newEdges,
				},
			};
			try {
				client.writeQuery({
					query,
					variables: queryVariables,
					// @ts-expect-error - TS2345 - Argument of type '{ query: any; variables: { projectId: number | undefined; issueOperation: IssueOperation; first: number; } | undefined; notifyOnNetworkStatusChange: boolean; data: { jiraBusinessAvailableFields: { ...; }; }; }' is not assignable to parameter of type 'WriteQueryOptions<{ jiraBusinessAvailableFields: { edges: (JiraBusinessAvailableFieldsListView_jiraBusinessAvailableFields_edges | { ...; } | null)[]; __typename?: "JiraBusinessAvailableFieldsConnection" | undefined; }; }, { ...; }>'.
					notifyOnNetworkStatusChange: true,
					data: newData,
				});
				fireOperationalAnalytics(createAnalyticsEvent({}), 'apolloCacheWrite taskSuccess', {
					task: analyticsTaskName,
				});
			} catch (e) {
				fireErrorAnalytics({
					error: e instanceof Error ? e : new Error('Unknown cache write error'),
					meta: {
						id: 'useIssueTypesAndFieldsCache',
						packageName: 'jiraBusinessEntityProject',
						teamName: 'wanjel',
					},
					sendToPrivacyUnsafeSplunk: true,
					skipSentry: true,
					attributes: {
						task: analyticsTaskName,
						query: JSON.stringify(query),
						variables: JSON.stringify(queryVariables),
						eventName: 'apolloCacheWrite taskFail',
						error: JSON.stringify(e),
					},
				});
			}
		},
		[client, createAnalyticsEvent, query, queryVariables],
	);
};

/* Before using this hook read the ticket https://jbusiness.atlassian.net/browse/JET-2293 and be sure that is not making any regression in the performance */
export const useIssueTypesAndFieldsService = (
	input: UseIssueTypesAndFieldsInput,
): UseIssueTypesAndFieldsReturn & {
	refetch: () => Promise<ApolloQueryResult<JiraBusinessAvailableFieldsListView>>;
} => {
	const [hasStartedFetchingFields, setHasStartedFetchingFields] = useState(false);
	const fetchAttempts = useRef(0);

	const {
		variables: queryVariables,
		loading: isQueryLoading,
		query,
		view,
	} = useIssueTypesAndFieldsQuery(input);

	const [getAvailableFields, { data, called, loading, error, refetch: refetchQuery }] =
		useLazyQuery<JiraBusinessAvailableFieldsListView, JiraBusinessAvailableFieldsListViewVariables>(
			query,
			{
				variables: queryVariables,
				errorPolicy: 'all',
				fetchPolicy: 'cache-first', // do not call again for the same variables
				onError: (apolloError) => {
					markViewMetric(view.toLowerCase(), AVAILABLE_FIELDS_QUERY_END);
					// do not fire error if user doesn't have project permissions
					if (isProjectPermissionError(apolloError)) {
						return;
					}

					fireErrorAnalytics({
						meta: {
							id: `availableFields${view}ViewQuery`,
							packageName: 'jiraBusinessEntityProject',
							teamName: 'wanjel',
						},
						attributes: {
							message: `Failed to fetch available fields in ${view} view`,
						},
						error: apolloError,
						sendToPrivacyUnsafeSplunk: true,
					});
				},
				onCompleted: (onCompletedData: JiraBusinessAvailableFieldsListView) => {
					markViewMetric(view.toLowerCase(), AVAILABLE_FIELDS_QUERY_END);

					if (onCompletedData?.jiraBusinessAvailableFields == null) {
						throw error || new Error('No available fields data');
					}

					onCompletedData?.jiraBusinessAvailableFields?.edges?.forEach((e) => {
						const fieldEdges = e?.node?.fields?.edges;
						if (fieldEdges == null || fieldEdges?.length === 0) {
							const emptyMessage = fieldEdges == null ? 'null fields' : '0 length fields';

							const emptyFieldsError = new Error(
								`availableFieldsAPI is returning ${emptyMessage} for issueType ${
									e?.node?.issueType.name ?? 'UNKNOWN'
								}`,
							);
							// retrying due to intermittent error related to new projects: FUN-570
							if (fetchAttempts.current < 1) {
								fetchAttempts.current += 1;
								refetch();
							} else {
								throw emptyFieldsError;
							}
						}
					});
				},
			},
		);

	useEffect(() => {
		if (queryVariables && !hasStartedFetchingFields && !error) {
			setHasStartedFetchingFields(true);
			markViewMetric(view.toLowerCase(), AVAILABLE_FIELDS_QUERY_START);
			getAvailableFields();
		}
	}, [error, getAvailableFields, hasStartedFetchingFields, queryVariables, view]);

	const refetch = useCallback(() => {
		if (called && typeof refetchQuery === 'function') {
			return refetchQuery(queryVariables);
		}
		const err = new Error(
			'availableFields refetch is undefined because the service has not been called yet',
		);

		fireErrorAnalytics({
			meta: {
				id: 'availableFieldsRefetchIsUndefined',
				packageName: 'jiraBusinessEntityProject',
				teamName: 'wanjel',
			},
			error: err,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw err;
	}, [called, refetchQuery, queryVariables]);

	useEffect(() => {
		if (hasStartedFetchingFields && error) {
			setHasStartedFetchingFields(false);
		}
	}, [hasStartedFetchingFields, error]);

	const result = useMemo(() => transformResult(data), [data]);
	const typesWithFields = useMemo(() => transformResultToTypesWithFields(data), [data]);
	const hierarchyLevels = useMemo(
		() =>
			result.issueTypes
				.map((issueType) => ({
					level: issueType.hierarchyLevel,
					name: issueType.name,
					id: issueType.id,
				}))
				.sort((a, b) => (a.level !== b.level ? b.level - a.level : a.name.localeCompare(b.name))),
		[result.issueTypes],
	);

	// make sure "loading" flag starts from "true"
	const isFieldsLoading = hasStartedFetchingFields ? loading : true;

	return {
		error,
		loading: error ? false : isQueryLoading || isFieldsLoading,
		data: result,
		typesWithFields,
		hierarchyLevels,
		refetch,
	};
};

const issueTypesAndFieldsContextDefaultValue = {
	data: { fields: [], issueTypes: [] },
	loading: false,
	error: undefined,
	typesWithFields: [],
	hierarchyLevels: [],
};

export const IssueTypesAndFieldsContextView = createContext<UseIssueTypesAndFieldsReturn>({
	...issueTypesAndFieldsContextDefaultValue,
});

export const IssueTypesAndFieldsContextCreate = createContext<UseIssueTypesAndFieldsReturn>({
	...issueTypesAndFieldsContextDefaultValue,
});

export const useIssueTypesAndFields = (input?: UseIssueTypesAndFieldsInput) => {
	const issueTypesAndFieldsViewData = useContext(IssueTypesAndFieldsContextView);
	const issueTypesAndFieldsCreateData = useContext(IssueTypesAndFieldsContextCreate);

	if (!input || input.issueOperation === 'VIEW') {
		return issueTypesAndFieldsViewData;
	}

	return issueTypesAndFieldsCreateData;
};
