import { memo } from 'react';
import { useSubscribe } from '@atlassian/jira-graphql-subscription/src/services/subscribe/index.tsx';
import { FIELD_MUTATION_EVENT } from '../../constants.tsx';
import type { OnReceive, OnError } from '../../types.tsx';
import { getResponseError } from '../../utils.tsx';
import { JWM_FIELD_MUTATION_SUBSCRIPTION } from './gql.tsx';
import type { FieldMutationResponse } from './types.tsx';

type FieldMutationSubscriptionProps = {
	siteId: string;
	onReceive: OnReceive;
	onError: OnError;
};

const operationName = 'JwmFieldMutationSubscription';

export const FieldMutationSubscription = memo<FieldMutationSubscriptionProps>(
	({ siteId, onReceive, onError }: FieldMutationSubscriptionProps) => {
		useSubscribe<FieldMutationResponse>(
			{
				query: JWM_FIELD_MUTATION_SUBSCRIPTION,
				operationName,
				variables: {
					siteId,
				},
			},
			{
				next: (value) => {
					const error = getResponseError(value);
					if (error) {
						onError(error, operationName);
					} else if (value.data?.jira?.onJwmFieldMutation) {
						onReceive({
							type: FIELD_MUTATION_EVENT,
							payload: {},
						});
					}
				},
			},
		);

		return null;
	},
);
