/**
 * @generated SignedSource<<adfaa9c2a4e23b5c84e86159e16c826f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_workManagementBoard_JWMBoardRoot_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_BoardWithWorkflowsData_jiraQuery">;
  readonly " $fragmentType": "src_workManagementBoard_JWMBoardRoot_jiraQuery";
};
export type src_workManagementBoard_JWMBoardRoot_jiraQuery$key = {
  readonly " $data"?: src_workManagementBoard_JWMBoardRoot_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_JWMBoardRoot_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_workManagementBoard_JWMBoardRoot_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "src_workManagementBoard_BoardWithWorkflowsData_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "73cb01fc36c26b2127e7095f30a03b49";

export default node;
