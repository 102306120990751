import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import {
	ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE,
	TAILOR_BOARD_COLUMN_NUDGE_ID,
} from '../../common/constants.tsx';
import type { WrapperProps } from '../../common/types.tsx';
import { useRegisterNudgeTarget } from '../../controllers/register-nudge-target/index.tsx';
import { tailorBoardColumnsNudgeEntryPoint } from './entrypoint.tsx';

const runtimeProps = {};
const entryPointParams = {};

export const TailorBoardColumnsAsync = ({ children }: WrapperProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		tailorBoardColumnsNudgeEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const { ref: tailorBoardColumnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		TAILOR_BOARD_COLUMN_NUDGE_ID,
		true,
	);

	return (
		<>
			<div ref={tailorBoardColumnsNudgeRef}>{children}</div>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE}
				packageName="jiraBoardOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};
