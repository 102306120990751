import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Sorting } from '../../../sorting/types.tsx';
import type { TransformedParentField } from '../types.tsx';

const getParentSorting: Sorting<TransformedParentField> = (field) =>
	field.value ? field.value.issueKey : '';

export const parentSortingHandler = {
	[PARENT_TYPE]: getParentSorting,
};
