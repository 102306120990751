import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Sorting } from '../../../sorting/types.tsx';
import type { TransformedCascadingSelectField } from '../types.tsx';
import { getCascadingSelectStringValue } from '../value/index.tsx';

const getCascadingSelectSorting: Sorting<TransformedCascadingSelectField> = (field) =>
	getCascadingSelectStringValue(field.value);

export const cascadingSelectSortingHandler = {
	[CASCADING_SELECT_CF_TYPE]: getCascadingSelectSorting,
};
