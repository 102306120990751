import memoizeOne from 'memoize-one';
import { QueryError } from '@atlassian/jira-business-error-handling/src/utils/query-error/index.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { HYDRATE_VALUES_QUERY, HYDRATE_VALUES_QUERY_OLD } from './gql.tsx';
import type {
	HydrateFilterValuesResponse,
	HydratedFields,
	HydratedValues,
	Value,
} from './types.tsx';

export const transformResponse = (fields: HydratedFields[]): HydratedValues => {
	const hydratedValues: HydratedValues = {};

	fields.forEach((hydratedField) => {
		if (!hydratedField) {
			return;
		}
		const { field, values } = hydratedField;

		if (!field || !values) {
			return;
		}

		hydratedValues[field.jqlTerm] = {
			edges: values
				.map((item: Value) => (item && item.values && item.values[0]) ?? null)
				.filter(Boolean)
				.map((item) => ({
					node: item,
				})),
			totalCount: 0,
		};
	});

	return hydratedValues;
};

export const hydrateFilterValuesService = async (cloudId: string, jqlContext: string) => {
	try {
		const response = await performPostRequest<HydrateFilterValuesResponse>(
			'/rest/gira/1/?operation=JWMHydrateFilterValues',
			{
				body: JSON.stringify({
					query: fg('native_goals_integration_on_list_view')
						? HYDRATE_VALUES_QUERY
						: HYDRATE_VALUES_QUERY_OLD,
					variables: {
						cloudId,
						jql: jqlContext,
					},
				}),
			},
		);

		if (response.errors != null) {
			throw new GraphQLErrors(response.errors);
		}

		if (response.data?.jira?.jqlBuilder?.hydrateJqlQuery?.fields == null) {
			if (response.data?.jira?.jqlBuilder?.hydrateJqlQuery?.__typename === 'QueryError') {
				throw new QueryError(response.data.jira.jqlBuilder.hydrateJqlQuery);
			}

			throw new Error('Failed to fetch on hydrateFilterValuesService');
		}

		return transformResponse(response.data.jira.jqlBuilder.hydrateJqlQuery.fields);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'JWMHydrateFilterValues',
				packageName: 'jiraBusinessFilters',
				teamName: 'wanjel',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}
};

export const hydrateFilterValues = memoizeOne(hydrateFilterValuesService);
