import React, { memo, type ComponentType } from 'react';
import '@atlaskit/css-reset';
import { type EntryPointProps, usePreloadedQuery, graphql } from 'react-relay';
import { RelayProjectContextProvider } from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/index.tsx';
import { BOARD_VIEW_PREFERENCES } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { ViewPreferencesProvider } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import type { src_jiraBusinessBoardQuery } from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessBoardQuery.graphql';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import Board from '@atlassian/jira-work-management-board/src/index.tsx';

type JiraBusinessBoardAppType = EntryPointProps<
	{ boardQuery: src_jiraBusinessBoardQuery },
	{},
	{},
	{}
>;

const JiraBusinessBoardApp: ComponentType<JiraBusinessBoardAppType> = ({
	queries: { boardQuery },
}) => {
	const data = usePreloadedQuery<src_jiraBusinessBoardQuery>(
		graphql`
			query src_jiraBusinessBoardQuery($cloudId: ID!, $projectKey: String!) @preloadable {
				jira {
					...src_workManagementBoard_Board_jiraQuery
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey) {
						...projectContext_businessEntityProjectProvider_RelayProjectContextProvider
					}
				}
			}
		`,
		boardQuery,
	);

	return (
		<UFOSegment name="business-board-app">
			<AuthenticationCheck>
				<RelayProjectContextProvider projectFragment={data.jira?.jiraProjectByKey}>
					<ViewPreferencesProvider view="board" preferences={BOARD_VIEW_PREFERENCES}>
						<Board jiraQuery={data.jira} />
					</ViewPreferencesProvider>
				</RelayProjectContextProvider>
				<SubProductUpdater subProduct="core" />
			</AuthenticationCheck>
		</UFOSegment>
	);
};

export default memo(JiraBusinessBoardApp);
