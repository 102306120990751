import React from 'react';
import BranchesIcon from '@atlaskit/icon/core/branch';
import BranchesIconLegacy from '@atlaskit/icon/glyph/bitbucket/branches';
import { DEV_SUMMARY_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getDevSummaryIcons: GetIconFunction = (size, spacing) => ({
	[DEV_SUMMARY_CF_TYPE]: (
		<BranchesIcon
			label=""
			color="currentColor"
			spacing={spacing}
			LEGACY_fallbackIcon={BranchesIconLegacy}
			LEGACY_size={size}
		/>
	),
});
