import type { IntlShape } from '@atlassian/jira-intl';
import type { Response } from '../../services/fetch-group-item-values/types.tsx';
import messages from './messages.tsx';
import type { TransformedInitialData } from './types.tsx';

export const transformInitialData = ({
	data,
	formatMessage,
}: {
	data: Response['data'];
	formatMessage: IntlShape['formatMessage'];
}): TransformedInitialData => ({
	totalCount: data.items.totalCount,
	options: data.items.edges.map((edge) => {
		if (edge.node.field.floatValue == null) {
			return {
				value: null,
				label: formatMessage(messages.noneOption),
			};
		}
		const stringFloatValue = String(edge.node.field.floatValue);

		return {
			value: stringFloatValue,
			label: stringFloatValue,
		};
	}),
});
