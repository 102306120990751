import { useCallback, useReducer } from 'react';
import { useAbortable, ABORTED } from '@atlassian/jira-business-abortable/src/index.tsx';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fetchGroupItemValues } from '../../services/fetch-group-item-values/index.tsx';
import type { State, Actions } from './types.tsx';
import { transformInitialData } from './utils.tsx';

const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
		case 'loading':
			return { loading: true };
		case 'success':
			return { loading: false, data: action.payload };
		case 'error':
			return { loading: false, data: null };
		default:
			return state;
	}
};

export const useGroupItemValues = ({ groupFieldId }: { groupFieldId: string }) => {
	const [state, dispatch] = useReducer(reducer, { loading: false });
	const jqlContext = useJqlContext();
	const { formatMessage } = useIntl();

	const fetchDataAbortable = useAbortable(fetchGroupItemValues);

	const fetch = useCallback(async () => {
		try {
			dispatch({
				type: 'loading',
			});
			const response = await fetchDataAbortable(groupFieldId, jqlContext);

			if (response.data?.items == null) {
				throw new GraphQLErrors(response.errors);
			}

			dispatch({
				type: 'success',
				payload: transformInitialData({ data: response.data, formatMessage }),
			});
		} catch (error: unknown) {
			if (error === ABORTED) {
				return;
			}

			dispatch({
				type: 'error',
			});
		}
	}, [fetchDataAbortable, formatMessage, groupFieldId, jqlContext]);

	return {
		fetch,
		data: state.data,
		loading: state.loading,
	};
};
