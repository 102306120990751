import React, { type ReactNode } from 'react';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const ContactAdmin = ({ children }: { children: ReactNode }) => (
	<a href="/secure/ContactAdministrators!default.jspa" target="_blank">
		{children}
	</a>
);

export const ContactAdminToModifyBoardMessage = () => {
	const { formatMessage } = useIntl();

	return (
		<FormattedI18nMessage
			componentsMapping={{
				link: ContactAdmin,
			}}
			message={formatMessage(messages.noPermissionToModify, {
				linkStart: '{linkStart}',
				linkEnd: '{linkEnd}',
			})}
		/>
	);
};
