import type { CascadingSelectValuePair } from '@atlassian/jira-issue-field-cascading-select-editview-full/src/ui/cascading-select/types.tsx';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { RichValue, StringValue } from '../../../value/types.tsx';
import type { TransformedCascadingSelectField, CascadingSelectFieldValue } from '../types.tsx';

export const getCascadingSelectStringValue = (
	fieldValue: CascadingSelectFieldValue | CascadingSelectValuePair | null,
) => {
	if (fieldValue && fieldValue.parentOptionValue) {
		if (fieldValue.childOptionValue) {
			return `${fieldValue.parentOptionValue.value} - ${fieldValue.childOptionValue.value}`;
		}
		return `${fieldValue.parentOptionValue.value}`;
	}
	return '';
};

const getCascadingSelectValue: StringValue<TransformedCascadingSelectField> = (field) =>
	getCascadingSelectStringValue(field.value);

const getCascadingSelectRichValue: RichValue<
	TransformedCascadingSelectField,
	CascadingSelectFieldValue
> = (field) => field.value ?? null;

export const cascadingSelectStringValueHandler = {
	[CASCADING_SELECT_CF_TYPE]: getCascadingSelectValue,
};

export const cascadingSelectRichValueHandler = {
	[CASCADING_SELECT_CF_TYPE]: getCascadingSelectRichValue,
};
