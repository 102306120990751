import { useCallback } from 'react';
import { useFilters } from '@atlassian/jira-business-filters/src/controllers/index.tsx';
import { ACTIVE_WORKFLOW_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { useBoardData } from '../board-data/index.tsx';

export const useActiveWorkflow = () => {
	const [activeWorkflowId, setActiveWorkflowId] = useViewPreference(ACTIVE_WORKFLOW_PREFERENCE);
	const { reset } = useBoardData();
	const { setFilter } = useFilters();

	const setActiveWorkflow = useCallback(
		(newWorkflowId: string) => {
			setActiveWorkflowId(newWorkflowId);

			// Reset filters and board data when switching to a different workflow
			setFilter({});
			reset();
		},
		[setActiveWorkflowId, setFilter, reset],
	);
	return [activeWorkflowId, setActiveWorkflow] as const;
};
