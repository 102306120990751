import React from 'react';
import ProjectIcon from '@atlaskit/icon/core/folder-closed';
import ProjectIconLegacy from '@atlaskit/icon/glyph/folder';
import { PROJECT_PICKER_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getProjectIcons: GetIconFunction = (size, spacing) => ({
	[PROJECT_PICKER_CF_TYPE]: (
		<ProjectIcon
			color="currentColor"
			label=""
			spacing={spacing}
			LEGACY_size={size}
			LEGACY_fallbackIcon={ProjectIconLegacy}
		/>
	),
});
