import { useCallback } from 'react';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import { JWM_INLINE_CREATE } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useRankField } from '@atlassian/jira-router-resources-business-configuration/src/index.tsx';
import type { CreateIssueDialogInput, ExtraCreateIssuePayload } from '../../types.tsx';
import type { GetField } from './types.tsx';
import { buildGICPayload } from './utils.tsx';

export class CancelledError extends Error {}

export const useGetIssueCreateField = (): GetField => {
	const { typesWithFields } = useIssueTypesAndFields({
		issueOperation: 'CREATE',
	});

	return useCallback(
		(issueType, predicate) => {
			if (!issueType) {
				return undefined;
			}

			const issueTypeWithFields = typesWithFields.find(
				(typeWithFields) => typeWithFields.issueType.id === issueType.id,
			);

			return issueTypeWithFields?.fields.find(predicate);
		},
		[typesWithFields],
	);
};

export const useCreateIssueDialog = () => {
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { subscribe } = useGICLifeCycleEvents();
	const getField = useGetIssueCreateField();

	// rank field is handled separately as it's not returned by the available fields API
	const { data: rankField } = useRankField();

	return useCallback(
		(
			createInput: CreateIssueDialogInput,
			extraPayload?: ExtraCreateIssuePayload,
		): Promise<CreatedIssueWithIssueData> =>
			new Promise(
				(resolve: (result: CreatedIssueWithIssueData) => void, reject: (error: Error) => void) => {
					const unsubscribe = subscribe(
						GIC_LIFECYCLE_EVENTS.MODAL_KEEP_EDITING_EXISTING_ISSUE,
						() => {
							// the user cancelled editing
							onClose(null);
						},
					);

					// resolve the promise once the GIC has been closed or reject it if cancelled
					const onClose = (data: CreatedIssueWithIssueData[] | null | undefined) => {
						const result = data?.[0];

						if (result) {
							resolve(result);
						} else {
							// adding a delay upon user cancelling because of a bug in the GIC's state management logic ICO-303
							setTimeout(() => {
								reject(new CancelledError('User cancelled'));
							}, 100);
						}

						unsubscribe();
					};

					const { payload, submitApiData } = buildGICPayload({
						createInput,
						extraPayload,
						rankField,
						getField,
					});

					const isSubTaskCreationOpen = createInput.issueType?.hierarchyLevel === -1;

					openIssueCreateModal({
						id: 'jwm-create-issue-dialog',
						onClose,
						payload,
						context: {
							projectId: createInput.projectId,
							submitApiData,
						},
						isSubTaskCreationOpen,
						triggerPointKey: JWM_INLINE_CREATE,
						disableProjectDropdown: true,
						disableIssueTypeDropdown: Boolean(payload.issueType?.issueTypeId),
						disableCreationSuccessFlag: true,
						displayCreateAnotherIssueToggle: false,
						minimizableModalView: 'mini',
					});
				},
			),
		[getField, openIssueCreateModal, rankField, subscribe],
	);
};
