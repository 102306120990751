/**
 * @generated SignedSource<<96ac824f81bc99ddb7ae4a7795e12c1c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardCover_workManagementBoard_CardImageCover_jiraQuery$data = {
  readonly mediaClientId: string | null | undefined;
  readonly mediaExternalEndpointUrl: string | null | undefined;
  readonly " $fragmentType": "cardCover_workManagementBoard_CardImageCover_jiraQuery";
};
export type cardCover_workManagementBoard_CardImageCover_jiraQuery$key = {
  readonly " $data"?: cardCover_workManagementBoard_CardImageCover_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_CardImageCover_jiraQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "cardCover_workManagementBoard_CardImageCover_jiraQuery",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "mediaClientId"
    },
    {
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "mediaExternalEndpointUrl"
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "bca43ee59b382952a32cc7596ce7d09b";

export default node;
