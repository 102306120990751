import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { SmartCardProvider } from '@atlaskit/link-provider';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ConfluenceModalProvider } from '@atlassian/jira-business-confluence-pages/src/ui/confluence-view-modal/index.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { IssueTypesAndFieldsContext } from '@atlassian/jira-business-entity-project/src/controllers/issue-types-and-fields-context/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { DeserialisedFiltersProvider } from '@atlassian/jira-business-filters/src/controllers/deserialise-filters/index.tsx';
import { IssueCreateProvider } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import { WorkflowActionsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import { WorkflowsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflows-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { src_workManagementBoard_Board_jiraQuery$key } from '@atlassian/jira-relay/src/__generated__/src_workManagementBoard_Board_jiraQuery.graphql';
import type { src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery$key } from '@atlassian/jira-relay/src/__generated__/src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery.graphql';
import type { src_workManagementBoard_BoardWithWorkflowsData_jiraQuery$key } from '@atlassian/jira-relay/src/__generated__/src_workManagementBoard_BoardWithWorkflowsData_jiraQuery.graphql';
import type { src_workManagementBoard_JWMBoardRoot_jiraQuery$key } from '@atlassian/jira-relay/src/__generated__/src_workManagementBoard_JWMBoardRoot_jiraQuery.graphql';
import { VIEW_EXPERIENCE } from './common/constants.tsx';
import { BoardDataProvider } from './controllers/board-data/index.tsx';
import { IssueStoreContainer } from './controllers/board-issue-store/index.tsx';
import { ConfettiColumnsProvider } from './controllers/column-confetti/index.tsx';
import { CardDragDropProvider } from './controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { ColumnDragDropProvider } from './controllers/drag-and-drop/column-drag-drop-context/index.tsx';
import { useSelectedFields } from './controllers/fields-preference/index.tsx';
import { GroupsProvider } from './controllers/groups-context/index.tsx';
import { IssueTransitionsProvider } from './controllers/issue-transitions/index.tsx';
import { IssuesByGroupProvider } from './controllers/issues-by-group/index.tsx';
import {
	WorkflowStoreContainer,
	useWorkflowStoreState,
} from './controllers/workflow-store/index.tsx';
import messages from './messages.tsx';
import { useCreateIssue } from './services/create-issue/index.tsx';
import JWMBoardComponent from './ui/board/index.tsx';
import JWMBoardHeader from './ui/header/index.tsx';
import { transformWorkflow } from './utils/workflow-transformer/index.tsx';

const analyticsSourceName = 'board';

const FireScreenAnalyticsWithIssueLinksAttributes = () => {
	const selectedFields = useSelectedFields();

	return (
		<FireScreenAnalytics
			attributes={{ withIssueLinks: Boolean(selectedFields[ISSUE_LINKS_TYPE]) }}
		/>
	);
};

type BoardWithWorkflowsDataProps = {
	jiraQuery: src_workManagementBoard_BoardWithWorkflowsData_jiraQuery$key | null | undefined;
};

const BoardWithWorkflowsData = ({ jiraQuery = null }: BoardWithWorkflowsDataProps) => {
	const { workflows } = useWorkflowStoreState();
	const services = useCreateIssue();
	const workflowsProviderWorkflows = useMemo(() => workflows.map(transformWorkflow), [workflows]);

	const jiraQueryData = useFragment(
		graphql`
			fragment src_workManagementBoard_BoardWithWorkflowsData_jiraQuery on JiraQuery {
				...board_workManagementBoard_jiraQuery
			}
		`,
		jiraQuery,
	);

	const content = (
		<IssueTransitionsProvider>
			<ConfettiColumnsProvider>
				<JWMBoardHeader />
				<JWMBoardComponent jiraQuery={jiraQueryData} />
			</ConfettiColumnsProvider>
		</IssueTransitionsProvider>
	);

	return (
		<WorkflowsProvider workflows={workflowsProviderWorkflows}>
			<IssueCreateProvider
				onCreateIssue={services.createIssue}
				onRefetchIssue={services.refetchIssue}
				ignoreFiltersContext
			>
				<ContextualAnalyticsData
					objectType="jwmBoard"
					sourceType={SCREEN}
					sourceName={analyticsSourceName}
				>
					<DeserialisedFiltersProvider>
						<WorkflowActionsProvider>
							<IssueStoreContainer>
								<ColumnDragDropProvider>
									<CardDragDropProvider>
										{fg('fun-1361_refactor_business_board_grouping_logic') ? (
											<BoardDataProvider>
												<IssuesByGroupProvider>
													<GroupsProvider>{content}</GroupsProvider>
												</IssuesByGroupProvider>
											</BoardDataProvider>
										) : (
											<GroupsProvider>
												<BoardDataProvider>{content}</BoardDataProvider>
											</GroupsProvider>
										)}
									</CardDragDropProvider>
								</ColumnDragDropProvider>
							</IssueStoreContainer>
						</WorkflowActionsProvider>
						<FireScreenAnalyticsWithIssueLinksAttributes />
					</DeserialisedFiltersProvider>
				</ContextualAnalyticsData>
			</IssueCreateProvider>
		</WorkflowsProvider>
	);
};

type JWMBoardRootProps = {
	jiraQuery: src_workManagementBoard_JWMBoardRoot_jiraQuery$key | null | undefined;
};

export const JWMBoardRoot = memo(({ jiraQuery = null }: JWMBoardRootProps) => {
	const { formatMessage } = useIntl();

	const pageHeading = formatMessage(messages.boardHeading);

	const jiraQueryData = useFragment(
		graphql`
			fragment src_workManagementBoard_JWMBoardRoot_jiraQuery on JiraQuery {
				...src_workManagementBoard_BoardWithWorkflowsData_jiraQuery
			}
		`,
		jiraQuery,
	);

	return (
		<>
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<BusinessAppWrapper withSidebar withOverflow>
				<ApolloClientProvider client={client}>
					<IssueTypesAndFieldsContext>
						<WorkflowStoreContainer>
							<BoardWithWorkflowsData jiraQuery={jiraQueryData} />
						</WorkflowStoreContainer>
					</IssueTypesAndFieldsContext>
				</ApolloClientProvider>
			</BusinessAppWrapper>
		</>
	);
});

type BoardViewRootWithSmartCardProps = {
	jiraQuery: src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery$key | null | undefined;
};

const BoardViewRootWithSmartCard = ({ jiraQuery = null }: BoardViewRootWithSmartCardProps) => {
	const hasConfluence = useTenantHasConfluence();

	const jiraQueryData = useFragment(
		graphql`
			fragment src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery on JiraQuery {
				...src_workManagementBoard_JWMBoardRoot_jiraQuery
			}
		`,
		jiraQuery,
	);

	if (hasConfluence) {
		return (
			<SmartCardProvider>
				<ConfluenceModalProvider>
					<JWMBoardRoot jiraQuery={jiraQueryData} />
				</ConfluenceModalProvider>
			</SmartCardProvider>
		);
	}
	return <JWMBoardRoot jiraQuery={jiraQueryData} />;
};

export type BoardProps = {
	jiraQuery: src_workManagementBoard_Board_jiraQuery$key | null | undefined;
};

const Board = ({ jiraQuery = null }: BoardProps) => {
	const jiraQueryData = useFragment(
		graphql`
			fragment src_workManagementBoard_Board_jiraQuery on JiraQuery {
				...src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery
			}
		`,
		jiraQuery,
	);

	return (
		<ExperienceErrorBoundary experience={VIEW_EXPERIENCE} shouldStartExperience>
			<BoardViewRootWithSmartCard jiraQuery={jiraQueryData} />
		</ExperienceErrorBoundary>
	);
};

export default memo(Board);
