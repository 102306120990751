/* eslint-disable complexity */
import { useCallback } from 'react';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import {
	ASSIGNEE_ID,
	PRIORITY_ID,
	CATEGORY_ID,
	STATUS_ID,
	ISSUE_KEY_ID,
} from '../../common/constants.tsx';
import type { BoardIssue, BoardIssueFields, RankInput } from '../../common/types.tsx';
import {
	useTransitionAndRankIssueService,
	TransitionValidationError,
} from '../../services/transition-and-rank-issue/index.tsx';
import { useUpdateAssignee } from '../../services/update-assignee/index.tsx';
import { useUpdateCategory } from '../../services/update-category/index.tsx';
import { useUpdatePriority } from '../../services/update-priority/index.tsx';
import { UpdateFieldsValidationError } from '../../services/utils.tsx';
import { useTransformIssues } from '../../utils/issue-transformer/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import { useFieldIds } from '../field-ids/index.tsx';
import { useRefetchIssues } from '../refetch-issues/index.tsx';
import {
	useTransitionScreenModal,
	TransitionModalCanceled,
} from '../transition-screen-modal/index.tsx';
import messages from './messages.tsx';

export type UpdateIssueArgs = {
	issue: BoardIssue;
	fields?: Partial<BoardIssueFields>;
	rank?: RankInput;
	transition?: Transition | null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
const noop = (_arg1: any, _arg2?: any) => {};

export const useUpdateIssue = () => {
	const fieldIds = useFieldIds();
	const { showFlag } = useFlagService();
	const { updateIssueFields, updateIssues } = useIssueStoreActions();
	const transformToIssues = useTransformIssues();
	const refetchIssues = useRefetchIssues();
	const transitionAndRankIssueService = useTransitionAndRankIssueService();
	const updateAssignee = useUpdateAssignee();
	const updatePriority = useUpdatePriority();
	const updateCategory = useUpdateCategory();
	const openTransitionScreenModal = useTransitionScreenModal();

	const { showIssueTransitionSuccessFlag } = fg('show-modernised-issue-transition-success-flag')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowFlag()
		: { showIssueTransitionSuccessFlag: noop };

	return useCallback(
		async ({ issue, fields, rank, transition }: UpdateIssueArgs) => {
			// optimistically update the issue fields
			updateIssueFields({ issueId: issue.id, fields, rank });

			const issueKey = issue.fields[ISSUE_KEY_ID].value;

			let hasTriedFieldsUpdate = false;
			let hasTriedStatusTransition = false;
			let hasTriedRanking = false;

			try {
				// perform field updates first as the mutations/endpoints
				// cannot return the full issue data
				if (fields != null) {
					if (ASSIGNEE_ID in fields) {
						hasTriedFieldsUpdate = true;
						await updateAssignee({
							issueId: issue.id,
							assigneeId: fields[ASSIGNEE_ID]?.user.accountId ?? null,
						});
					} else if (PRIORITY_ID in fields && fields[PRIORITY_ID]?.priority.id != null) {
						hasTriedFieldsUpdate = true;
						await updatePriority({
							issueId: issue.id,
							priorityId: fields[PRIORITY_ID].priority.id,
						});
					} else if (CATEGORY_ID in fields) {
						hasTriedFieldsUpdate = true;
						await updateCategory({
							issueId: issue.id,
							optionId: fields[CATEGORY_ID]?.value ?? null,
						});
					}
				}

				if (transition != null || rank != null) {
					hasTriedStatusTransition = transition != null;
					hasTriedRanking = rank != null;

					if (transition?.hasScreen) {
						// if the transition has a screen, the transition is done through the modal
						await openTransitionScreenModal(issue, transition.transitionId);

						// only the ranking needs to be updated after the transition
						if (rank == null) {
							refetchIssues([issue.id]);
						} else {
							const response = await transitionAndRankIssueService({
								fieldIds,
								issueId: issue.id,
								rank,
							});

							updateIssues(transformToIssues([response]));
						}
					} else {
						const response = await transitionAndRankIssueService({
							fieldIds,
							issueId: issue.id,
							rank,
							transitionId: transition?.transitionId,
						});

						updateIssues(transformToIssues([response]));
					}

					const destinationStatusName = fields?.[STATUS_ID]?.status.name;
					if (
						destinationStatusName != null &&
						fg('show-modernised-issue-transition-success-flag')
					) {
						showIssueTransitionSuccessFlag(issueKey, destinationStatusName);
					}
				}
			} catch (error) {
				// if anything failed, refetch issues
				refetchIssues([issue.id]);

				// if the user cancelled the transition modal, do not show an error flag
				if (error instanceof TransitionModalCanceled) {
					throw error;
				}

				if (error instanceof TransitionValidationError) {
					showFlag({
						type: 'warning',
						title: messages.transitionValidationErrorTitle,
						description: error.message,
					});
				} else if (hasTriedStatusTransition) {
					showFlag({
						type: 'error',
						title: messages.failedToTransitionErrorTitle,
						description: messages.failedToTransitionErrorDescription,
					});
				} else if (error instanceof UpdateFieldsValidationError) {
					showFlag({
						type: 'error',
						title: messages.failedToUpdateFieldsErrorTitle,
						description: error.message ?? messages.failedToUpdateFieldsErrorDescription,
					});
				} else if (hasTriedFieldsUpdate) {
					showFlag({
						type: 'error',
						title: messages.failedToUpdateFieldsErrorTitle,
						description: messages.failedToUpdateFieldsErrorDescription,
					});
				} else if (hasTriedRanking) {
					showFlag({
						type: 'error',
						title: messages.failedToRankErrorTitle,
						description: messages.failedToRankErrorDescription,
					});
				}

				throw error;
			}
		},
		[
			updateIssueFields,
			updateAssignee,
			updatePriority,
			updateCategory,
			openTransitionScreenModal,
			refetchIssues,
			transitionAndRankIssueService,
			fieldIds,
			updateIssues,
			transformToIssues,
			showIssueTransitionSuccessFlag,
			showFlag,
		],
	);
};
