import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type CreatePage from './main.tsx';
import type { CreatePageProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCreatePage = lazy<typeof CreatePage>(
	() =>
		import(
			/* webpackChunkName: "async-jwm-confluence-pages-confluence-view-modal-create-page-modal" */ './main'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: CreatePageProps) => (
	<Placeholder name="create-page-modal">
		<LazyCreatePage {...props} />
	</Placeholder>
);
