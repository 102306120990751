import React from 'react';
import RadioCheckedIcon from '@atlaskit/icon/core/radio-checked';
import RadioIcon from '@atlaskit/icon/glyph/radio';
import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getRadioSelectIcon: GetIconFunction = (size, spacing) => ({
	[RADIO_BUTTONS_CF_TYPE]: (
		<RadioCheckedIcon
			color="currentColor"
			label=""
			spacing={spacing}
			LEGACY_size={size}
			LEGACY_fallbackIcon={RadioIcon}
		/>
	),
});
