import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import {
	CARD_COVERS_TYPE,
	REMOTE_ISSUE_LINKS_TYPE,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { shouldHandleTheError } from '@atlassian/jira-business-error-handling/src/utils/partial-data/index.tsx';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import {
	ISSUE_LINKS_TYPE,
	SUBTASKS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIsLinkingEnabled } from '@atlassian/jira-router-resources-business-configuration/src/index.tsx';
import type { RankInput } from '../../common/types.tsx';
import type {
	JWMBoardTransitionAndRankIssue,
	JWMBoardTransitionAndRankIssueVariables,
} from './__generated_apollo__/JWMBoardTransitionAndRankIssue';
import { TRANSITION_AND_RANK_ISSUE_MUTATION } from './gql.tsx';

const TRANSITION_AND_RANK_EXPERIENCE: ExperienceDetails = {
	experience: 'transitionAndRankIssue',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export class TransitionValidationError extends Error {}

export type TransitionAndRankIssueArgs = {
	fieldIds: string[];
	issueId: number;
	rank?: RankInput;
	transitionId?: JWMBoardTransitionAndRankIssueVariables['input']['transitionId'];
};

export const useTransitionAndRankIssueService = () => {
	const hasConfluence = useTenantHasConfluence();
	const isLinkingEnabled = useIsLinkingEnabled();

	const startTransitionAndRankIssueExperience = useExperienceStart(TRANSITION_AND_RANK_EXPERIENCE);
	const markTransitionAndRankIssueSuccess = useExperienceSuccess(TRANSITION_AND_RANK_EXPERIENCE);
	const markTransitionAndRankIssueFailed = useExperienceFail(TRANSITION_AND_RANK_EXPERIENCE);
	const abortTransitionAndRankIssueExperience = useExperienceAbort(TRANSITION_AND_RANK_EXPERIENCE);

	const [transitionAndRankMutation] = useMutation<
		JWMBoardTransitionAndRankIssue,
		JWMBoardTransitionAndRankIssueVariables
	>(TRANSITION_AND_RANK_ISSUE_MUTATION);

	return useCallback(
		async ({
			fieldIds: fieldIdsInput,
			issueId,
			rank,
			transitionId,
		}: TransitionAndRankIssueArgs) => {
			startTransitionAndRankIssueExperience();

			try {
				const fieldIds = new Set(fieldIdsInput);
				const withChildrenCount = fieldIds.has(SUBTASKS_TYPE);
				fieldIds.delete(SUBTASKS_TYPE);
				const withConfluenceLinks = hasConfluence && fieldIds.has(REMOTE_ISSUE_LINKS_TYPE);
				fieldIds.delete(REMOTE_ISSUE_LINKS_TYPE);
				const withCoverMedia = fieldIds.has(CARD_COVERS_TYPE);
				fieldIds.delete(CARD_COVERS_TYPE);
				const withIssueLinks = fieldIds.has(ISSUE_LINKS_TYPE) && isLinkingEnabled;
				fieldIds.delete(ISSUE_LINKS_TYPE);

				let rankInput: JWMBoardTransitionAndRankIssueVariables['input']['rank'];
				if (rank?.position === 'before') {
					rankInput = {
						beforeIssueId: rank.relativeIssueId,
					};
				} else if (rank?.position === 'after') {
					rankInput = {
						afterIssueId: rank.relativeIssueId,
					};
				}

				const { data, errors } = await transitionAndRankMutation({
					variables: {
						fieldIds: Array.from(fieldIds),
						input: {
							issueIds: [issueId],
							rank: rankInput,
							transitionId,
						},
						withConfluenceLinks,
						withCoverMedia,
						withIssueLinks,
						withChildrenCount,
						withUnsplashCoverMedia: fg('jwm_unsplash_images'),
					},
					// @ts-expect-error - errorPolicy is not in the types but does work
					errorPolicy: 'all',
				});

				const mutationError = data?.transitionAndRankJwmItems?.errors?.[0];
				if (mutationError != null) {
					if (
						mutationError.message != null &&
						mutationError.extensions?.statusCode === 400 &&
						mutationError.extensions.errorType === 'TRANSITION_ERROR'
					) {
						throw new TransitionValidationError(mutationError.message);
					}

					throw new MutationError(mutationError);
				}

				if (errors) {
					const apolloError = new ApolloError({
						graphQLErrors: errors,
					});

					if (shouldHandleTheError(apolloError)) {
						throw apolloError;
					}
				}

				if (!data) {
					throw new Error('No transition and rank response');
				}

				const responseData = data.transitionAndRankJwmItems;
				const issue =
					data.transitionAndRankJwmItems?.items?.[0]?.fieldsByIdOrAlias != null
						? responseData?.items?.[0]
						: null;

				if (issue == null) {
					throw new Error('Invalid transition and rank response');
				}

				markTransitionAndRankIssueSuccess();

				return issue;

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error instanceof TransitionValidationError) {
					abortTransitionAndRankIssueExperience('Transition validation error');
				} else {
					markTransitionAndRankIssueFailed('Failed to transition and rank issue', error);
				}

				throw error;
			}
		},
		[
			startTransitionAndRankIssueExperience,
			hasConfluence,
			isLinkingEnabled,
			transitionAndRankMutation,
			markTransitionAndRankIssueSuccess,
			abortTransitionAndRankIssueExperience,
			markTransitionAndRankIssueFailed,
		],
	);
};
