import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyProjectInviteModalWithRecommendedUsers = lazy(() =>
	import(
		/* webpackChunkName: "async-project-invite-modal-with-recommended-users" */ './index'
	).then(({ ProjectInviteModalWithRecommendedUsers }) => ProjectInviteModalWithRecommendedUsers),
);

export const ProjectInviteModalWithRecommendedUsers = (props: Props) => {
	return (
		<ErrorBoundary id="async-project-invite-modal-with-recommended-users">
			<Placeholder name="project-invite-modal-with-recommended-users" fallback={<></>}>
				<LazyProjectInviteModalWithRecommendedUsers {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};
