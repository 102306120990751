import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import { NULL_GROUP_KEY } from '../../common/constants.tsx';
import type { BoardIssue, Group } from '../../common/types.tsx';
import { useBoardData } from '../board-data/index.tsx';
import { useGroupByField } from '../group-by/index.tsx';

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
type IssuesByGroupOld = Map<Group['id'], BoardIssue[]>;

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
const IssuesByGroupContextOld = createContext<IssuesByGroupOld | null>(null);

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
type IssuesByGroupProviderPropsOld = {
	filteredIssues: BoardIssue[];
	children: ReactNode;
};

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
export const IssuesByGroupProviderOld = ({
	filteredIssues,
	children,
}: IssuesByGroupProviderPropsOld) => {
	const groupBy = useGroupByField();

	const issuesByGroup = useMemo(() => {
		const map = new Map<Group['id'], BoardIssue[]>();

		// iterate over the filtered issues and add them to the correct group
		filteredIssues.forEach((issue) => {
			const groupId = issue.fields[groupBy]?.value ?? NULL_GROUP_KEY;
			let groupIssues = map.get(groupId);
			if (!groupIssues) {
				groupIssues = [];
				map.set(groupId, groupIssues);
			}
			groupIssues.push(issue);
		});

		return map;
	}, [filteredIssues, groupBy]);

	return (
		<IssuesByGroupContextOld.Provider value={issuesByGroup}>
			{children}
		</IssuesByGroupContextOld.Provider>
	);
};

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
export const useIssuesByGroupOld = (): IssuesByGroupOld => {
	const issuesByGroup = useContext(IssuesByGroupContextOld);

	if (!issuesByGroup) {
		throw new Error('useIssuesByGroup must be used within a IssuesByGroupProvider');
	}

	return issuesByGroup;
};

type IssuesByGroup = Map<Group['id'], BoardIssue[]>;

const IssuesByGroupContext = createContext<IssuesByGroup | null>(null);

type IssuesByGroupProviderProps = {
	children: ReactNode;
};

export const IssuesByGroupProvider = ({ children }: IssuesByGroupProviderProps) => {
	const groupBy = useGroupByField();
	const { filteredIssues } = useBoardData();

	const issuesByGroup = useMemo(() => {
		const map = new Map<Group['id'], BoardIssue[]>();

		// iterate over the filtered issues and add them to the correct group
		filteredIssues.forEach((issue) => {
			const groupId = issue.fields[groupBy]?.value ?? NULL_GROUP_KEY;
			let groupIssues = map.get(groupId);
			if (!groupIssues) {
				groupIssues = [];
				map.set(groupId, groupIssues);
			}
			groupIssues.push(issue);
		});

		return map;
	}, [filteredIssues, groupBy]);

	return (
		<IssuesByGroupContext.Provider value={issuesByGroup}>{children}</IssuesByGroupContext.Provider>
	);
};

export const useIssuesByGroup = (): IssuesByGroup => {
	const issuesByGroup = useContext(IssuesByGroupContext);

	if (!issuesByGroup) {
		throw new Error('useIssuesByGroup must be used within a IssuesByGroupProvider');
	}

	return issuesByGroup;
};
