import React, { useEffect } from 'react';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { configurationModalEntryPoint } from './entrypoint.tsx';
import type { AsyncConfigurationModalProps } from './types.tsx';
import { useShouldShowConfigurationModal } from './utils.tsx';

export const AsyncConfigurationModal = ({ source }: AsyncConfigurationModalProps) => {
	const shouldShowConfigurationModal = useShouldShowConfigurationModal();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		configurationModalEntryPoint,
		{},
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (shouldShowConfigurationModal) {
			entryPointActions.load();

			return () => {
				entryPointActions.unload();
			};
		}
	}, [shouldShowConfigurationModal, entryPointActions]);

	if (!shouldShowConfigurationModal) {
		return null;
	}

	return (
		<ModalEntryPointContainer
			id="jira-onboarding-configuration-modal"
			packageName="jiraOnboarding"
			teamName="Growth-Jira-Find"
			runtimeProps={{ onClose: entryPointActions.unload, source }}
			entryPointActions={entryPointActions}
			entryPointReferenceSubject={entryPointReferenceSubject}
			errorFallback="flag"
		/>
	);
};
