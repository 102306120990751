import React, { memo, useCallback, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import {
	StatusCategoryTypes,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { STATUS_ID } from '../../../../../../../../common/constants.tsx';
import type { BoardIssue } from '../../../../../../../../common/types.tsx';
import messages from './messages.tsx';

type Props = {
	subtasks?: BoardIssue[];
};

const SubtaskProgressBar = ({ subtasks }: Props) => {
	const { formatMessage } = useIntl();

	const renderBarSection = useCallback(
		(statusCategoryId: number, BarSectionComponent: ComponentType, message: MessageDescriptor) => {
			if (!subtasks) {
				return null;
			}
			const count = subtasks.filter(
				(subtask) => subtask.fields[STATUS_ID].status.statusCategoryId === statusCategoryId,
			).length;

			const label = formatMessage(message, {
				itemCount: count,
				totalCount: subtasks.length,
			});
			return (
				<BarSectionContainer
					barWidthPct={(count / subtasks.length) * 100}
					role="progressbar"
					aria-valuemax={subtasks.length}
					aria-valuenow={count}
					aria-label={label}
				>
					<ToolTip content={label}>
						<BarSectionComponent />
					</ToolTip>
				</BarSectionContainer>
			);
		},
		[subtasks, formatMessage],
	);

	if (!subtasks || subtasks.length === 0) {
		return null;
	}

	return (
		<SubtaskProgressBarContainer>
			{renderBarSection(
				StatusCategoryIds[StatusCategoryTypes.DONE],
				DoneBarSection,
				messages.doneItems,
			)}
			{renderBarSection(
				StatusCategoryIds[StatusCategoryTypes.INDETERMINATE],
				InProgressBarSection,
				messages.inProgressItems,
			)}
			{renderBarSection(
				StatusCategoryIds[StatusCategoryTypes.NEW],
				TodoBarSection,
				messages.todoItems,
			)}
		</SubtaskProgressBarContainer>
	);
};

export default memo<Props>(SubtaskProgressBar);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskProgressBarContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N30),
	width: '100%',
	borderRadius: '4px',
	overflow: 'hidden',
	marginTop: token('space.075', '6px'),
	marginBottom: token('space.100', '8px'),
	display: 'flex',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarSectionContainer = styled.div<{ barWidthPct: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexBasis: ({ barWidthPct }) => `${barWidthPct}%`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child, &:nth-last-child(2)': {
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
		overflow: 'hidden',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarSection = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 0.75}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DoneBarSection = styled(BarSection)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border.success', colors.G300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InProgressBarSection = styled(BarSection)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border.information', colors.B400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TodoBarSection = styled(BarSection)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N30),
});
