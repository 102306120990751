import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';

export const getNextRank = (s: string): string => `${s}0`;

export const getPreviousRank = (s: string): string => {
	const lastCharCode = s.charCodeAt(s.length - 1);
	const previousLastChar = String.fromCharCode(lastCharCode - 1);
	if (lastCharCode === 0) {
		return getPreviousRank(s.substring(0, s.length - 1));
	}

	return `${s.substring(0, s.length - 1)}${previousLastChar}99`;
};

export const useHasRankIssuePermission = () => {
	const { permissions } = useProject();

	return permissions.scheduleIssues && permissions.editIssues;
};
