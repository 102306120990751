import { useMemo } from 'react';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { CATEGORY_TYPE, GOALS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	transformCategories,
	transformValuesData,
} from '../../common/utils/transform-field-values/index.tsx';
import type { TransformedInitialData } from '../../common/utils/transform-field-values/types.tsx';
import type { InitialData } from './types.tsx';

const transformInitialCategories = (data: InitialData) => {
	if (
		data.jira.jwmCategoryOptions?.edges != null &&
		Array.isArray(data.jira.jwmCategoryOptions.edges)
	) {
		return {
			totalCount: data.jira.jwmCategoryOptions.totalCount,
			options: transformCategories(data.jira.jwmCategoryOptions),
		};
	}
	return undefined;
};

export const transformInitialData = (data: InitialData): TransformedInitialData => {
	const transformedData = transformValuesData(data.jira.jqlBuilder);

	const categories = transformInitialCategories(data);
	if (categories) {
		transformedData[CATEGORY_TYPE] = categories;
	}

	return transformedData;
};

export const fireFieldValuesErrorAnalytics = (
	errorId: string,
	errorMessage: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any,
) => {
	fireErrorAnalytics({
		meta: {
			id: errorId,
			packageName: 'jiraBusinessFilters',
			teamName: 'wanjel',
		},
		attributes: {
			message: errorMessage,
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const useGoalsJqlTerm = (filterFields?: BusinessIssueField[]) => {
	const goalsJqlTerm = useMemo(
		() => filterFields?.find((field: BusinessIssueField) => field?.type === GOALS_CF_TYPE)?.id,
		[filterFields],
	);

	return goalsJqlTerm;
};
