import React, { useState } from 'react';
import { styled } from '@compiled/react';
import LoadingButton from '@atlaskit/button/loading-button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import EmojiKeyboardIcon from '@atlaskit/icon/glyph/emoji/keyboard';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ConditionalWrapper } from '@atlassian/jira-business-conditional-wrapper/src/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ContactAdminToModifyBoardMessage } from '../../../../../common/ui/contact-admin-to-modify-board/index.tsx';
import { OpenProjectSettingsMultipleWorkflows } from '../../../../../common/ui/open-project-settings-multiple-workflows/index.tsx';
import { useGroups } from '../../../../../controllers/groups-context/index.tsx';
import AsyncColumnDeleteModal from './column-delete-modal/index.tsx';
import messages from './messages.tsx';

export type Props = {
	hasMultipleWorkflows: boolean | undefined;
	isLoading: boolean | undefined;
	onRenameClick: () => void;
	columnName?: string;
};

const HeaderDropdown = ({ hasMultipleWorkflows, isLoading, onRenameClick, columnName }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();

	const project = useProject();
	const canAdministerProject = project.permissions.administerProject;
	const actionsDisabled =
		!canAdministerProject || hasMultipleWorkflows === true || isWorkflowOperationInProgress;

	const groups = useGroups();
	const onlyOneColumnLeft = groups.length <= 1;

	return (
		<>
			<DropdownMenu
				testId="work-management-board.ui.board.column.column-header.header-dropdown.header-dropdown-menu"
				trigger={({ triggerRef, ...props }) =>
					isVisualRefreshEnabled() ? (
						<IconButton
							{...props}
							label=""
							isLoading={isLoading}
							appearance="subtle"
							testId="work-management-board.ui.board.column.column-header.header-dropdown.more-button"
							icon={(iconProps) => (
								<ShowMoreHorizontalIcon
									{...iconProps}
									label={formatMessage(messages.columnActionsWithColumn, { columnName })}
									color={token('color.icon')}
								/>
							)}
							ref={triggerRef}
						/>
					) : (
						<StyledButton
							{...props}
							isLoading={isLoading}
							appearance="subtle"
							testId="work-management-board.ui.board.column.column-header.header-dropdown.more-button"
							iconBefore={
								<ShowMoreHorizontalIcon
									label={formatMessage(messages.columnActionsWithColumn, { columnName })}
									color={token('color.icon')}
								/>
							}
							ref={triggerRef}
						/>
					)
				}
				onOpenChange={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jwmBoardColumnMoreButton', {
						columnMoreActionsDisabled: actionsDisabled,
					});
				}}
				placement="bottom-end"
			>
				<DropdownItemGroup title={formatMessage(messages.dropdownHeaderTitle)}>
					<DropdownItem
						elemBefore={
							isVisualRefreshEnabled() ? null : <EmojiKeyboardIcon size="small" label="" />
						}
						onClick={() => {
							onRenameClick();
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'dropdownItem clicked',
								'jwmBoardColumnRenameDropdownItem',
							);
						}}
						isDisabled={actionsDisabled}
					>
						{formatMessage(messages.rename)}
					</DropdownItem>
					<ConditionalWrapper
						condition={onlyOneColumnLeft}
						renderWrapper={(children) => (
							<Tooltip content={formatMessage(messages.deleteDisabledTooltip)}>{children}</Tooltip>
						)}
					>
						<DropdownItem
							testId="work-management-board.ui.board.column.column-header.header-dropdown.delete-dropdown"
							isDisabled={onlyOneColumnLeft || actionsDisabled}
							elemBefore={isVisualRefreshEnabled() ? null : <TrashIcon size="small" label="" />}
							onClick={() => {
								setIsDeleteModalOpen(true);
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'dropdownItem clicked',
									'jwmBoardColumnDeleteDropdownItem',
								);
							}}
						>
							{formatMessage(messages.delete)}
						</DropdownItem>
					</ConditionalWrapper>
					{hasMultipleWorkflows === true && (
						<DropdownMessageWrapper>
							<OpenProjectSettingsMultipleWorkflows />
						</DropdownMessageWrapper>
					)}
					{!canAdministerProject && (
						<DropdownMessageWrapper>
							<ContactAdminToModifyBoardMessage />
						</DropdownMessageWrapper>
					)}
				</DropdownItemGroup>
			</DropdownMenu>
			<ModalTransition>
				{isDeleteModalOpen && (
					<AsyncColumnDeleteModal onClose={() => setIsDeleteModalOpen(false)} />
				)}
			</ModalTransition>
		</>
	);
};

const HeaderDropdownWithErrorBoundary = (props: Props) => (
	<ErrorBoundary id="jira-business.board.header-dropdown" packageName="jiraWorkManagementBoard">
		<HeaderDropdown {...props} />
	</ErrorBoundary>
);

export default HeaderDropdownWithErrorBoundary;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMessageWrapper = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
	maxWidth: '200px',
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(LoadingButton)({
	flexShrink: 0,
});
