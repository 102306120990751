import React, { createContext, useContext, useEffect, type ReactNode } from 'react';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useFetchWorkflows } from '../../services/workflows/index.tsx';
import type { WorkflowDetails } from '../../services/workflows/types.tsx';
import messages from './messages.tsx';

const FETCH_WORKFLOW_FLAG_ID = toFlagId('fetch-board-error-flag');

type ContextType = WorkflowDetails[] | undefined;

const WorkflowsContext = createContext<ContextType>(undefined);

type Props = {
	children: ReactNode;
	enabled?: boolean;
	workflows?: WorkflowDetails[];
};

export const WorkflowsProvider = ({ children, enabled = true, workflows }: Props) => {
	const { showFlag } = useFlagsService();
	const { fetch, data, error } = useFetchWorkflows();

	useEffect(() => {
		if (enabled && workflows == null) {
			fetch();
		}
	}, [enabled, fetch, workflows]);

	useEffect(() => {
		if (error) {
			showFlag({
				id: FETCH_WORKFLOW_FLAG_ID,
				type: 'error',
				title: messages.workflowsErrorTitle,
				description: messages.workflowsErrorDescription,
			});
		}
	}, [error, showFlag]);

	return (
		<WorkflowsContext.Provider value={workflows ?? data}>{children}</WorkflowsContext.Provider>
	);
};

export const useWorkflows = () => useContext(WorkflowsContext);
