import { useCallback } from 'react';
import { FILTER_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import type { Filters } from '../common/types.tsx';
import { serialiseJql } from './utils.tsx';

export const useFilters = () => {
	const [jql, saveFilters] = useViewPreference(FILTER_PREFERENCE);

	const setFilter = useCallback(
		(filters: Filters) => {
			const serialisedJql = serialiseJql(filters);
			saveFilters(serialisedJql !== '' ? serialisedJql : undefined);
		},
		[saveFilters],
	);

	return {
		setFilter,
		jql,
	};
};
