/**
 * @generated SignedSource<<80711d1684f80ca4cf7be827537f0e28>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type column_workManagementBoard_BoardColumn$data = {
  readonly " $fragmentSpreads": FragmentRefs<"card_workManagementBoard_jiraQuery">;
  readonly " $fragmentType": "column_workManagementBoard_BoardColumn";
};
export type column_workManagementBoard_BoardColumn$key = {
  readonly " $data"?: column_workManagementBoard_BoardColumn$data;
  readonly " $fragmentSpreads": FragmentRefs<"column_workManagementBoard_BoardColumn">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "column_workManagementBoard_BoardColumn",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "card_workManagementBoard_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e853b32ca74d9c7fb618c9928cdb3cc9";

export default node;
