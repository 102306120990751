import { getPreviousRank, getNextRank } from '@atlassian/jira-business-rank/src/index.tsx';
import {
	createStore,
	createContainer,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { RANK_ID, BOARD_FIELD_TEXT } from '../../common/constants.tsx';
import type { BoardIssue, BoardIssueId, BoardIssueFields, RankInput } from '../../common/types.tsx';

export type State = {
	issues: Map<BoardIssueId, BoardIssue>;
};

const initialState: State = { issues: new Map() };

export const IssueStoreContainer = createContainer();

export const Store = createStore({
	containedBy: IssueStoreContainer,
	initialState,
	actions: {
		setIssues:
			(issues: BoardIssue[]) =>
			({ setState }) => {
				const issueMap = new Map();
				issues.forEach((issue) => {
					issueMap.set(issue.id, issue);
				});
				setState({ issues: issueMap });
			},
		updateIssues:
			(issues: BoardIssue[]) =>
			({ setState, getState }) => {
				const issueMap = new Map(getState().issues);
				issues.forEach((issue) => {
					issueMap.set(issue.id, issue);
				});
				setState({ issues: issueMap });
			},
		updateIssueFields:
			({
				issueId,
				fields,
				rank,
			}: {
				issueId: BoardIssueId;
				fields?: Partial<BoardIssueFields>;
				rank?: RankInput;
			}) =>
			({ setState, getState }) => {
				const fieldUpdates = { ...fields };

				const issueMap = new Map(getState().issues);
				const issue = issueMap.get(issueId);

				if (issue == null) {
					return;
				}

				if (rank != null) {
					const relativeIssue = issueMap.get(rank.relativeIssueId);

					if (relativeIssue != null) {
						const relativeIssueRank = relativeIssue.fields[RANK_ID].value;

						fieldUpdates[RANK_ID] = {
							fieldId: RANK_ID,
							type: BOARD_FIELD_TEXT,
							value:
								rank.position === 'before'
									? getPreviousRank(relativeIssueRank)
									: getNextRank(relativeIssueRank),
						};
					}
				}

				if (Object.keys(fieldUpdates).length === 0) {
					return;
				}

				Object.entries(fieldUpdates).forEach(([fieldId, field]) => {
					if (field == null) {
						delete issue.fields[fieldId];
						return;
					}

					issue.fields[fieldId] = field;
				});

				issueMap.set(issueId, issue);

				setState({ issues: issueMap });
			},
		reset:
			() =>
			({ setState }) => {
				setState(initialState);
			},
		removeIssues:
			(issueIds: number[]) =>
			({ setState, getState }) => {
				const issueMap = new Map(getState().issues);
				issueIds.forEach((issueId) => {
					issueMap.delete(issueId);
				});
				setState({ issues: issueMap });
			},
	},
});

export const useIssueStoreState = createStateHook(Store);
export const useIssueStoreActions = createActionsHook(Store);
