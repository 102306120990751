import { useCallback } from 'react';
import type { Column } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { persistStatusGroups } from '../../utils/columns-order/index.tsx';
import { useGroupByField } from '../group-by/index.tsx';
import { useGroupsOrder } from '../groups-order/index.tsx';
import { useWorkflowStoreActions, useWorkflowStoreState } from '../workflow-store/index.tsx';
import messages from './messages.tsx';

export const MOVE_COLUMN_ERROR_FLAG_ID = toFlagId('move-column-error-flag');
type ColumnMoveHandlerReturnType = (updatedGroupIds: string[]) => Promise<void>;

export const useGroupMoveHandler = (): ColumnMoveHandlerReturnType => {
	const { workflowName, columns } = useWorkflowStoreState();
	const { setColumns } = useWorkflowStoreActions();
	const groupBy = useGroupByField();
	const [, setGroupOrder] = useGroupsOrder();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const project = useProject();
	const projectId = String(project.id);

	return useCallback(
		async (updatedGroupIds: string[]) => {
			if (workflowName != null) {
				fireUIAnalytics(createAnalyticsEvent({}), 'column dragged', 'board');

				if (groupBy === GROUP_BY_STATUS) {
					const updatedColumns: Column[] = [];
					updatedGroupIds.forEach((groupId) => {
						const column = columns.find((col) => String(col.statusId) === groupId);

						if (column) {
							updatedColumns.push(column);
						}
					});

					setColumns(updatedColumns);

					try {
						await persistStatusGroups(projectId, workflowName, updatedGroupIds);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						setColumns(columns);

						fireErrorAnalytics({
							meta: {
								id: 'jwmBoardControllerOnColumnMove',
								packageName: 'jiraWorkManagementBoard',
								teamName: 'wanjel',
							},
							attributes: {
								message: 'Failed to set column order in JWM board',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
						showFlag({
							id: MOVE_COLUMN_ERROR_FLAG_ID,
							type: 'error',
							title: messages.columnMoveErrorTitle,
							description: messages.columnMoveErrorMessage,
						});
					}
				} else {
					setGroupOrder(updatedGroupIds);
				}
			}
		},
		[
			workflowName,
			createAnalyticsEvent,
			groupBy,
			setColumns,
			columns,
			projectId,
			showFlag,
			setGroupOrder,
		],
	);
};
