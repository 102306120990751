import { useEffect, useMemo } from 'react';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import { GOALS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useFilterFields } from '../../../controllers/filter-fields/index.tsx';
import type { Value } from '../../types.tsx';

type UseGoalsFilterAnalyticsEvent = {
	filters: {
		[key: string]: Value;
	};
	view: JWMView;
};

export const useGoalsFilterAnalyticsEvent = ({ filters, view }: UseGoalsFilterAnalyticsEvent) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: filterFields } = useFilterFields();
	const goalField = useMemo(
		() => filterFields.find((field) => field.type === GOALS_CF_TYPE),
		[filterFields],
	);

	useEffect(() => {
		if (goalField && filters[goalField.id]) {
			const goalsFilter = filters[goalField.id];
			fireTrackAnalytics(createAnalyticsEvent({}), 'goalsFilter applied', {
				source: view,
				goalsCount: Array.isArray(goalsFilter) ? goalsFilter.length : 0,
			});
		}
	}, [createAnalyticsEvent, filters, goalField, view]);
};
