import { useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	PACKAGE_NAME,
	USER_LIMIT_PROJECT_ERROR_CODE,
	USER_LIMIT_INSTANCE_ERROR_CODE,
	RECAPTCHA_ERROR_CODE,
	BAD_REQUEST_ERROR_CODE,
	NETWORK_ERROR_CODE,
	AUTH_FAILED_ERROR_CODE,
	USER_NOT_FOUND_ERROR_CODE,
	SERVER_ERROR_ERROR_CODE,
} from './constants.tsx';
import { type ModalType, InvitationStatusEnum } from './types.tsx';
import {
	createInviteSuccessFlagConfig,
	inviteFailFlagConfig,
	createInviteFailedUserLimitFlagConfig,
	createAddSuccessFlagConfig,
	inviteFailBadRequestFlagConfig,
	inviteFailNetworkErrorFlagConfig,
	inviteFailGenericFlagConfig,
} from './utils.tsx';

export const useInviteModalHandlers = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagService();
	const { isSiteAdmin } = useTenantContext();
	const projectData = useProject();

	const handleAddSuccess = useCallback(
		({
			modalType,
			totalPeopleAdded,
			email,
			inviteStatus,
		}: {
			modalType: ModalType;
			totalPeopleAdded: number;
			email: string;
			inviteStatus?: InvitationStatusEnum;
		}) => {
			const hasSentInvitation = inviteStatus === InvitationStatusEnum.INVITED;
			const hasSuccessfullyAddedPeople = !inviteStatus && projectData.permissions.administerProject;

			showFlag(
				hasSuccessfullyAddedPeople
					? createAddSuccessFlagConfig({
							totalPeopleAdded,
							email,
							projectKey: projectData.key,
						})
					: createInviteSuccessFlagConfig({
							totalPeopleAdded,
							email,
							hasSentInvitation,
						}),
			);

			// The event tracked by the Invite People SLOs
			fireTrackAnalytics(createAnalyticsEvent({}), `jwm${modalType}Invite success`, {
				inviteStatus,
			});
		},
		[createAnalyticsEvent, showFlag, projectData],
	);

	const handleError = useCallback(
		({
			modalType,
			errorMessage,
			statusCode,
		}: {
			modalType: ModalType;
			errorMessage: string;
			statusCode?: number;
		}) => {
			if (
				errorMessage.includes(USER_LIMIT_PROJECT_ERROR_CODE) ||
				errorMessage.includes(USER_LIMIT_INSTANCE_ERROR_CODE)
			) {
				showFlag(createInviteFailedUserLimitFlagConfig(isSiteAdmin, createAnalyticsEvent));
				fireTrackAnalytics(createAnalyticsEvent({}), 'userLimit reached', `jwm${modalType}Invite`);
				return;
			}

			if (errorMessage.includes(BAD_REQUEST_ERROR_CODE)) {
				showFlag(inviteFailBadRequestFlagConfig);
			} else if (errorMessage.includes(NETWORK_ERROR_CODE)) {
				showFlag(inviteFailNetworkErrorFlagConfig);
			} else if (
				errorMessage.includes(AUTH_FAILED_ERROR_CODE) ||
				errorMessage.includes(SERVER_ERROR_ERROR_CODE) ||
				errorMessage.includes(USER_NOT_FOUND_ERROR_CODE)
			) {
				showFlag(inviteFailGenericFlagConfig);
			} else {
				showFlag(inviteFailFlagConfig);
			}

			const isClientError = statusCode && statusCode >= 400 && statusCode < 500;

			if (errorMessage.includes(RECAPTCHA_ERROR_CODE) || isClientError) {
				return;
			}

			fireErrorAnalytics({
				meta: {
					id: `jwm${modalType}Invite`,
					packageName: PACKAGE_NAME,
					teamName: 'jira-warepil',
				},
				attributes: {
					message: errorMessage,
				},
				error: new Error(errorMessage),
				sendToPrivacyUnsafeSplunk: true,
			});

			// The event tracked by the Invite People SLOs
			fireTrackAnalytics(createAnalyticsEvent({}), `jwm${modalType}Invite failed`);
		},
		[createAnalyticsEvent, isSiteAdmin, showFlag],
	);

	return {
		handleAddSuccess,
		handleError,
	};
};
