import gqlTagGira from 'graphql-tag';

export const WORKFLOWS_QUERY = gqlTagGira`
    query JWMWorkflowWithIssueTypes($projectId: Long!, $filter: JWMWorkflowWithIssueTypesFilter, $first: Int, $after: String, $last: Int, $before: String) {
        jwmWorkflowWithIssueTypes(projectId: $projectId, filter: $filter, first: $first, after: $after, last: $last, before: $before) {
            edges {
                node {
                    workflow {
                        workflowId
                        name
                        transitions(first: $first) {
                            edges {
                                node {
                                    transitionId
                                    name
                                    isInitial
                                    isGlobal
                                    isConditional
                                    to {
                                        statusId
                                    }
                                }
                            }
                        }
                    }
                    issueTypes(first: $first) {
                        edges {
                            node {
                                issueTypeId
                                name
                                avatar {
                                  medium
                                }
                              	hierarchy {
                              	  level
                              	}
                            }
                        }
                    }
                }
            }
        }
    }
`;
