/** @jsx jsx */
import React, { memo, useCallback, type ChangeEvent, useState, Fragment, useEffect } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import Close from '@atlaskit/icon/core/migration/close--cross';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import Search from '@atlaskit/icon/core/search';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getTextFieldAttributes } from '../../common/utils/text-field-attributes/index.tsx';
import { useSimpleSearch } from '../../controllers/simple-search/index.tsx';
import messages from './messages.tsx';

const SEARCH_FIELD_WIDTH = 184;

type SimpleSearchFieldProps = {
	placeholder: MessageDescriptor;
	view: string;

	describedbyId?: string;
	onChange?: (arg1: String) => void;
};

export type SimpleSearchTextFieldProps = {
	inputRef?: React.Ref<HTMLElement | null>;
	placeholder: MessageDescriptor;
	view: string;
	value?: string;
	testId?: string;
	onChange: (arg1: string) => void;
	onFocus?: () => void;
	isDisabled?: boolean;
	// Make label prop mandatory when cleaning up the feature flag jwm_header_filters_a11y_fixes
	label?: string;
	describedbyId?: string;
	themedField?: boolean;
	isFullWidth?: boolean;
};

export const SimpleSearchTextField = memo<SimpleSearchTextFieldProps>(
	({
		inputRef,
		placeholder,
		value: externalValue = '',
		onChange,
		onFocus,
		view,
		testId = 'searchfield',
		isDisabled,
		label,
		describedbyId,
		themedField = true,
		isFullWidth = false,
	}: SimpleSearchTextFieldProps) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const [searchValue, setSearchValue] = useState(externalValue);

		const handleSearchChange = useCallback(
			(newValue: string) => {
				fireUIAnalytics(createAnalyticsEvent({}), 'simpleSearchField updated', {
					view,
					...getTextFieldAttributes(newValue),
				});
				onChange(newValue);
			},
			[createAnalyticsEvent, onChange, view],
		);

		const [handleSearchChangeDebounced] = useDebouncedCallback(handleSearchChange, 500);

		const handleChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				setSearchValue(e.target.value);
				handleSearchChangeDebounced(e.target.value);
			},
			[handleSearchChangeDebounced],
		);

		const handleClear = useCallback(() => {
			setSearchValue('');
			handleSearchChangeDebounced('');
		}, [setSearchValue, handleSearchChangeDebounced]);

		const themedIconPrimaryColor = isDisabled
			? Tokens.COLOR_TEXT_DISABLED
			: Tokens.COLOR_TEXT_SUBTLEST;

		// eslint-disable-next-line no-nested-ternary
		const Container = themedField
			? isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
				? ThemedContainer
				: ThemedContainerOld
			: Fragment;

		useEffect(() => {
			setSearchValue(externalValue);
		}, [externalValue]);

		const elemAfterSearchIcon = !isVisualRefreshEnabled() ? (
			<SearchIcon
				label=""
				color="currentColor"
				LEGACY_size="small"
				LEGACY_primaryColor={themedField ? themedIconPrimaryColor : undefined}
			/>
		) : null;

		let textFieldWidth;
		if (isVisualRefreshEnabled()) {
			textFieldWidth = isFullWidth ? '100%' : SEARCH_FIELD_WIDTH;
		}

		return (
			<Container>
				<Textfield
					ref={inputRef}
					aria-describedby={describedbyId}
					testId={testId}
					css={!isVisualRefreshEnabled() && textFieldStyles}
					value={searchValue}
					isCompact
					placeholder={formatMessage(placeholder)}
					aria-label={label || formatMessage(placeholder)}
					onChange={handleChange}
					onFocus={onFocus}
					isDisabled={isDisabled}
					elemAfterInput={
						searchValue !== '' ? (
							<Box paddingInlineEnd={isVisualRefreshEnabled() ? 'space.025' : undefined}>
								<IconButton
									icon={(iconProps) => (
										<Close
											{...iconProps}
											label={formatMessage(messages.clear)}
											spacing="none"
											color={token('color.text')}
											LEGACY_size="small"
											LEGACY_primaryColor={themedField ? themedIconPrimaryColor : undefined}
										/>
									)}
									appearance="subtle"
									spacing="compact"
									label={formatMessage(messages.clear)}
									testId="business-filters.ui.simple-search-field.clear-button"
									onClick={handleClear}
								/>
							</Box>
						) : (
							elemAfterSearchIcon
						)
					}
					elemBeforeInput={
						isVisualRefreshEnabled() && (
							<Box xcss={iconStyles}>
								<Search color="currentColor" label="" spacing="none" />
							</Box>
						)
					}
					width={textFieldWidth}
				/>
			</Container>
		);
	},
);

export const SimpleSearchField = memo<SimpleSearchFieldProps>(
	({ placeholder, view, describedbyId, onChange }: SimpleSearchFieldProps) => {
		const [simpleSearch, setSimpleSearch] = useSimpleSearch();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleFocus = useCallback(() => {
			fireUIAnalytics(createAnalyticsEvent({}), 'simpleSearchField focused', {
				view,
			});
		}, [createAnalyticsEvent, view]);

		useEffect(() => {
			if (onChange) {
				onChange(simpleSearch);
			}
		}, [onChange, simpleSearch]);

		return (
			<Container>
				<SimpleSearchTextField
					view={view}
					onFocus={handleFocus}
					describedbyId={describedbyId}
					onChange={(text) => {
						if (onChange) {
							onChange(text);
						}
						return setSimpleSearch(text);
					}}
					value={simpleSearch}
					placeholder={placeholder}
				/>
			</Container>
		);
	},
);

const textFieldStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&[data-ds--text-field--container]': {
		paddingRight: token('space.100', '8px'),
	},
});

const iconStyles = xcss({
	display: 'inline-flex',
	marginLeft: 'space.075',
});

/* eslint-disable @atlaskit/design-system/no-unsafe-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ThemedContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-disabled': Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-input': Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-input-pressed': Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-input-hovered': Tokens.COLOR_BACKGROUND_INPUT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-border-input': Tokens.COLOR_BORDER,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text': Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-subtlest': Tokens.COLOR_TEXT_SUBTLEST,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-disabled': Tokens.COLOR_TEXT_DISABLED,
});
/* eslint-enable @atlaskit/design-system/no-unsafe-design-token-usage */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ThemedContainerOld = styled(ThemedContainer)({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	'--ds-border-input': Tokens.COLOR_BORDER_INPUT,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
});
