import { expValEquals } from '@atlassian/jira-feature-experiments';
import { COMPONENTS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

export const getJqlTerm = (fieldId: string, fieldType?: string) => {
	// custom fields are indexed by their ids and only work with JQL when referenced as such `cf[id]`.
	if (fieldId.indexOf('customfield_') === 0) {
		const customFieldId = fieldId.split('customfield_')[1];
		return `cf[${customFieldId}]`;
	}

	// We are hardcoding the components field jqlTerm because is not the same as field ID
	// We should get the jqlTerm from the BE
	// https://atlassian.slack.com/archives/CFGMKF5QC/p1724650674231049?thread_ts=1720579171.825049&cid=CFGMKF5QC
	if (
		fieldType === COMPONENTS_TYPE &&
		expValEquals('list_view_conditional_formatting', 'cohort', 'variation')
	) {
		return 'component';
	}

	return fieldId;
};
