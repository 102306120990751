import {
	CATEGORY_THEMES,
	GREY_LIGHTEST_META,
} from '../../ui/category-form/category-color-picker/constants.tsx';

export const getRandomColor = () => {
	const randomIndex = Math.floor(Math.random() * CATEGORY_THEMES.length);
	return CATEGORY_THEMES[randomIndex].name;
};

export const getColorNameFromValue = (colorValue: string) =>
	CATEGORY_THEMES.find((item) => item.backgroundColor === colorValue)?.name ?? '';

export const getValueFromColorName = (colorName: string) =>
	CATEGORY_THEMES.find((item) => item.name === colorName)?.backgroundColor ?? '';

export const getColorMetadata = (colorName: string | null | undefined) => {
	if (!colorName) {
		return GREY_LIGHTEST_META;
	}
	return CATEGORY_THEMES.find((item) => item.name === colorName) ?? GREY_LIGHTEST_META;
};

export const getAllColorNames = () => CATEGORY_THEMES.map((item) => item.name);
