import gqlTagGira from 'graphql-tag';

export const GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT = gqlTagGira`
  fragment GetIssueTypesAndFields on JiraBusinessAvailableFields {
    issueType {
      issueTypeId
      name
      type
      avatarId
      avatar {
        medium
      }
      hierarchy {
        level
      }
    }
    isDefaultIssueType
    fields {
      edges {
        node {
          fieldId
          type
          name
          aliasFieldId
          ... on GenericIssueField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraIssueTypeField {
            fieldConfig {
              isRequired
              isEditable
            }
            issueTypes {
              nodes {
                name
                id
              }
            }
          }
          ... on JiraPriorityField {
            fieldConfig {
              isRequired
              isEditable
            }
            priority {
              priorityId
            }
            priorities {
              nodes {
                priorityId
                name
                iconUrl
              }
            }
          }
          ... on JiraLabelsField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSingleSelectUserPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSingleLineTextField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraDatePickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraAffectedServicesField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraAttachmentsField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraCMDBField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraCascadingSelectField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraCheckboxesField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraColorField {
            fieldConfig {
              isRequired
              isEditable
            }
            color {
              colorKey
            }
          }
          ... on JiraComponentsField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraDateTimePickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraEpicLinkField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraIssueLinkField {
            fieldConfig {
              isRequired
              isEditable
            }
            issueLinkTypeRelations(first: 10) {
              nodes {
                linkTypeId
                relationName
              }
            }
          }
          ... on JiraIssueRestrictionField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraMultipleGroupPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraMultipleSelectField {
            fieldConfig {
              isRequired
              isEditable
            }
            fieldOptions {
              nodes {
                id
                optionId
                value
              }
            }
          }
          ... on JiraMultipleSelectUserPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraMultipleVersionPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraNumberField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraPeopleField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraProjectField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraRadioSelectField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraResolutionField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraRichTextField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSecurityLevelField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSingleGroupPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSingleSelectField {
            fieldConfig {
              isRequired
              isEditable
            }
            fieldOptions {
              nodes {
                id
                optionId
                value
              }
            }
          }
          ... on JiraSingleVersionPickerField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraSprintField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraTeamField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraUrlField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JsmRequestTypeField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JWMCategoryField {
            fieldConfig {
              isRequired
              isEditable
            }
            fieldOptions {
              nodes {
                id
                optionId
                value
                color {
                    colorKey
                }
              }
            }
          }
          ... on JiraCheckboxesField {
            fieldConfig {
              isRequired
              isEditable
            }
            fieldOptions {
              nodes {
                id
                optionId
                value
              }
            }
          }
          ... on JiraAtlassianTeamField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
          ... on JiraGoalsField {
            fieldConfig {
              isRequired
              isEditable
            }
          }
        }
      }
    }
  }
`;

export const ISSUE_TYPES_AND_FIELDS_LIST_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsListView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;

export const ISSUE_TYPES_AND_FIELDS_CALENDAR_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsCalendarView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;

export const ISSUE_TYPES_AND_FIELDS_TIMELINE_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsTimelineView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;

export const ISSUE_TYPES_AND_FIELDS_BOARD_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsBoardView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;

export const ISSUE_TYPES_AND_FIELDS_FORM_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsFormView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;

export const ISSUE_TYPES_AND_FIELDS_FORM_SUBMIT_QUERY = gqlTagGira`
  query JiraBusinessAvailableFieldsFormSubmitView ($projectKey: String!, $issueOperation: JiraBusinessIssueOperation, $first: Int, $last: Int, $before: String, $after: String) {
      jiraBusinessAvailableFields(projectKey: $projectKey, issueOperation: $issueOperation, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            ...GetIssueTypesAndFields
          }
        }
      }
  }
  ${GET_ISSUE_TYPES_AND_FIELDS_FRAGMENT}
`;
