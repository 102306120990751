import { di } from 'react-magnetic-di';

type EmbedParam = 'inlineCreate' | 'selectedIssue' | 'inviteOpen';

type Param = {
	key: EmbedParam;
	value: string;
};

export const openViewFromEmbed = (param?: Param): void => {
	di(window);
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const cleanUrl = window.location.href.replace('/embed', '');
	const currentUrl = new URL(cleanUrl);
	if (param != null) {
		currentUrl.searchParams.append(param.key, param.value);
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.open(currentUrl.href, '_blank');
};
