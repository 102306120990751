import React, { memo, useMemo, useCallback } from 'react';
import type InvitePeopleType from '@atlassian/invite-people';
import type {
	BulkInviteFailureResponse,
	BulkInviteSuccessResponse,
	BulkInviteResponse,
	InvitePeopleProps,
} from '@atlassian/invite-people/types';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import {
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_SOFTWARE_LIST,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { JiraModal } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import InvitePeopleModalAsync from '@atlassian/jira-project-invite-people-modal/async.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import {
	type ModalProps,
	InvitationStatusEnum,
} from '../../../../../controllers/modal-handlers/types.tsx';

type OnInviteCompleteHandler = InvitePeopleProps['onInviteCompletedHandler'];

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const InvitePeopleAsync = lazy<typeof InvitePeopleType>(
	() => import(/* webpackChunkName: "async-invite-people" */ '@atlassian/invite-people'),
	{ ssr: false },
);

export const getInviteStatus = (res: BulkInviteSuccessResponse) => {
	if (res.accessRequested?.length) {
		return InvitationStatusEnum.ACCESS_REQUESTED;
	}
	return InvitationStatusEnum.INVITED;
};

const LIST_ROUTES: string[] = [ROUTE_NAMES_SOFTWARE_LIST_CLASSIC, ROUTE_NAMES_SOFTWARE_LIST];

const isFailureResponse = (res: BulkInviteResponse): res is BulkInviteFailureResponse =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(res as BulkInviteFailureResponse).failure !== undefined;

export const InvitePeopleModal = memo<ModalProps>(
	({ onInviteSuccess, onInviteError, onModalClose, source = 'projectBoard' }: ModalProps) => {
		const route = useCurrentRoute();
		const cloudId = useCloudId();
		const appEditions = useAppEditions();
		const { data: orgId } = useOrgId();
		const projectData = useProject();
		const isAdmin = useIsAdmin();

		const onInviteCompleteHandler: OnInviteCompleteHandler = useCallback(
			(response: BulkInviteResponse) => {
				if (isFailureResponse(response)) {
					const { message, status } = response.failure;
					onInviteError({
						modalType: 'Instance',
						errorMessage: message,
						statusCode: status,
					});

					return;
				}

				const inviteStatus = getInviteStatus(response);
				const { invited, accessRequested } = response;

				onInviteSuccess({
					totalPeopleAdded: invited.length || accessRequested.length,
					email: invited[0]?.email || accessRequested[0]?.email || '',
					modalType: 'Instance',
					inviteStatus,
				});
			},
			[onInviteError, onInviteSuccess],
		);

		const isSoftwareRoute = useMemo(() => LIST_ROUTES.includes(route.name), [route]);

		return (
			<ErrorBoundary
				id="async.jira-business.project-invite-people-modal.invite-people"
				packageName="jira-business.project-invite-people-modal.invite-people"
				teamName="jira-warepil"
			>
				<Placeholder name="project-invite-people-modal.invite-people" fallback={null}>
					<ShortcutScope>
						<JiraModal
							messageId="business-invite-people.ui.add-people-with-avatars.add-people.project-invite-people-modal.invite-people-modal.jira-modal"
							messageType="transactional"
							onClose={onModalClose}
							width="small"
							shouldScrollInViewport
							testId="business-invite-people.ui.add-people-with-avatars.add-people.project-invite-people-modal.invite-people-modal"
						>
							<FireTrackAnalytics eventName="jwmInstanceInviteComponent viewed" />
							{isSoftwareRoute ? (
								<InvitePeopleModalAsync
									isOpen
									cloudId={cloudId}
									projectId={projectData.id}
									jiraProjectKey={projectData.key}
									isSiteAdmin={isAdmin}
									onClose={onModalClose}
									appEdition={getEdition(JIRA_SOFTWARE, appEditions)}
									jiraProjectName={projectData.name}
								/>
							) : (
								<InvitePeopleAsync
									onSendHandler={onModalClose}
									onInviteCompletedHandler={onInviteCompleteHandler}
									onCancelHandler={onModalClose}
									resourceAri={`ari:cloud:jira::site/${cloudId}`}
									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									continueUrl={window.location.href}
									enableThirdParty
									source={source}
									orgId={orgId}
								/>
							)}
						</JiraModal>
					</ShortcutScope>
				</Placeholder>
			</ErrorBoundary>
		);
	},
);
