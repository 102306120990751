import React, { useEffect, useMemo, useState } from 'react';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import { PEOPLE_CF_TYPE, USER_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { EXTRA_CUSTOM_FIELD_TYPES } from '../../common/constants.tsx';
import type { FieldConfig, AsyncAvatarField, AliasField } from '../../common/types.tsx';
import { useFetchFieldValues } from '../../services/field-values/index.tsx';
import { useFieldConfigV2 } from '../field-config-v2/index.tsx';
import { useFieldConfig as useFieldConfigOld } from '../field-config/index.tsx';
import type { Resolvers } from '../types.tsx';

const emptySet = new Set<string>();

const CustomFieldsLoader = ({
	allowedFieldTypes,
	allowedAliasFieldId,
	setFieldConfig,
}: {
	allowedFieldTypes: Set<string>;
	allowedAliasFieldId?: Set<string>;
	setFieldConfig: ({
		fieldConfig,
		resolvers,
		loading,
	}: {
		fieldConfig: FieldConfig;
		resolvers: Resolvers;
		loading: boolean;
	}) => void;
}) => {
	const { fieldConfig, resolvers, loading } = useFieldConfigOld({
		allowedFieldTypes,
		allowedAliasFieldId,
	});

	useEffect(() => {
		setFieldConfig({ fieldConfig, resolvers, loading });
	}, [fieldConfig, resolvers, loading, setFieldConfig]);

	return null;
};

// Fetches custom fields from availableFields API and normal fields from platform (NIN) API
// This is a short term solution until NIN API adds support for custom fields
export const useFieldConfigWithCustomFields = ({
	view,
	allowCustomFields,
	allowedAliasFieldId,
	allowedFieldTypes,
}: {
	view?: JWMView;
	allowedFieldTypes: Set<string>;
	allowedAliasFieldId?: Set<string>;
	allowCustomFields?: boolean;
}) => {
	const [newAllowedFieldTypes, oldAllowedFieldTypes] = useMemo(() => {
		// split allowedFieldTypes into new and old input for useFieldConfigV2 and useFieldConfigOld
		const newTypes = new Set<string>(allowedFieldTypes);
		const oldTypes = new Set<string>();

		EXTRA_CUSTOM_FIELD_TYPES.forEach((type) => {
			if (allowedFieldTypes.has(type)) {
				newTypes.delete(type);
				oldTypes.add(type);
			}
		});

		return [newTypes, oldTypes];
	}, [allowedFieldTypes]);

	const hasCustomFields = Boolean(allowCustomFields) && oldAllowedFieldTypes.size > 0;
	const hasAliasFields = allowedAliasFieldId != null && allowedAliasFieldId.size > 0;
	const shouldLoadOldFieldConfig = hasAliasFields || hasCustomFields;

	const {
		fieldConfig: fieldConfigNew,
		resolvers: resolversNew,
		loading: isLoadingFieldConfigNew,
	} = useFieldConfigV2({ view, allowedFieldTypes: newAllowedFieldTypes });

	const [
		{ fieldConfig: fieldConfigOld, loading: isLoadingFieldConfigOld, resolvers: resolversOld },
		setFieldConfig,
	] = useState<{ fieldConfig: FieldConfig; resolvers: Resolvers; loading: boolean }>({
		fieldConfig: {},
		resolvers: {},
		loading: shouldLoadOldFieldConfig,
	});

	const customFieldsLoader = useMemo(() => {
		if (!shouldLoadOldFieldConfig) {
			return null;
		}

		return (
			<CustomFieldsLoader
				allowedFieldTypes={allowCustomFields ? oldAllowedFieldTypes : emptySet}
				allowedAliasFieldId={allowedAliasFieldId}
				setFieldConfig={setFieldConfig}
			/>
		);
	}, [allowCustomFields, allowedAliasFieldId, oldAllowedFieldTypes, shouldLoadOldFieldConfig]);

	const { fetchUsers } = useFetchFieldValues({ view, allowedFieldTypes });

	const fieldConfig = useMemo(() => {
		const aliasFields: FieldConfig = {};
		const otherFields: FieldConfig = {};

		Object.keys(fieldConfigOld).forEach((id) => {
			if (
				fieldConfigOld[id].systemType === PEOPLE_CF_TYPE ||
				fieldConfigOld[id].systemType === USER_CF_TYPE
			) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				fieldConfigOld[id] = {
					...fieldConfigOld[id],
					loadOptions: fetchUsers,
				} as AsyncAvatarField;
			}

			// We want to assure that alias fields are always at the end of the filter
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if ((fieldConfigOld[id] as AliasField).aliasFieldId != null) {
				aliasFields[id] = fieldConfigOld[id];
			} else {
				otherFields[id] = fieldConfigOld[id];
			}
		});

		return {
			...otherFields,
			...fieldConfigNew,
			...aliasFields,
		};
	}, [fieldConfigOld, fieldConfigNew, fetchUsers]);

	return {
		fieldConfig,
		resolvers: { ...resolversOld, ...resolversNew },
		customFieldsLoader,
		loading: isLoadingFieldConfigNew || isLoadingFieldConfigOld,
	};
};
