import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getColorMetadata } from '../../../utils/colors/index.tsx';

export type Props = {
	categoryName: string;
	categoryColor: string | null;
	isInteractive?: boolean;
};

const CategoryTag = ({ categoryName, categoryColor, isInteractive = true }: Props) => {
	const { textColor, backgroundColor: tagColor } = getColorMetadata(categoryColor);

	return (
		<CategoryTagWrapper
			isInteractive={isInteractive}
			textColor={isVisualRefreshEnabled() ? token('color.text') : textColor}
			aria-label={categoryName}
			data-testid="business-categories.ui.category-form.category-tag.category-tag-wrapper"
		>
			<StyledTag color={tagColor}>{categoryName}</StyledTag>
		</CategoryTagWrapper>
	);
};

export default memo(CategoryTag);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CategoryTagWrapper = styled.div<{ textColor: string; isInteractive: boolean }>({
	display: 'inline-flex',
	justifyContent: 'left',
	alignItems: 'center',
	transition: '0.3s',
	maxWidth: '100%',
	minWidth: 0,
	minHeight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ textColor }) => textColor,
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		cursor: ({ isInteractive }) => (isInteractive ? 'pointer' : 'default'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagOld = styled.div<{ color: string }>({
	borderRadius: token('border.radius.100', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => color,
	padding: token('space.050', '4px'),
	font: token('font.body'),
	lineHeight: '14px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagNew = styled.div<{ color: string }>({
	borderRadius: token('border.radius.050', '3px'),
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	border: ({ color }) => `1px solid ${color}`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	font: token('font.body'),
	lineHeight: '14px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '100%',
});

const StyledTag = componentWithCondition(isVisualRefreshEnabled, StyledTagNew, StyledTagOld);
