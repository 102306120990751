import React, { memo, useCallback, useEffect, type MouseEvent } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { BoardIssue } from '../../../../../../common/types.tsx';
import { useExpandCollapseSubtasks } from '../../../../../../controllers/expand-collapse-subtasks/index.tsx';
import { useIssueSubtasks } from '../../../../../../controllers/issue-subtasks/index.tsx';
import SubtaskList from './subtask-list/index.tsx';
import SubtasksExpandCollapse from './subtasks-expand-collapse/index.tsx';

type Props = { issue: BoardIssue };

const Subtasks = ({ issue }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isExpanded, toggleSubtasks } = useExpandCollapseSubtasks(issue.id);
	const { loading, fetch } = useIssueSubtasks(issue);

	const hasSubtasks = Boolean(issue.subtaskMetadata?.total);

	useEffect(() => {
		if (isExpanded && hasSubtasks) {
			fetch();
		}

		// empty dependencies as we only want to run this effect once on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToggleClick = useCallback(() => {
		if (!isExpanded) {
			fetch();
		}

		toggleSubtasks();

		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jwmBoardCardSubtaskTrigger', {
			isExpanded,
		});
	}, [createAnalyticsEvent, fetch, isExpanded, toggleSubtasks]);

	const preventEventBubbling = useCallback((event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();
	}, []);

	// Do not display any subtask UI if there are no subtasks
	if (!issue.subtaskMetadata?.total) {
		return null;
	}

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div onClick={preventEventBubbling}>
			<SubtasksExpandCollapse
				isExpanded={isExpanded}
				isLoading={loading}
				onToggle={handleToggleClick}
				subtaskMetadata={issue.subtaskMetadata}
			/>

			{isExpanded && !loading && <SubtaskList parent={issue} />}
		</div>
	);
};

export default memo(Subtasks);
