import { memo } from 'react';
import { useSubscribe } from '@atlassian/jira-graphql-subscription/src/services/subscribe/index.tsx';
import { ISSUE_UPDATED_EVENT } from '../../constants.tsx';
import type { IssueSubscriptionProps } from '../../types.tsx';
import { getResponseError } from '../../utils.tsx';
import { JWM_UPDATE_ISSUE_SUBSCRIPTION } from './gql.tsx';
import type { UpdateIssueResponse } from './types.tsx';

const operationName = 'JwmIssueUpdatedSubscription';

export const UpdateIssueSubscription = memo<IssueSubscriptionProps>(
	({ cloudId, projectId, onReceive, onError }: IssueSubscriptionProps) => {
		useSubscribe<UpdateIssueResponse>(
			{
				query: JWM_UPDATE_ISSUE_SUBSCRIPTION,
				variables: {
					cloudId,
					projectId,
				},
				operationName,
			},
			{
				next: (value) => {
					const error = getResponseError(value);
					if (error) {
						onError(error, operationName);
					} else {
						const payload = value.data?.jira?.onIssueUpdatedByProject;
						if (payload) {
							onReceive({
								type: ISSUE_UPDATED_EVENT,
								payload,
							});
						}
					}
				},
			},
		);

		return null;
	},
);
