import React from 'react';
import EditorBulletListIcon from '@atlaskit/icon/core/list-bulleted';
import EditorBulletListIconLegacy from '@atlaskit/icon/glyph/editor/bullet-list';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getCascadingSelectIcons: GetIconFunction = (size, spacing) => ({
	[CASCADING_SELECT_CF_TYPE]: (
		<EditorBulletListIcon
			label=""
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			LEGACY_fallbackIcon={EditorBulletListIconLegacy}
		/>
	),
});
