import { useCallback } from 'react';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { standardIssueTypes } from '@atlassian/jira-common-constants/src/issue-types.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ASSIGNEE_ID, ISSUE_TYPE_ID, NULL_GROUP_KEY, OTHER } from '../../../common/constants.tsx';
import type { BoardIssue, Group, Rank } from '../../../common/types.tsx';

type CardDropAnalyticsProps = {
	issue: BoardIssue;
	sourceGroup: Group;
	destinationGroup: Group;
	rank: Rank | undefined;
	transition: Transition | null | undefined;
	updateSessionId: string;
};

type AnalyticsAttributesProps = CardDropAnalyticsProps & {
	project: JiraProject;
	updateSessionId: string;
};

export const useCardDragDropAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const project = useProject();

	const fireStartCardDragAnalytics = useCallback(
		(sessionId: string) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'item',
			});
			const analyticsAttributes = {
				isDragEditing: true,
				updateSessionId: sessionId,
			};
			fireUIAnalytics(analyticsEvent, 'issue', analyticsAttributes);
		},
		[createAnalyticsEvent],
	);

	const fireCardDropAnalytics = useCallback(
		({
			issue,
			sourceGroup,
			destinationGroup,
			rank,
			transition,
			updateSessionId,
		}: CardDropAnalyticsProps) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'update',
				actionSubject: 'issue',
			});

			const analyticsAttributes = getAnalyticsAttributes({
				issue,
				sourceGroup,
				destinationGroup,
				rank,
				transition,
				project,
				updateSessionId,
			});

			fireTrackAnalytics(analyticsEvent, 'issue updated', 'ended', analyticsAttributes);
		},
		[createAnalyticsEvent, project],
	);

	return {
		fireStartCardDragAnalytics,
		fireCardDropAnalytics,
	};
};

const getAnalyticsAttributes = ({
	issue,
	sourceGroup,
	destinationGroup,
	rank,
	transition,
	project,
	updateSessionId,
}: AnalyticsAttributesProps) => {
	const sourceGroupId = sourceGroup.id;
	const destinationGroupId = destinationGroup.id;
	const sourceGroupType = sourceGroup.type;
	const destinationGroupType = destinationGroup.type;
	const issueTypeName = issue.fields[ISSUE_TYPE_ID].issueType.name;

	const attributes: Record<string, string | number | boolean> = {
		assigneeId: issue.fields[ASSIGNEE_ID]?.user?.accountId ?? '',
		fieldType: destinationGroupType,
		fieldKey: destinationGroupType,
		oldValId: sourceGroupId !== NULL_GROUP_KEY ? sourceGroupId : '',
		newValId: destinationGroupId !== NULL_GROUP_KEY ? destinationGroupId : '',
		isClassic: !project.isSimplified,
		isDragEditing: true,
		isInlineEditing: false,
		hasRankChanged: rank != null,
		isProjectAdmin: project.permissions.administerProject,
		issueId: issue.id,
		issueType: standardIssueTypes[issueTypeName] ? issueTypeName : OTHER,
		projectId: project.id,
		updateSessionId,
	};

	if (sourceGroupType === GROUP_BY_STATUS && destinationGroupType === GROUP_BY_STATUS) {
		attributes.oldStatusCategoryId = sourceGroup.statusCategoryId;
		attributes.newStatusCategoryId = destinationGroup.statusCategoryId;
		attributes.oldStatusCategoryName = statusCategoryForId(sourceGroup.statusCategoryId);
		attributes.newStatusCategoryName = statusCategoryForId(destinationGroup.statusCategoryId);
	}

	if (transition != null) {
		attributes.transitionId = transition.transitionId;
	}

	return attributes;
};
