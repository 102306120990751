import { memo } from 'react';
import { useSubscribe } from '@atlassian/jira-graphql-subscription/src/services/subscribe/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { ISSUE_DELETED_EVENT } from '../../constants.tsx';
import type { IssueSubscriptionProps } from '../../types.tsx';
import { getResponseError } from '../../utils.tsx';
import { JWM_DELETE_ISSUE_SUBSCRIPTION } from './gql.tsx';
import type { DeleteIssueResponse } from './types.tsx';

const operationName = 'JwmIssueDeletedSubscription';

export const DeleteIssueSubscription = memo<IssueSubscriptionProps>(
	({ cloudId, projectId, onReceive, onError }: IssueSubscriptionProps) => {
		useSubscribe<DeleteIssueResponse>(
			{
				query: JWM_DELETE_ISSUE_SUBSCRIPTION,
				variables: {
					cloudId,
					projectId,
				},
				operationName,
			},
			{
				next: (value) => {
					const error = getResponseError(value);
					if (error) {
						onError(error, operationName);
					} else {
						const payload = value.data?.jira?.onIssueDeletedByProject;
						if (payload) {
							onReceive({
								type: ISSUE_DELETED_EVENT,
								payload: {
									...payload,
									issueId: getAriConfig(payload.resource).resourceId,
								},
							});
						}
					}
				},
			},
		);

		return null;
	},
);
