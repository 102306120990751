/**
 * @generated SignedSource<<2b19216f16dabff76e054486032c0455>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type board_workManagementBoard_BoardContent_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"column_workManagementBoard_BoardColumn">;
  readonly " $fragmentType": "board_workManagementBoard_BoardContent_jiraQuery";
};
export type board_workManagementBoard_BoardContent_jiraQuery$key = {
  readonly " $data"?: board_workManagementBoard_BoardContent_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"board_workManagementBoard_BoardContent_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "board_workManagementBoard_BoardContent_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "column_workManagementBoard_BoardColumn"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "7421d02b70f92bece6543f99b158f548";

export default node;
