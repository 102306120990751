import React, { memo, useMemo, useEffect } from 'react';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import {
	usePrefetchIssueViewData,
	usePrefetchIssueViewDataActions,
} from '../controllers/use-prefetch-issue-view-data/index.tsx';
import { AsyncIssueViewCriticalDataLoader } from './issue-view-data-loader/async.tsx';

const IssueDataLoader = memo(({ issues }: { issues: string[] }) =>
	issues.map((issueKey) => <AsyncIssueViewCriticalDataLoader key={issueKey} issueKey={issueKey} />),
);

export const PrefetchIssueDataController = () => {
	const { prefetchedIssues } = usePrefetchIssueViewData();
	const { clearCache } = usePrefetchIssueViewDataActions();

	const [selectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);
	const prevSelectedIssueKey = usePreviousWithInitial(selectedIssueKey);

	useEffect(() => {
		const isIssueAppClosed = prevSelectedIssueKey && !selectedIssueKey;
		const isIssueKeyUpdated =
			selectedIssueKey && prevSelectedIssueKey && selectedIssueKey !== prevSelectedIssueKey;

		// clear cache when user closes Issue modal or opens another issue without closing it (ie via breadcrumbs)
		if (isIssueAppClosed || isIssueKeyUpdated) {
			clearCache();
		}
	}, [clearCache, prevSelectedIssueKey, selectedIssueKey]);

	useEffect(
		() => () => {
			// clear cache on unmount
			clearCache();
		},
		[clearCache],
	);

	const issues = useMemo(() => Object.keys(prefetchedIssues), [prefetchedIssues]);

	return <IssueDataLoader issues={issues} />;
};
