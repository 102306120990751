import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

const MenuFallback = () => (
	<SpinnerWrapper>
		<Spinner size="medium" />
	</SpinnerWrapper>
);

export default memo(MenuFallback);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SpinnerWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: token('space.200', '16px'),
});
