import type { FlagConfiguration, FlagMessage, FlagType } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type InviteSuccessDescriptor = [MessageDescriptorV2, {}] | [MessageDescriptorV2, InviteParameters];

type InviteConfig = {
	specificDescription: MessageDescriptorV2;
	agnosticDescription: MessageDescriptorV2;
	totalPeopleAdded: number;
	email: string;
};

type GetInviteSuccessDescription = (arg0: InviteConfig) => InviteSuccessDescriptor;

type InviteFlagConfig = {
	type: FlagType;
	title: FlagMessage;
	description: InviteSuccessDescriptor;
};

type CreateInviteSuccessFlagConfigParam = {
	totalPeopleAdded: number;
	email: string;
	hasSentInvitation: boolean;
};

type CreateInviteSuccessFlagConfig = (arg0: CreateInviteSuccessFlagConfigParam) => InviteFlagConfig;

type CreateAddSuccessFlagConfigParam = {
	totalPeopleAdded: number;
	email: string;
	projectKey: string;
};

type CreateAddSuccessFlagConfig = (arg0: CreateAddSuccessFlagConfigParam) => InviteFlagConfig;

type CreateInviteFailUserLimitFlagConfig = (
	isSiteAdmin: boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createAnalyticsEvent: (arg1: any) => AnalyticsEvent,
) => FlagConfiguration;

type InviteParameters = {
	totalPeopleAddedMinusOne: number;
	totalPeopleAdded: number;
	email: string;
};

export const getInviteSuccessDescription: GetInviteSuccessDescription = ({
	specificDescription,
	agnosticDescription,
	totalPeopleAdded,
	email,
}) => {
	if (totalPeopleAdded <= 0) {
		return [agnosticDescription, {}];
	}

	return [
		specificDescription,
		{
			totalPeopleAddedMinusOne: totalPeopleAdded - 1,
			totalPeopleAdded,
			email,
		},
	];
};

export const inviteFailFlagConfig: FlagConfiguration = {
	type: 'error',
	title: messages.inviteFailedMessageTitle,
	description: messages.inviteFailedMessageDescription,
	actions: [
		{
			content: messages.inviteFailedMessageCta,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			onClick: () => window.open('/secure/ContactAdministrators!default.jspa', '_blank'),
		},
	],
	messageType: 'transactional',
};

export const inviteFailGenericFlagConfig: FlagConfiguration = {
	type: 'error',
	title: messages.inviteFailedMessageTitle,
	description: messages.inviteFailedGenericMessageDescription,
	messageType: 'transactional',
};

export const inviteFailBadRequestFlagConfig: FlagConfiguration = {
	type: 'error',
	title: messages.inviteFailedMessageTitle,
	description: messages.inviteFailedBadRequestMessageDescription,
	messageType: 'transactional',
};

export const inviteFailNetworkErrorFlagConfig: FlagConfiguration = {
	type: 'error',
	title: messages.inviteFailedMessageTitle,
	description: messages.inviteFailedNetworkErrorMessageDescription,
	messageType: 'transactional',
};

export const createInviteSuccessFlagConfig: CreateInviteSuccessFlagConfig = ({
	totalPeopleAdded,
	email,
	hasSentInvitation,
}) => {
	if (!hasSentInvitation) {
		return {
			type: 'success',
			title: messages.requestInviteSuccessMessageTitle,
			description: getInviteSuccessDescription({
				agnosticDescription: messages.requestInviteSuccessMessageDescriptionAgnostic,
				specificDescription: messages.requestInviteSuccessMessageDescription,
				totalPeopleAdded,
				email,
			}),
			messageType: 'transactional',
		};
	}

	return {
		type: 'success',
		title: messages.inviteSuccessMessageTitle,
		description: getInviteSuccessDescription({
			agnosticDescription: messages.inviteSuccessMessageDescriptionAgnostic,
			specificDescription: messages.inviteSuccessMessageDescription,
			totalPeopleAdded,
			email,
		}),
		messageType: 'transactional',
	};
};

export const createAddSuccessFlagConfig: CreateAddSuccessFlagConfig = ({
	totalPeopleAdded,
	email,
	projectKey,
}) => ({
	type: 'success',
	title: messages.peopleAddedSuccessMessageTitle,
	description: getInviteSuccessDescription({
		agnosticDescription: messages.peopleAddedSuccessMessageDescriptionAgnostic,
		specificDescription: messages.peopleAddedSuccessMessageDescription,
		totalPeopleAdded,
		email,
	}),
	actions: [
		{
			content: messages.peopleAddedManageAccess,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			onClick: () => window.location.assign(`/jira/core/projects/${projectKey}/settings/access`),
		},
	],
	messageType: 'transactional',
});

export const createInviteFailedUserLimitFlagConfig: CreateInviteFailUserLimitFlagConfig = (
	isSiteAdmin,
	createAnalyticsEvent,
) => ({
	type: 'warning',
	title: messages.userLimitInviteErrorTitle,
	description: isSiteAdmin
		? messages.userLimitInviteErrorDescriptionAdmin
		: messages.userLimitInviteErrorDescription,
	actions: isSiteAdmin
		? [
				{
					content: messages.upgradePlan,
					onClick: () => {
						fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'upgradePlan');

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open('/admin/billing/applications', '_blank');
					},
				},
				{
					content: messages.viewUsers,
					onClick: () => {
						fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'viewUsers');

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open('/admin/users', '_blank');
					},
				},
			]
		: [
				{
					content: messages.inviteFailedMessageCta,
					onClick: () => {
						fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'contactAdmin');

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open('/secure/ContactAdministrators!default.jspa', '_blank');
					},
				},
			],
	messageType: 'transactional',
});
