import React from 'react';
import ResolutionsIcon from '@atlaskit/icon/core/check-mark';
import ResolutionsIconLegacy from '@atlaskit/icon/glyph/editor/done';
import { RESOLUTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getResolutionIcons: GetIconFunction = (size, spacing) => ({
	[RESOLUTION_TYPE]: (
		<ResolutionsIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			LEGACY_fallbackIcon={ResolutionsIconLegacy}
			label=""
		/>
	),
});
