import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { IdentityUserAri } from '@atlassian/ari/identity/user';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira/issuefieldvalue';
import {
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { UpdateFieldsValidationError, UPDATE_ISSUE_FIELDS_EXPERIENCE } from '../utils.tsx';
import type {
	BusinessBoardUpdateAssignee,
	BusinessBoardUpdateAssigneeVariables,
} from './__generated_apollo__/BusinessBoardUpdateAssignee';
import { UPDATE_ASSIGNEE_MUTATION } from './gql.tsx';

type Args = {
	issueId: number;
	assigneeId: string | null;
};

export const useUpdateAssignee = () => {
	const cloudId = useCloudId();
	const startExperience = useExperienceStart(UPDATE_ISSUE_FIELDS_EXPERIENCE);
	const markExperienceSuccessful = useExperienceSuccess(UPDATE_ISSUE_FIELDS_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(UPDATE_ISSUE_FIELDS_EXPERIENCE);
	const abortExperience = useExperienceAbort(UPDATE_ISSUE_FIELDS_EXPERIENCE);

	const [updateAssignee] = useMutation<
		BusinessBoardUpdateAssignee,
		BusinessBoardUpdateAssigneeVariables
	>(UPDATE_ASSIGNEE_MUTATION);

	return useCallback(
		async ({ issueId, assigneeId }: Args) => {
			startExperience();

			try {
				const input: BusinessBoardUpdateAssigneeVariables['input'] = {
					id: JiraIssuefieldvalueAri.create({
						issueId: String(issueId),
						fieldId: ASSIGNEE_TYPE,
						siteId: cloudId,
					}).toString(),
					operation: {
						id: assigneeId ? IdentityUserAri.create({ userId: assigneeId }).toString() : null,
						// @ts-expect-error - cannot import the enum from the generated types
						operation: 'SET',
					},
				};

				const { data, errors } = await updateAssignee({
					variables: {
						input,
					},
					// @ts-expect-error - errorPolicy is not in the types but does work
					errorPolicy: 'all',
				});

				const mutationResponse = data?.jira?.updateSingleSelectUserPickerField;

				if (mutationResponse?.success !== true) {
					const mutationError = mutationResponse?.errors?.[0];
					if (mutationError != null) {
						const statusCode = mutationError.extensions?.statusCode ?? 0;
						if (statusCode >= 400 && statusCode < 500) {
							throw new UpdateFieldsValidationError(mutationError.message ?? undefined);
						}

						throw new MutationError(mutationError);
					}

					if (errors) {
						const apolloError = new ApolloError({
							graphQLErrors: errors,
						});

						throw apolloError;
					}

					throw new Error('No data returned from update assignee mutation');
				}

				markExperienceSuccessful();

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error instanceof UpdateFieldsValidationError) {
					abortExperience('Updating assignee failed with validation error');
				} else {
					markExperienceFailed('Failed to update issue assignee', error);
				}

				throw error;
			}
		},
		[
			startExperience,
			cloudId,
			updateAssignee,
			markExperienceSuccessful,
			abortExperience,
			markExperienceFailed,
		],
	);
};
