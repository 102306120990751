import { useCallback } from 'react';
import type { BoardGroupByType } from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import {
	GROUP_BY_STATUS,
	GROUP_BY_CATEGORY,
	GROUP_BY_ASSIGNEE,
	GROUP_BY_PRIORITY,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { GROUPING_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';

const DEFAULT_GROUP_BY = GROUP_BY_STATUS;

const getGroupByValue = (groupByPreference: string | undefined): BoardGroupByType => {
	if (groupByPreference === GROUP_BY_STATUS) {
		return GROUP_BY_STATUS;
	}
	if (groupByPreference === GROUP_BY_ASSIGNEE) {
		return GROUP_BY_ASSIGNEE;
	}
	if (groupByPreference === GROUP_BY_PRIORITY) {
		return GROUP_BY_PRIORITY;
	}
	if (groupByPreference?.includes('customfield')) {
		return GROUP_BY_CATEGORY;
	}

	return DEFAULT_GROUP_BY;
};

export const useGroupBy = () => {
	const [groupByPreference, setGroupByPreference] = useViewPreference(GROUPING_PREFERENCE);

	const setGroupBy = useCallback(
		(value: string) => {
			// Set the default type value to undefined so we don't show query param for the default value
			if (value === GROUP_BY_STATUS) {
				setGroupByPreference(undefined);
			} else {
				setGroupByPreference(value);
			}
		},
		[setGroupByPreference],
	);

	return [getGroupByValue(groupByPreference), setGroupBy] as const;
};

export const useGroupByField = (): BoardGroupByType => {
	const [groupByPreference] = useViewPreference(GROUPING_PREFERENCE);
	return getGroupByValue(groupByPreference);
};
