import React from 'react';
import ReleaseIcon from '@atlaskit/icon/core/release';
import ShipIconLegacy from '@atlaskit/icon/glyph/ship';
import {
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	AFFECTS_VERSIONS_TYPE,
	FIX_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getVersionIcons: GetIconFunction = (size, spacing) => ({
	[VERSION_CF_TYPE]: (
		<ReleaseIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={ShipIconLegacy}
		/>
	),
	[MULTI_VERSION_CF_TYPE]: (
		<ReleaseIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={ShipIconLegacy}
		/>
	),
	[AFFECTS_VERSIONS_TYPE]: (
		<ReleaseIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={ShipIconLegacy}
		/>
	),
	[FIX_VERSIONS_TYPE]: (
		<ReleaseIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={ShipIconLegacy}
		/>
	),
});
