/**
 * @generated SignedSource<<859589da4b8dcaedf8f7e823f876ac51>>
 * @relayHash 7fee8b341915e756eb39281276465fe6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f0a7347331c5c53934d27a4679dad2ab94c9ddca76cfb2039e02f1e073652c68

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_jiraBusinessBoardQuery$variables = {
  cloudId: string;
  projectKey: string;
};
export type src_jiraBusinessBoardQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly " $fragmentSpreads": FragmentRefs<"projectContext_businessEntityProjectProvider_RelayProjectContextProvider">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_Board_jiraQuery">;
  } | null | undefined;
};
export type src_jiraBusinessBoardQuery = {
  response: src_jiraBusinessBoardQuery$data;
  variables: src_jiraBusinessBoardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectKey"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v3 = [
  (v1/*: any*/)
],
v4 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "src_jiraBusinessBoardQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "src_workManagementBoard_Board_jiraQuery"
          },
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "projectContext_businessEntityProjectProvider_RelayProjectContextProvider"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "src_jiraBusinessBoardQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "mediaClientId"
          },
          {
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "mediaExternalEndpointUrl"
          },
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "large"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "name"
              },
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              {
                "kind": "ScalarField",
                "name": "status"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              {
                "alias": "assignIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "ASSIGN_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"ASSIGN_ISSUES\")"
              },
              {
                "alias": "administerProject",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              {
                "alias": "createIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "CREATE_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"CREATE_ISSUES\")"
              },
              {
                "alias": "deleteIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "DELETE_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"DELETE_ISSUES\")"
              },
              {
                "alias": "editIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"EDIT_ISSUES\")"
              },
              {
                "alias": "linkIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "LINK_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"LINK_ISSUES\")"
              },
              {
                "alias": "scheduleIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "SCHEDULE_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"SCHEDULE_ISSUES\")"
              },
              {
                "alias": "transitionIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "TRANSITION_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"TRANSITION_ISSUES\")"
              },
              {
                "alias": "viewIssues",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "VIEW_ISSUES"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "action(type:\"VIEW_ISSUES\")"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f0a7347331c5c53934d27a4679dad2ab94c9ddca76cfb2039e02f1e073652c68",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7d8222c8a060b2bd75c5006926dfd7bc";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
