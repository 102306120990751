import { useMemo } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import type { Group } from '../../common/types.tsx';
import { useGroupByField } from '../group-by/index.tsx';
import { useGroups } from '../groups-context/index.tsx';
import { useSortGroups } from '../groups-order/index.tsx';
import { useIssuesByGroupOld } from '../issues-by-group/index.tsx';

// delete when cleaning up fun-1361_refactor_business_board_grouping_logic
/** @deprecated use useGroups instead pls */
export const useIssueGroups = (): Group[] => {
	// merge the groups from the group context with the groups from the issues by group
	// to make sure we do not hide any groups
	const groupBy = useGroupByField();
	const groups = useGroups();
	const issuesByGroup = useIssuesByGroupOld();
	const sortGroups = useSortGroups();

	return useMemo(() => {
		// if grouped by status or assignee, skip this
		if (groupBy === GROUP_BY_STATUS || groupBy === GROUP_BY_ASSIGNEE) {
			return groups;
		}

		// if all the groups found in issuesByGroup are already in the groups array
		// we can just return the groups array
		const missingGroups = [...issuesByGroup.keys()].filter(
			(groupId) => !groups.some((group) => group.id === groupId),
		);

		if (missingGroups.length === 0) {
			return groups;
		}

		return sortGroups([
			...missingGroups.map((groupId) => {
				const firstIssue = issuesByGroup.get(groupId)?.[0];

				if (!firstIssue) {
					throw new Error('No issues found for group');
				}

				switch (groupBy) {
					case GROUP_BY_PRIORITY:
						return {
							id: groupId,
							imageUrl: firstIssue.fields[groupBy]?.priority?.iconUrl ?? null,
							name: firstIssue.fields[groupBy]?.priority.name ?? '',
							type: groupBy,
						};
					case GROUP_BY_CATEGORY:
						return {
							id: groupId,
							color: firstIssue.fields[groupBy]?.category?.color ?? null,
							name: firstIssue.fields[groupBy]?.category.name ?? '',
							type: groupBy,
						};
					default: {
						const exhaustiveCheck: never = groupBy;
						throw new Error(`Unexpected groupBy value: ${exhaustiveCheck}`);
					}
				}
			}),
			...groups,
		]);
	}, [sortGroups, groupBy, groups, issuesByGroup]);
};
