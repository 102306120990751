/**
 * @generated SignedSource<<bad17c151eafa76325c1232e42a13b08>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type board_workManagementBoard_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"board_workManagementBoard_BoardContent_jiraQuery">;
  readonly " $fragmentType": "board_workManagementBoard_jiraQuery";
};
export type board_workManagementBoard_jiraQuery$key = {
  readonly " $data"?: board_workManagementBoard_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"board_workManagementBoard_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "board_workManagementBoard_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "board_workManagementBoard_BoardContent_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "b8b8341f6cf4ef9c218518b387b90bba";

export default node;
