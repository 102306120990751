/** @jsx jsx */
import React, { memo, useCallback } from 'react';
import { jsx, styled, css } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import Badge from '@atlaskit/badge';
import Lozenge from '@atlaskit/lozenge';
import SimpleTag from '@atlaskit/tag/simple-tag';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import CategoryTag from '@atlassian/jira-business-categories/src/ui/category-form/category-tag/index.tsx';
import { HierarchyIcon } from '@atlassian/jira-business-fields/src/common/field-types/parent/icon/hierarchy-icon/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Timestamp from '@atlassian/jira-issue-timestamp/src/main.tsx';
import { CardLabels } from '@atlassian/jira-platform-card/src/common/ui/labels/index.tsx';
import {
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	SUMMARY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DateOnly } from '@atlassian/jira-platform-utils-date-only/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_COMPONENTS,
	BOARD_FIELD_DATE,
	BOARD_FIELD_TEXT,
	BOARD_FIELD_FLOAT,
	BOARD_FIELD_DATE_TIME,
	BOARD_FIELD_USER,
	BOARD_FIELD_MULTI_USER,
	BOARD_FIELD_SELECT,
	BOARD_FIELD_TIME_ORIGINAL_ESTIMATE,
	BOARD_FIELD_STORY_POINTS,
	BOARD_FIELD_PARENT,
} from '../../../../../../common/constants.tsx';
import type { BoardIssue, BoardIssueField } from '../../../../../../common/types.tsx';
import { useSelectedFields } from '../../../../../../controllers/fields-preference/index.tsx';

type FieldRendererArgs = {
	field: BoardIssueField;
	formatDate: (date: DateOnly | null) => string;
	formatNumber: (number: number | bigint) => string;
	TimeStampComponent: typeof Timestamp;
};

const DATE_FORMAT_OPTIONS = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
} as const;

const TimeStampCss = {
	whenDisplaySettingSwitchable: css({
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text.subtle', colors.N500),
		},
	}),
};

const parentFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_PARENT &&
	(isVisualRefreshEnabled() ? (
		<DetailRow key={field.fieldId}>
			<Lozenge isBold>{`${field.parent?.key} ${field.parent?.summary}`}</Lozenge>
		</DetailRow>
	) : (
		<DetailRow key={field.fieldId} noIndent>
			<SimpleTag
				elemBefore={<HierarchyIcon size={20} />}
				text={`${field.parent?.key} ${field.parent?.summary}`}
			/>
		</DetailRow>
	));

const categoryFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_CATEGORY && (
		<DetailRow key={field.fieldId} noIndent>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
				indent
			>
				{field.name}
			</FieldLabel>
			<CategoryTagContainer>
				<CategoryTag categoryName={field.category.name} categoryColor={field.category.color} />
			</CategoryTagContainer>
		</DetailRow>
	);

const componentFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_COMPONENTS &&
	field.value.length > 0 && (
		<DetailRow key={field.fieldId} noIndent>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
				indent
			>
				{field.name}
			</FieldLabel>
			{field.value.map((component) => (
				<SimpleTag key={component} text={component} />
			))}
		</DetailRow>
	);

const dateRenderer = ({ field, formatDate }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_DATE &&
	field.value != null && (
		<DetailRow key={field.fieldId}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			<LozengeWrapper>
				<Lozenge>{formatDate(DateOnly.fromISODateString(field.value))}</Lozenge>
			</LozengeWrapper>
		</DetailRow>
	);

const textAndNumberRenderer = ({ field, formatNumber }: FieldRendererArgs) =>
	(field.type === BOARD_FIELD_TEXT || field.type === BOARD_FIELD_FLOAT) && (
		<DetailRow key={field.fieldId}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			<TextContainer>
				{field.type === BOARD_FIELD_FLOAT ? formatNumber(field.value) : field.value}
			</TextContainer>
		</DetailRow>
	);

const dateTimeRenderer = ({ field, TimeStampComponent }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_DATE_TIME && (
		<DetailRow key={field.fieldId} onClick={(e) => e.stopPropagation()}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			<TimeStampContainer
				css={[
					isVisualRefreshEnabled() &&
						fg('jira_nav4_beta_drop_1') &&
						TimestampContainerRefreshStyles,
				]}
			>
				<TimeStampComponent
					value={field.value}
					// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.
					extraStyles={TimeStampCss}
				/>
			</TimeStampContainer>
		</DetailRow>
	);

const userRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_USER &&
	field.user && (
		<DetailRow key={field.fieldId}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			<Avatar name={field.user.name} src={field.user.avatarURL ?? undefined} size="small" />
			<TextContainer>{field.user.name}</TextContainer>
		</DetailRow>
	);

const multiUserRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_MULTI_USER &&
	field.users.length > 0 && (
		<DetailRow key={field.fieldId}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			<AvatarGroup
				appearance="stack"
				data={field.users.map((user) => ({
					name: user.name,
					src: user.avatarURL ?? undefined,
					enableTooltip: true,
				}))}
				maxCount={10}
				size="small"
			/>
			{field.users.length === 1 && <TextContainer>{field.users[0].name}</TextContainer>}
		</DetailRow>
	);

const selectFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_SELECT &&
	field.value.length > 0 && (
		<DetailRow key={field.fieldId}>
			<FieldLabel
				css={[isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') && FieldLabelRefreshStyles]}
			>
				{field.name}
			</FieldLabel>
			{field.value.length > 1 ? (
				<CardLabels labels={field.value} />
			) : (
				<TextContainer>{field.value[0]}</TextContainer>
			)}
		</DetailRow>
	);

const timeOriginalEstimateRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_TIME_ORIGINAL_ESTIMATE && (
		<DetailRow key={field.fieldId}>
			<InlineLabel>{field.name}</InlineLabel>
			<InlineContent>
				<Badge>{field.value}</Badge>
			</InlineContent>
		</DetailRow>
	);

const FIELD_RENDERERS: {
	[key: string]: (arg0: FieldRendererArgs) => false | React.JSX.Element | undefined;
} = {
	[BOARD_FIELD_PARENT]: parentFieldRenderer,
	[BOARD_FIELD_CATEGORY]: categoryFieldRenderer,
	[BOARD_FIELD_COMPONENTS]: componentFieldRenderer,
	[BOARD_FIELD_DATE]: dateRenderer,
	[BOARD_FIELD_TEXT]: textAndNumberRenderer,
	[BOARD_FIELD_USER]: userRenderer,
	[BOARD_FIELD_MULTI_USER]: multiUserRenderer,
	[BOARD_FIELD_SELECT]: selectFieldRenderer,
	[BOARD_FIELD_FLOAT]: textAndNumberRenderer,
	[BOARD_FIELD_STORY_POINTS]: textAndNumberRenderer,
	[BOARD_FIELD_TIME_ORIGINAL_ESTIMATE]: timeOriginalEstimateRenderer,
	[BOARD_FIELD_DATE_TIME]: dateTimeRenderer,
};

const FIELD_TYPE_ORDER = Object.keys(FIELD_RENDERERS);

const EXCLUDED_SYSTEM_FIELDS = new Set<string>([ASSIGNEE_TYPE, DUE_DATE_TYPE, SUMMARY_TYPE]);

type Props = { issue: BoardIssue };

const CardDetailFields = ({ issue }: Props) => {
	const selectedFields = useSelectedFields();

	const { formatDate: formatDateIntl, formatNumber } = useIntl();
	const formatDate = useCallback(
		(date: DateOnly | null) =>
			date ? formatDateIntl(date.toLocalDate(), DATE_FORMAT_OPTIONS) : '',
		[formatDateIntl],
	);

	const fields = Object.values(issue.fields)
		?.filter((field) => {
			/**
			 * Filter out field based off the following criteria:
			 * * If the field is not selected via customized card configuration
			 * * If the field type is not in the FIELD_TYPE_ORDER (i.e. no renderer for field type available)
			 * * If the field is in the EXCLUDED_SYSTEM_FIELDS
			 */
			if (
				!selectedFields[field.fieldId] ||
				!FIELD_TYPE_ORDER.includes(field.type) ||
				EXCLUDED_SYSTEM_FIELDS.has(field.fieldId)
			) {
				return false;
			}

			return true;
		})
		.sort(
			(field1, field2) =>
				FIELD_TYPE_ORDER.indexOf(field1.type) - FIELD_TYPE_ORDER.indexOf(field2.type),
		)
		.map(
			(field) =>
				FIELD_RENDERERS[field.type]?.({
					field,
					formatDate,
					formatNumber,
					TimeStampComponent: Timestamp,
				}) ?? null,
		);

	return fields;
};

export default memo<Props>(CardDetailFields);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailRow = styled.div<{ noIndent?: boolean }>({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	columnGap: token('space.050', '4px'),
	with: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) =>
		props.noIndent ? token('space.negative.050', '-4px') : token('space.0', '0px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldLabel = styled.div<{ indent?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N800),
	width: '100%',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.medium', '500'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) => (props.indent ? token('space.050', '4px') : token('space.0', '0px')),
});

const FieldLabelRefreshStyles = css({
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineLabel = styled.div<{ indent?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N800),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.medium', '500'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) => (props.indent ? token('space.050', '4px') : token('space.0', '0px')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineContent = styled.div({
	marginLeft: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtleText = styled.span({
	color: token('color.text'),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtlestText = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N700),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
});

const TextContainer = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1'),
	SubtleText,
	SubtlestText,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	marginLeft: 0,
});

const TimestampContainerRefreshStyles = css({
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimeStampContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtle', colors.N500),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CategoryTagContainer = styled.div({
	paddingTop: token('space.050', '4px'),
	paddingLeft: token('space.050', '4px'),
	minWidth: 0,
});
