import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Group } from '../../common/types.tsx';
import { useGroupByField } from '../group-by/index.tsx';
import {
	useAssigneeGroups,
	useCategoryGroupsOld,
	useCategoryGroups,
	usePriorityGroupsOld,
	usePriorityGroups,
	useStatusGroups,
} from './utils.tsx';

type ContextType = Group[] | null;

const GroupsContext = createContext<ContextType>(null);

type GroupSetterProps = {
	onGroupsChange: (groups: Group[]) => void;
};

const AssigneeGroupsSetter = ({ onGroupsChange }: GroupSetterProps) => {
	const groups = useAssigneeGroups();

	useEffect(() => {
		onGroupsChange(groups);
	}, [groups, onGroupsChange]);

	return null;
};

const CategoryGroupsSetter = ({ onGroupsChange }: GroupSetterProps) => {
	const groups = fg('fun-1361_refactor_business_board_grouping_logic')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCategoryGroups()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useCategoryGroupsOld();

	useEffect(() => {
		onGroupsChange(groups);
	}, [groups, onGroupsChange]);

	return null;
};

const PriorityGroupsSetter = ({ onGroupsChange }: GroupSetterProps) => {
	const groups = fg('fun-1361_refactor_business_board_grouping_logic')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			usePriorityGroups()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			usePriorityGroupsOld();

	useEffect(() => {
		onGroupsChange(groups);
	}, [groups, onGroupsChange]);

	return null;
};

const StatusGroupsSetter = ({ onGroupsChange }: GroupSetterProps) => {
	const groups = useStatusGroups();

	useEffect(() => {
		onGroupsChange(groups);
	}, [groups, onGroupsChange]);

	return null;
};

export const GroupsProvider = ({ children }: { children: ReactNode }) => {
	const groupBy = useGroupByField();
	const [groups, setGroups] = useState<Group[]>([]);

	let GroupSetter;
	switch (groupBy) {
		case GROUP_BY_ASSIGNEE:
			GroupSetter = AssigneeGroupsSetter;
			break;
		case GROUP_BY_CATEGORY:
			GroupSetter = CategoryGroupsSetter;
			break;
		case GROUP_BY_PRIORITY:
			GroupSetter = PriorityGroupsSetter;
			break;
		case GROUP_BY_STATUS:
			GroupSetter = StatusGroupsSetter;
			break;
		default: {
			const exhaustiveCheck: never = groupBy;
			throw new Error(`Unexpected groupBy value: ${exhaustiveCheck}`);
		}
	}

	return (
		<GroupsContext.Provider value={groups}>
			{children}
			<GroupSetter onGroupsChange={setGroups} />
		</GroupsContext.Provider>
	);
};

export const useGroups = () => {
	const context = useContext(GroupsContext);

	if (!context) {
		throw new Error('useGroups must be used within a GroupsProvider');
	}

	return context;
};
