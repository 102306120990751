import { setBoardColumnOrder } from './services/index.tsx';

export const persistStatusGroups = async (
	projectId: string,
	selectedWorkflow: string,
	updatedGroupIds: string[],
) => {
	const request = {
		columnRequest: {
			workflowName: selectedWorkflow,
			columns: updatedGroupIds.map((statusId) => ({ statusId })),
		},
		projectId,
	};
	await setBoardColumnOrder(request);
};
