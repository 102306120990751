import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitle: {
		id: 'business-issue-create.controllers.create-issue.error-flag-title',
		defaultMessage: "We weren't able to create an issue",
		description: 'Title for the flag displayed when creating an inline issue fails',
	},
	errorFlagDescription: {
		id: 'business-issue-create.controllers.create-issue.error-flag-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description for the flag displayed when creating an inline issue fails',
	},
	errorFlagTitleIssueTermRefresh: {
		id: 'business-issue-create.controllers.create-issue.error-flag-title-issue-term-refresh',
		defaultMessage: "We weren't able to create a work item",
		description: 'Title for the flag displayed when creating an inline issue fails',
	},
});
