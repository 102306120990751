import { useCallback } from 'react';
import chunk from 'lodash/chunk';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import {
	useExperienceFail,
	useExperienceStart,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { LOAD_EXTENDED_BOARD_DATA_EXPERIENCE } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';
import { useFetchBoardIssues } from '../../services/board-issues/index.tsx';
import { useTransformIssues } from '../../utils/issue-transformer/index.tsx';
import { useFieldIds } from '../field-ids/index.tsx';

type FetchIssueDetails = (issueIds: number[]) => Promise<BoardIssue[]>;

const CHUNK_SIZE = 100;

export const useFetchIssueDetails = (): FetchIssueDetails => {
	const project = useProject();
	const fieldIds = useFieldIds();
	const fetchIssues = useFetchBoardIssues();
	const transformToIssues = useTransformIssues();

	const startExperience = useExperienceStart(LOAD_EXTENDED_BOARD_DATA_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(LOAD_EXTENDED_BOARD_DATA_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(LOAD_EXTENDED_BOARD_DATA_EXPERIENCE);

	return useCallback(
		async (issueIds: number[]) => {
			if (!fieldIds.length) {
				return [];
			}

			startExperience();

			try {
				const chunks = chunk(issueIds, CHUNK_SIZE);

				const results = await Promise.all(
					chunks.map((chunkIssueIds) =>
						fetchIssues({
							jql: `project = '${project.id}' AND id in (${chunkIssueIds.join(',')})`,
							fieldIds,
						}),
					),
				);

				markExperienceSuccess();
				return results.reduce<BoardIssue[]>(
					(acc, result) => acc.concat(transformToIssues(result)),
					[],
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				markExperienceFailed('Failed to load issue details', error);
				throw error;
			}
		},
		[
			fetchIssues,
			fieldIds,
			markExperienceFailed,
			markExperienceSuccess,
			project.id,
			startExperience,
			transformToIssues,
		],
	);
};
