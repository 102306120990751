/**
 * @generated SignedSource<<bffe431b7f62590bc4b5c91fa43d6bdd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type card_workManagementBoard_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"card_workManagementBoard_CardCoverWrapper_jiraQuery">;
  readonly " $fragmentType": "card_workManagementBoard_jiraQuery";
};
export type card_workManagementBoard_jiraQuery$key = {
  readonly " $data"?: card_workManagementBoard_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"card_workManagementBoard_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "card_workManagementBoard_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "card_workManagementBoard_CardCoverWrapper_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "a9aa0b5095a12bb5fbfd831034fdd864";

export default node;
