import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { BoardIssueIssueTypeField } from '../../../../../../common/types.tsx';

type Props = {
	issueType?: BoardIssueIssueTypeField;
};

export const CardIssueType = ({ issueType }: Props) => (
	<Box xcss={iconStyles}>
		{issueType?.issueType.iconUrl ? (
			<Tooltip content={issueType.issueType.name}>
				<Image alt={issueType.issueType.name} src={issueType.issueType.iconUrl} />
			</Tooltip>
		) : (
			<ImagePlaceholder />
		)}
	</Box>
);

export default CardIssueType;

const iconStyles = xcss({
	marginRight: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImagePlaceholder = styled.div({
	width: '16px',
	height: '16px',
	backgroundColor: token('color.skeleton', '#091E420F'),
	borderRadius: token('border.radius', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	display: 'flex',
	width: '16px',
	height: '16px',
});
