import { RESOLUTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Sorting } from '../../../sorting/types.tsx';
import type { TransformedResolutionField } from '../types.tsx';

const getResolutionSorting: Sorting<TransformedResolutionField> = (field) =>
	field.value?.name ?? '';

export const resolutionSortingHandler = {
	[RESOLUTION_TYPE]: getResolutionSorting,
};
