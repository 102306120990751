import { useCallback, useMemo } from 'react';
import { FILTER_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { SPRINT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useFieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import type { QuickFilters, QuickFiltersJQL } from '../../common/types.tsx';

const quickFilterToJQLMapOld: QuickFiltersJQL = {
	ASSIGNED_TO_ME: 'assignee = currentUser()',
	DONE: "StatusCategory = 'Complete'",
	DUE_THIS_WEEK: 'duedate <= endOfWeek() and duedate > startOfWeek()',
};

export const createQuickFilterToJQLMap = (sprintJQLTerm?: string): QuickFiltersJQL => {
	if (sprintJQLTerm == null) {
		return quickFilterToJQLMapOld;
	}
	return {
		...quickFilterToJQLMapOld,
		ACTIVE_SPRINTS: `${sprintJQLTerm} in openSprints()`,
	};
};

type Options = { quickFilterToJQLMap?: QuickFiltersJQL };
const buildJQLFromQuickFilters = (
	quickFilters: QuickFilters,
	{ quickFilterToJQLMap = quickFilterToJQLMapOld }: Options = {},
) => {
	const jqlClauses = Object.entries(quickFilters).map(
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly ASSIGNED_TO_ME: "assignee = currentUser()"; readonly DONE: "StatusCategory = 'Complete'"; readonly DUE_THIS_WEEK: "duedate <= endOfWeek() and duedate > startOfWeek()"; }'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		([key, isEnabled]: [any, any]) => isEnabled === true && quickFilterToJQLMap[key],
	);
	const enabledFilters = jqlClauses.filter((jql) => jql);

	return enabledFilters.join(' AND ') || null;
};

export const defaultFilters: QuickFilters = {
	ASSIGNED_TO_ME: false,
	DONE: false,
	DUE_THIS_WEEK: false,
	ACTIVE_SPRINTS: false,
};

export const getQuickFiltersApplied = (
	jql: string | undefined,
	{ quickFilterToJQLMap = quickFilterToJQLMapOld }: Options = {},
): QuickFilters => {
	const quickFilters = { ...defaultFilters };

	if (!jql) {
		return quickFilters;
	}

	if (jql.includes(quickFilterToJQLMap.ASSIGNED_TO_ME)) {
		quickFilters.ASSIGNED_TO_ME = true;
	}
	if (jql.includes(quickFilterToJQLMap.DONE)) {
		quickFilters.DONE = true;
	}
	if (jql.includes(quickFilterToJQLMap.DUE_THIS_WEEK)) {
		quickFilters.DUE_THIS_WEEK = true;
	}
	if (quickFilterToJQLMap.ACTIVE_SPRINTS && jql.includes(quickFilterToJQLMap.ACTIVE_SPRINTS)) {
		quickFilters.ACTIVE_SPRINTS = true;
	}
	return quickFilters;
};

const useSprintFieldJQLTerm = () => {
	const { data: fields } = useFieldsResource();
	return useMemo(() => {
		if (fields == null) {
			return undefined;
		}
		const sprintField = fields.find((field) => field.type === SPRINT_TYPE);
		return sprintField != null ? sprintField.jqlTerm : undefined;
	}, [fields]);
};

export const useQuickFilters = () => {
	const sprintFieldJQLTerm = useSprintFieldJQLTerm();
	const quickFilterToJQLMap = useMemo(
		() => createQuickFilterToJQLMap(sprintFieldJQLTerm),
		[sprintFieldJQLTerm],
	);
	const [filters, setFilters] = useViewPreference(FILTER_PREFERENCE);

	const setQuickFilters = useCallback(
		(newQuickFilters?: QuickFilters): void => {
			if (newQuickFilters) {
				const jql = buildJQLFromQuickFilters(newQuickFilters, {
					quickFilterToJQLMap,
				});
				if (jql == null) {
					setFilters(undefined);
				} else {
					setFilters(jql);
				}
			} else {
				setFilters(undefined);
			}
		},
		[quickFilterToJQLMap, setFilters],
	);

	const quickFilters = useMemo(
		() =>
			getQuickFiltersApplied(filters, {
				quickFilterToJQLMap,
			}),
		[filters, quickFilterToJQLMap],
	);

	return {
		quickFilters,
		persistQuickFilters: setQuickFilters,
		quickFiltersJql: buildJQLFromQuickFilters(quickFilters, {
			quickFilterToJQLMap,
		}),
	};
};
