import { useCallback } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { useOpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ISSUE_KEY_ID } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';

const TRIGGER_POINT_KEY = 'issue-transition-board-card-transition';

type ShowTransitionScreenModal = (issue: BoardIssue, transitionId: number) => Promise<void>;

export class TransitionModalCanceled extends Error {}

export const useTransitionScreenModal = (): ShowTransitionScreenModal => {
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const openIssueTransitionModal = useOpenIssueTransitionModal();

	return useCallback(
		(issue: BoardIssue, transitionId: number) =>
			new Promise((resolve, reject) => {
				const issueKey = issue.fields[ISSUE_KEY_ID].value;
				const ariIssueId = JiraIssueAri.create({
					issueId: String(issue.id),
					siteId: cloudId,
				}).toString();

				const onDialogSuccess = () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'issueTransition success', {
						triggerPointKey: TRIGGER_POINT_KEY,
						isModalOpen: true,
					});

					resolve(undefined);
				};

				const onDialogError = () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'issueTransition failed', {
						triggerPointKey: TRIGGER_POINT_KEY,
						isModalOpen: true,
					});

					reject(new Error('Issue transition failed'));
				};

				const onDialogCancel = () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'issueTransition cancel', {
						triggerPointKey: TRIGGER_POINT_KEY,
						isModalOpen: true,
					});

					reject(new TransitionModalCanceled('Issue transition canceled'));
				};

				openIssueTransitionModal({
					payload: {
						issueId: ariIssueId,
						issueKey,
						transitionId: String(transitionId),
					},
					triggerPointKey: TRIGGER_POINT_KEY,
					onDialogSuccess,
					onDialogCancel,
					onDialogError,
				});
			}),
		[cloudId, createAnalyticsEvent, openIssueTransitionModal],
	);
};
