import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	createStore,
	createHook,
	type StoreActionApi,
	createSelector,
} from '@atlassian/react-sweet-state';
import type { Status, State, Props } from './types.tsx';

const PACKAGE_LOCATION = 'jira-business.common.services.project-statuses';
const MAX_RETRIES = 3;

const set =
	(projectKey: string, statuses: Status[]) =>
	({ setState }: StoreActionApi<State>) =>
		setState({
			[projectKey]: statuses,
		});

const actions = {
	set,
} as const;

type Actions = typeof actions;

const ProjectStatusesStore = createStore<State, Actions>({
	initialState: {},
	actions,
	name: 'projectStatuses',
});

const getProjectStatusesSelector = createSelector(
	(state: State) => state,
	(_, props: Props) => props?.projectKey ?? undefined,
	(state, projectKey) => {
		if (projectKey === undefined || state[projectKey] == null) {
			return [];
		}

		return state[projectKey];
	},
);

export const useProjectStatusesStore = createHook(ProjectStatusesStore, {
	selector: getProjectStatusesSelector,
});

const emptyArray: Array<Status> = [];
export const useProjectStatuses = (): {
	statuses: Status[];
	loading: boolean;
} => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [retriesCount, setRetriesCount] = useState(0);
	const projectData = useProject();
	const projectKey = projectData.key;
	const [statuses, statusActions] = useProjectStatusesStore({ projectKey });

	const retry = () => {
		setRetriesCount(retriesCount + 1);
		setIsLoading(false);
		setHasLoaded(false);
	};

	if (projectKey != null && Array.isArray(statuses) && statuses.length > 0) {
		return { statuses, loading: isLoading };
	}

	if (!isLoading && !hasLoaded) {
		fetch(`/rest/api/3/project/${projectKey}/statuses`)
			.then((response) => response.json())
			.then((issueTypes) => {
				const allStatuses: Status[] = issueTypes.reduce(
					// @ts-expect-error - TS7006 - Parameter 'acc' implicitly has an 'any' type. | TS7006 - Parameter 'next' implicitly has an 'any' type.
					(acc, next) => acc.concat(next.statuses),
					[],
				);
				const dedupedResults = uniqBy(allStatuses, 'id');

				return sortBy(dedupedResults, ['name']);
			})
			.then((uniqueStatuses: Status[]) => {
				statusActions.set(projectKey, uniqueStatuses);
				setIsLoading(false);
				setHasLoaded(true);
			})
			.catch(() => {
				log.safeErrorWithoutCustomerData(
					PACKAGE_LOCATION,
					`Failed to retrieve project statuses - Retry #${retriesCount}`,
				);

				if (retriesCount < MAX_RETRIES) {
					retry();
				}
			});
		setIsLoading(true);
	}

	return { statuses: emptyArray, loading: isLoading };
};
