import React, { useMemo, useCallback } from 'react';
import { useSidebar } from '@atlassian/jira-business-app-wrapper/src/controllers/sidebar/index.tsx';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import {
	BUSINESS_CALENDAR,
	type AnalyticsSource,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type ModalIssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app.tsx';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useQueryParam } from '@atlassian/react-resource-router';

type Props = {
	issueKey: IssueKey;
	analyticsSource?: AnalyticsSource;
	onChange?: OnChangeCallback;
	onClose: () => void;
	onDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
	viewModeSwitchDisabled?: boolean;
	isSoftwareProject?: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const ModalIssueApp = lazyForPaint<typeof ModalIssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-modal-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app'
		),
	{ ssr: false },
);

const ModalIssueAppWrapper = ({
	issueKey,
	onClose,
	onChange,
	onDeleteSuccess,
	analyticsSource = BUSINESS_CALENDAR,
	viewModeSwitchDisabled,
	isSoftwareProject,
}: Props) => {
	const [, setSelectedIssue] = useQueryParam(SELECTED_ISSUE_PARAM);
	const onIssueKeyChange = useCallback(
		({ toIssueKey }: { toIssueKey: string }) => setSelectedIssue(toIssueKey),
		[setSelectedIssue],
	);
	const [, { setSidebarContent }] = useSidebar();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { setDetailViewAsSidebar, setDetailViewAsModal }] = useIssueContextActions();

	const onSwitchToModal = useCallback(() => {
		setDetailViewAsModal();
		setSidebarContent(null);

		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToModal');
	}, [createAnalyticsEvent, setDetailViewAsModal, setSidebarContent]);

	const onSwitchToSidebar = useCallback(() => {
		setSidebarContent(null);
		setDetailViewAsSidebar();

		fireUIAnalytics(createAnalyticsEvent({}), 'issueAppMenuItem clicked', 'switchToSidebar');
	}, [createAnalyticsEvent, setDetailViewAsSidebar, setSidebarContent]);

	const viewModeOptions = useMemo(
		() =>
			!viewModeSwitchDisabled
				? {
						viewModeSwitchEnabled: true,
						onSwitchToModal,
						onSwitchToSidebar,
					}
				: undefined,
		[onSwitchToModal, onSwitchToSidebar, viewModeSwitchDisabled],
	);

	return (
		<UFOSegment name="business-modal-issue-app">
			<ContextualAnalyticsData
				sourceType={SCREEN}
				sourceName={isSoftwareProject ? 'jswModalIssueApp' : 'jwmModalIssueApp'}
			>
				<ModalBoundary packageName={isSoftwareProject ? 'software' : 'business'} id="issue-modal">
					<Placeholder name="modal-issue-app" fallback={null}>
						<ModalIssueApp
							analyticsSource={analyticsSource}
							issueKey={issueKey}
							onClose={onClose}
							onChange={onChange}
							onIssueDeleteSuccess={onDeleteSuccess}
							onIssueKeyChange={onIssueKeyChange}
							viewModeOptions={viewModeOptions}
						/>
					</Placeholder>
				</ModalBoundary>
			</ContextualAnalyticsData>
		</UFOSegment>
	);
};

export default ModalIssueAppWrapper;
