import React, { memo, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ColumnTransitionZonesContainer } from '@atlassian/jira-platform-board-kit/src/ui/column/column-transition-zones-container/main.tsx';
import { COLUMN_HEADER_HEIGHT, TRANSITION_ZONE_MIN_HEIGHT } from '../../../../common/constants.tsx';
import type { Group } from '../../../../common/types.tsx';
import {
	useCardDragDrop,
	useUpdateDropTarget,
} from '../../../../controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { useIssueTransitions } from '../../../../controllers/issue-transitions/index.tsx';
import messages from './messages.tsx';

const getTransitionById = (transitions: Transition[], transitionId: number | null) =>
	transitions.find((transition) => transition.transitionId === transitionId);

type Props = {
	group: Group;
};

const TransitionZones = ({ group }: Props) => {
	const { transitions } = useIssueTransitions();
	const updateDropTarget = useUpdateDropTarget();
	const { sourceGroup, dropTarget } = useCardDragDrop();

	const handleCardDragOver = useCallback(
		(transitionId: number | null) => {
			updateDropTarget({
				groupId: group.id,
				transition: getTransitionById(transitions, transitionId),
			});
		},
		[group, transitions, updateDropTarget],
	);

	const handleTransitionSelected = useCallback(
		(transitionId: number | null) => {
			updateDropTarget({
				transition: getTransitionById(transitions, transitionId),
			});
		},
		[transitions, updateDropTarget],
	);

	const sourceStatus = useMemo(
		() =>
			sourceGroup
				? {
						id: Number(sourceGroup.id),
						name: sourceGroup.name,
					}
				: undefined,
		[sourceGroup],
	);

	const statusCategoryId = group.type === GROUP_BY_STATUS ? group.statusCategoryId : 0;

	const transitionZonesData = useMemo(
		() =>
			transitions
				.filter((transition) => String(transition.toStatusId) === group.id)
				.map((transition) => ({
					transitionId: transition.transitionId,
					transitionName: transition.name,
					targetStatusId: transition.toStatusId,
					targetStatusName: group.name,
					targetStatusCategory: statusCategoryForId(statusCategoryId),
					isAvailable: true,
					isGlobal: transition.isGlobal,
				})),
		[transitions, group.id, group.name, statusCategoryId],
	);

	if (!sourceGroup) {
		return null;
	}

	return (
		<TransitionZoneWrapper>
			<ColumnTransitionZonesContainer
				hasSwimlanes={false}
				columnId={group.id}
				sourceColumnId={sourceGroup.id}
				sourceStatus={sourceStatus}
				transitions={transitionZonesData}
				hoverIntent={false}
				columnDraggingOver={dropTarget.groupId}
				notAvailableMessage={
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.notAvailableMessageIssueTermRefresh
						: messages.notAvailableMessage
				}
				onCardDragOver={handleCardDragOver}
				onTransitionSelected={handleTransitionSelected}
			/>
		</TransitionZoneWrapper>
	);
};

export default memo(TransitionZones);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionZoneWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.outer-transition-container"]':
		{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			height: `calc(100% - ${COLUMN_HEADER_HEIGHT}px)`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.inner-container"]':
		{
			maxHeight: 'none',
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-zone.compiled-transition-zones-container"]':
		{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			minHeight: `${TRANSITION_ZONE_MIN_HEIGHT}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-to-zone.transition-to-zone"]':
		{
			marginTop: 0,
			position: 'absolute',
			top: '5px',
		},
});
