import React from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export type Props = {
	isCollapsed: boolean;
	issueCount: number;
};

const IssueCount = ({ isCollapsed, issueCount }: Props) =>
	isVisualRefreshEnabled() ? (
		<BadgeContainer isVerticalWritingMode={isCollapsed}>
			<Badge appearance="default">{issueCount}</Badge>
		</BadgeContainer>
	) : (
		<Counter isVerticalWritingMode={isCollapsed}>{issueCount}</Counter>
	);

export default IssueCount;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Counter = styled.div<{ isVerticalWritingMode: boolean }>({
	flex: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	writingMode: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? 'vertical-lr' : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeContainer = styled.div<{ isVerticalWritingMode: boolean }>({
	flex: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	writingMode: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? 'vertical-lr' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		minWidth: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? '12px' : undefined),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		minHeight: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? '24px' : undefined),
	},
});
