import gqlTagGira from 'graphql-tag';

export const UPDATE_CATEGORY_MUTATION = gqlTagGira`
mutation BusinessBoardUpdateCategory($input: JiraUpdateSingleSelectFieldInput!) {
  jira {
    updateSingleSelectField(input: $input) {
      success
      errors {
        message
        extensions {
          statusCode
          errorType
          __typename
        }
        __typename
      }
      __typename
    }
  }
}`;
