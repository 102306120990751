import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CheckIcon from '@atlaskit/icon/glyph/check';
import MenuExpandIcon from '@atlaskit/icon/glyph/menu-expand';
import { ButtonItem, HeadingItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Flex, xcss } from '@atlaskit/primitives';
import { B400 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import type { BoardGroupByType } from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import {
	PriorityIcon,
	StatusIcon,
} from '@atlassian/jira-business-common/src/common/utils/field-icons/index.tsx';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { getFieldIcons } from '@atlassian/jira-business-fields/src/common/icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { CATEGORY_TYPE, ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useGroupBy } from '../../../controllers/group-by/index.tsx';
import messages from './messages.tsx';

const BoardGroupSelector = () => {
	const { formatMessage } = useIntl();
	const [groupBy, setGroupBy] = useGroupBy();

	const [isOpen, setIsOpen] = useState(false);
	const { data: categoryField } = useCategoryField();
	const categoryFieldId = categoryField?.id;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireGroupChangedTracking = useCallback(
		(newGroupBy: BoardGroupByType) =>
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'groupBy', {
				value: newGroupBy,
			}),
		[createAnalyticsEvent],
	);

	const fireGroupMenuOpenedTracking = useCallback(() => {
		if (isOpen) {
			return;
		}
		fireTrackAnalytics(createAnalyticsEvent({}), 'jwmBoardGrouping menuOpened');
	}, [createAnalyticsEvent, isOpen]);

	let groupByLabel: string;
	let hasDefaultGroupApplied = false;
	if (groupBy === GROUP_BY_PRIORITY) {
		groupByLabel = formatMessage(messages.groupByPriorityLabel);
	} else if (groupBy === GROUP_BY_ASSIGNEE) {
		groupByLabel = formatMessage(messages.groupByAssigneeLabel);
	} else if (groupBy === GROUP_BY_CATEGORY) {
		groupByLabel = formatMessage(messages.groupByCategoryLabel);
	} else {
		hasDefaultGroupApplied = true;
		groupByLabel = formatMessage(messages.groupByStatusLabel);
	}

	const groupByStatus = useCallback(() => {
		fireGroupChangedTracking(GROUP_BY_STATUS);
		setGroupBy(GROUP_BY_STATUS);
		setIsOpen(false);
	}, [fireGroupChangedTracking, setGroupBy]);

	const groupByPriority = useCallback(() => {
		fireGroupChangedTracking(GROUP_BY_PRIORITY);
		setGroupBy(GROUP_BY_PRIORITY);
		setIsOpen(false);
	}, [fireGroupChangedTracking, setGroupBy]);

	const groupByAssignee = useCallback(() => {
		fireGroupChangedTracking(GROUP_BY_ASSIGNEE);
		setGroupBy(GROUP_BY_ASSIGNEE);
		setIsOpen(false);
	}, [fireGroupChangedTracking, setGroupBy]);

	const groupByCategory = useCallback(() => {
		if (!categoryFieldId) {
			return;
		}

		fireGroupChangedTracking(GROUP_BY_CATEGORY);
		setGroupBy(categoryFieldId);
		setIsOpen(false);
	}, [categoryFieldId, fireGroupChangedTracking, setGroupBy]);

	return useMemo(() => {
		const checkIcon = (
			<CheckIcon
				size="medium"
				label={formatMessage(messages.groupBySelected)}
				primaryColor={token('color.icon.selected', B400)}
			/>
		);

		const HeadingAndClearButtonInContainer = (
			<Flex justifyContent="space-between" alignItems="baseline" xcss={headingContainerStyles}>
				<Heading>{formatMessage(messages.groupBySectionTitle)}</Heading>
				{!hasDefaultGroupApplied && (
					<Button
						testId="work-management-board.ui.header.group-selector.clear-button"
						appearance="subtle-link"
						spacing="none"
						onClick={groupByStatus}
						aria-label={formatMessage(messages.clearGroupLabel)}
					>
						{formatMessage(messages.clearGroup)}
					</Button>
				)}
			</Flex>
		);

		return (
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-end"
				content={() => (
					<MenuGroup>
						<Section>
							{HeadingAndClearButtonInContainer}
							<ButtonItem
								onClick={groupByStatus}
								iconBefore={<StatusIcon />}
								iconAfter={hasDefaultGroupApplied && checkIcon}
								testId="work-management-board.ui.header.group-selector.status-button"
							>
								{formatMessage(messages.groupByStatusDefaultLabel)}
							</ButtonItem>
							<ButtonItem
								onClick={groupByPriority}
								iconBefore={<PriorityIcon />}
								iconAfter={groupBy === GROUP_BY_PRIORITY && checkIcon}
								testId="work-management-board.ui.header.group-selector.priority-button"
							>
								{formatMessage(messages.groupByPriorityLabel)}
							</ButtonItem>
							<ButtonItem
								onClick={groupByAssignee}
								iconBefore={getFieldIcons('medium')[ASSIGNEE_TYPE]}
								iconAfter={groupBy === GROUP_BY_ASSIGNEE && checkIcon}
								testId="work-management-board.ui.header.group-selector.assignee-button"
							>
								{formatMessage(messages.groupByAssigneeLabel)}
							</ButtonItem>
							<ButtonItem
								onClick={groupByCategory}
								iconBefore={getFieldIcons('medium')[CATEGORY_TYPE]}
								iconAfter={groupBy === GROUP_BY_CATEGORY && checkIcon}
								testId="work-management-board.ui.header.group-selector.category-button"
							>
								{formatMessage(messages.groupByCategoryLabel)}
							</ButtonItem>
						</Section>
					</MenuGroup>
				)}
				trigger={(triggerProps) =>
					isVisualRefreshEnabled() ? (
						<ThemedButton
							{...triggerProps}
							aria-label={formatMessage(messages.groupByButtonAriaLabel, { label: groupByLabel })}
							// Should be selected if the menu is open or a group is applied (which for boards is always the case)
							isSelected={isOpen || Boolean(groupBy)}
							onClick={() => {
								setIsOpen((wasOpen) => !wasOpen);
								fireGroupMenuOpenedTracking();
							}}
							testId="work-management-board.ui.header.group-selector"
						>
							{formatMessage(messages.groupByButtonLabel)}
							{groupByLabel}
						</ThemedButton>
					) : (
						<ThemedButton
							{...triggerProps}
							iconBefore={<MenuExpandIcon label="" size="small" />}
							isSelected={isOpen}
							onClick={() => {
								setIsOpen((wasOpen) => !wasOpen);
								fireGroupMenuOpenedTracking();
							}}
							appearance="subtle"
							testId="work-management-board.ui.header.group-selector"
						>
							{formatMessage(messages.groupByButtonLabelOld)}
							{groupByLabel}
						</ThemedButton>
					)
				}
			/>
		);
	}, [
		fireGroupMenuOpenedTracking,
		formatMessage,
		groupBy,
		groupByAssignee,
		groupByCategory,
		groupByLabel,
		groupByPriority,
		groupByStatus,
		hasDefaultGroupApplied,
		isOpen,
	]);
};

export default BoardGroupSelector;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled(HeadingItem)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const headingContainerStyles = xcss({
	paddingRight: 'space.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
});
