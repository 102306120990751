/**
 * @generated SignedSource<<ced2064701445f912dcdc14cf6e859e8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_workManagementBoard_BoardWithWorkflowsData_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"board_workManagementBoard_jiraQuery">;
  readonly " $fragmentType": "src_workManagementBoard_BoardWithWorkflowsData_jiraQuery";
};
export type src_workManagementBoard_BoardWithWorkflowsData_jiraQuery$key = {
  readonly " $data"?: src_workManagementBoard_BoardWithWorkflowsData_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_BoardWithWorkflowsData_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_workManagementBoard_BoardWithWorkflowsData_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "board_workManagementBoard_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e1835e3a124691a8abbad1e1228cc10d";

export default node;
