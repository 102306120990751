import {
	START_DATE_ALIAS_FIELD_ID,
	STORY_POINTS_ALIAS_FIELD_ID,
} from '@atlassian/jira-business-constants/src/index.tsx';
import type { TypesWithFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	DUE_DATE_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	SUMMARY_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SPRINT_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { CreateIssueInput } from '../../types.tsx';

export const hasAllRequiredFields = ({
	issueType,
	createInput,
}: {
	issueType: TypesWithFields | undefined;
	createInput: CreateIssueInput;
}) => {
	if (!issueType) {
		return false;
	}

	return issueType.fields.every((field) => {
		if (!field.fieldConfig?.isRequired) {
			return true;
		}

		if (!field.type && !field.aliasFieldId) {
			return false;
		}

		if (field.aliasFieldId && field.aliasFieldId === START_DATE_ALIAS_FIELD_ID) {
			return Boolean(createInput.startDate);
		}

		if (field.aliasFieldId === STORY_POINTS_ALIAS_FIELD_ID) {
			return createInput.storyPoints != null;
		}

		if (field.type) {
			switch (field.type) {
				case ISSUE_TYPE:
				case REPORTER_TYPE:
					return true;
				case SUMMARY_TYPE:
					return Boolean(createInput.summary);
				case PRIORITY_TYPE:
					return Boolean(createInput.priority);
				case ASSIGNEE_TYPE:
					return Boolean(createInput.assignee);
				case DUE_DATE_TYPE:
					return Boolean(createInput.dueDate);
				case CATEGORY_TYPE:
					return Boolean(createInput.category);
				case STORY_POINT_ESTIMATE_CF_TYPE:
					return createInput.storyPointEstimate != null;
				case SPRINT_TYPE:
					return Boolean(createInput.sprint);
				default:
					break;
			}
		}

		return false;
	});
};
