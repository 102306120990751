import { fg } from '@atlassian/jira-feature-gating';
import { createTraitsSelector } from '@atlassian/jira-traits/src/TraitsStore.tsx';

export const useIsCardCoversNew = createTraitsSelector(
	'SITE_USER',
	({ userCreatedBefore, hasTrait, loaded }) =>
		loaded &&
		!hasTrait('jira-core_ui_cardcovereditor_clicked_first_time') &&
		userCreatedBefore(new Date('2024-10-29')) &&
		fg('jwm_card_covers_changeboarding'),
);
