import React, { memo, useCallback, useState } from 'react';
import type { InlineCreateStyles } from '../types.tsx';
import InlineCreateForm, { type Props as FormProps } from './inline-create-form/index.tsx';
import InlineCreateTrigger from './inline-create-trigger/index.tsx';

export type Props<TIssue> = FormProps<TIssue> & {
	onToggleForm?: (isFormVisible: boolean) => void;
	inlineCreateStyles?: InlineCreateStyles;
	isOpenByDefault?: boolean;
	onIssueTypeCreated?: () => void;
};

const InlineCreate = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	fieldIdsToReturn,
	inlineCreateStyles,
	isCompact,
	location,
	maxHierarchyLevel,
	minHierarchyLevel,
	onBlur,
	onPrepareIssueContext,
	onSubmit,
	onToggleForm,
	isOpenByDefault = false,
	popperStrategy,
	allowIssueTypeCreation,
	onIssueTypeCreated,
}: Props<TIssue>) => {
	const [isFormVisible, setIsFormVisible] = useState(isOpenByDefault);

	const showForm = useCallback(() => {
		setIsFormVisible(true);
		onToggleForm?.(true);
	}, [onToggleForm]);

	const hideForm = useCallback(() => {
		setIsFormVisible(false);
		onToggleForm?.(false);
	}, [onToggleForm]);

	const handleBlur = useCallback(() => {
		hideForm();
		onBlur?.();
	}, [hideForm, onBlur]);

	return isFormVisible ? (
		<InlineCreateForm
			allowedIssueTypeIds={allowedIssueTypeIds}
			appearance={appearance}
			fieldIdsToReturn={fieldIdsToReturn}
			isCompact={isCompact}
			location={location}
			maxHierarchyLevel={maxHierarchyLevel}
			minHierarchyLevel={minHierarchyLevel}
			onBlur={handleBlur}
			onPrepareIssueContext={onPrepareIssueContext}
			onSubmit={onSubmit}
			popperStrategy={popperStrategy}
			allowIssueTypeCreation={allowIssueTypeCreation}
			onIssueTypeCreated={onIssueTypeCreated}
			{...inlineCreateStyles}
		/>
	) : (
		<InlineCreateTrigger onClick={showForm} {...inlineCreateStyles} />
	);
};

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(InlineCreate);
