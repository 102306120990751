import React from 'react';
import LozengeIcon from '@atlaskit/icon-lab/core/lozenge';
import StatusIconLegacy from '@atlaskit/icon/glyph/status';
import { STATUS_CATEGORY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { GetIconFunction } from '../../../icon/types.tsx';

export const getStatusCategoryIcons: GetIconFunction = (size, spacing) => ({
	[STATUS_CATEGORY_TYPE]: (
		<LozengeIcon
			color="currentColor"
			spacing={spacing}
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={StatusIconLegacy}
		/>
	),
});
