import React, { useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import noop from 'lodash/noop';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { asyncCreateIssueTypeModalEntryPoint } from '@atlassian/jira-create-issue-type-modal/entrypoint.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export type CreateIssueTypesModalProps = {
	onIssueTypeCreated?: () => void;
	onOpen: () => void;
	onClose: () => void;
};

export const CreateIssueTypesModal = forwardRef(
	({ onIssueTypeCreated = noop, onOpen, onClose }: CreateIssueTypesModalProps, ref) => {
		const cloudId = useCloudId();
		const { key: projectKey } = useProject();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
			asyncCreateIssueTypeModalEntryPoint,
			{
				projectKey: projectKey ?? '',
				cloudId,
			},
		);

		useImperativeHandle(ref, () => ({
			load: entryPointActions.load,
		}));

		const onCloseCreateIssueTypesModal = useCallback(() => {
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: 'inlineCreateIssueTypesModal',
					action: 'closed',
				}),
			);
			onClose();
		}, [createAnalyticsEvent, onClose]);

		const entryPointProps = useMemo(
			() => ({
				onIssueTypeCreated,
				issueTypesCountLimit: 30,
				onClose: onCloseCreateIssueTypesModal,
				onOpen,
			}),
			[onCloseCreateIssueTypesModal, onIssueTypeCreated, onOpen],
		);

		return (
			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				entryPointActions={entryPointActions}
				runtimeProps={entryPointProps}
				id="create-issue-type-modal"
			/>
		);
	},
);
