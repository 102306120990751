import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { BoardIssue, Group } from '../../common/types.tsx';
import { useIssuesByGroup, useIssuesByGroupOld } from '../issues-by-group/index.tsx';

const EMPTY_ISSUES: BoardIssue[] = [];

type IssueGroup = {
	issues: BoardIssue[];
	group: Group;
};

const IssueGroupContext = createContext<IssueGroup | null>(null);

type IssueGroupProviderProps = { group: Group; children: ReactNode };

export const IssueGroupProvider = ({ group, children }: IssueGroupProviderProps) => {
	const issuesByGroup = fg('fun-1361_refactor_business_board_grouping_logic')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssuesByGroup()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssuesByGroupOld();

	const issues = issuesByGroup.get(group.id) ?? EMPTY_ISSUES;

	const value = useMemo(() => ({ issues, group }), [issues, group]);

	return <IssueGroupContext.Provider value={value}>{children}</IssueGroupContext.Provider>;
};

export const useIssueGroup = (): IssueGroup => {
	const issueGroup = useContext(IssueGroupContext);

	if (!issueGroup) {
		throw new Error('useIssueGroup must be used within a IssueGroupProvider');
	}

	return issueGroup;
};
