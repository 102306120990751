/**
 * @generated SignedSource<<39dfad1a2c36c306997a9bd92010f1c4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardCover_workManagementBoard_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_CardCoverInner_jiraQuery">;
  readonly " $fragmentType": "cardCover_workManagementBoard_jiraQuery";
};
export type cardCover_workManagementBoard_jiraQuery$key = {
  readonly " $data"?: cardCover_workManagementBoard_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cardCover_workManagementBoard_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardCover_workManagementBoard_CardCoverInner_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "91eefdceecfa8276664666671d389566";

export default node;
