/**
 * @generated SignedSource<<36277921a8012e0702a65f1227cbbd1b>>
 * @relayHash 4a7b622560b3b3e20bb696680e8560ab
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d8c35e32d8548b68e2966c6dacdbc893c0768a3dd5dc31d2dfca58cd3e11bc76

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcAsyncCreateIssueTypeQuery$variables = {
  cloudId: string;
  projectKey: string;
  shouldFetchProjectData?: boolean | null | undefined;
};
export type srcAsyncCreateIssueTypeQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly issueTypes: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_createIssueTypeModal_CreateIssueTypeModalWrapper_createIssueTypeFragmentRef">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ui_createIssueTypeModal_CreateIssueTypeModalWrapper_projectDataFragmentRef">;
    };
  };
};
export type srcAsyncCreateIssueTypeQuery = {
  response: srcAsyncCreateIssueTypeQuery$data;
  variables: srcAsyncCreateIssueTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectKey"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "shouldFetchProjectData"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "srcAsyncCreateIssueTypeQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraIssueTypeConnection",
                      "kind": "LinkedField",
                      "name": "issueTypes",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "ui_createIssueTypeModal_CreateIssueTypeModalWrapper_createIssueTypeFragmentRef"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.jiraProjectByKey.issueTypes"
                  },
                  {
                    "condition": "shouldFetchProjectData",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "ui_createIssueTypeModal_CreateIssueTypeModalWrapper_projectDataFragmentRef"
                      }
                    ]
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProjectByKey"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcAsyncCreateIssueTypeQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  },
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "concreteType": "JiraIssueTypeHierarchyLevel",
                            "kind": "LinkedField",
                            "name": "hierarchy",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "level"
                              }
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "condition": "shouldFetchProjectData",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "projectType"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "projectId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "projectStyle"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "status"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "uuid"
                  },
                  (v2/*: any*/),
                  {
                    "concreteType": "JiraAvatar",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "medium"
                      }
                    ]
                  }
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d8c35e32d8548b68e2966c6dacdbc893c0768a3dd5dc31d2dfca58cd3e11bc76",
    "metadata": {},
    "name": "srcAsyncCreateIssueTypeQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b50515a18d5488758aa762560868c40c";

export default node;
