import { useCallback, useMemo } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { COLLAPSED_COLUMNS_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import type { CollapsedColumns } from '../../common/types.tsx';
import { useGroupByField } from '../group-by/index.tsx';
import { useGroups } from '../groups-context/index.tsx';

const emptyColumnKeys: CollapsedColumns = {
	[GROUP_BY_STATUS]: [],
	[GROUP_BY_ASSIGNEE]: [],
	[GROUP_BY_PRIORITY]: [],
	[GROUP_BY_CATEGORY]: [],
};

export const useCollapsedColumnIds = () => {
	const groupBy = useGroupByField();
	const [collapsedColumnsByGroupBy] = useViewPreference(COLLAPSED_COLUMNS_PREFERENCE);

	const collapsedColumns: string[] = useMemo(() => {
		if (collapsedColumnsByGroupBy && typeof collapsedColumnsByGroupBy === 'object') {
			const collapsedKeys = collapsedColumnsByGroupBy[groupBy];
			if (Array.isArray(collapsedKeys)) {
				return collapsedKeys;
			}
		}
		return [];
	}, [collapsedColumnsByGroupBy, groupBy]);

	return collapsedColumns;
};

export const useExpandCollapse = (groupId: string) => {
	const groupBy = useGroupByField();
	const [collapsedColumnsByGroupBy, updateCollapsedColumns] = useViewPreference(
		COLLAPSED_COLUMNS_PREFERENCE,
	);
	const collapsedColumns = useCollapsedColumnIds();

	const isCollapsed = useMemo(
		() => collapsedColumns.includes(groupId),
		[collapsedColumns, groupId],
	);

	const toggleCollapse = useCallback(() => {
		let newCollapsedKeys: string[] = [];

		if (isCollapsed) {
			// Remove ID to expand column
			newCollapsedKeys = collapsedColumns.filter(
				(collapsedColumnKey) => groupId !== collapsedColumnKey,
			);
		} else {
			// Add ID to collapse column
			newCollapsedKeys = [...collapsedColumns, groupId];
		}

		updateCollapsedColumns({
			...(collapsedColumnsByGroupBy ?? emptyColumnKeys),
			[groupBy]: newCollapsedKeys,
		});
	}, [
		collapsedColumns,
		collapsedColumnsByGroupBy,
		groupBy,
		groupId,
		isCollapsed,
		updateCollapsedColumns,
	]);

	return {
		isCollapsed,
		toggleCollapse,
	};
};

export const useExpandCollapseAllColumns = () => {
	const groupBy = useGroupByField();
	const [collapsedColumnsByGroupBy, updateCollapsedColumns] = useViewPreference(
		COLLAPSED_COLUMNS_PREFERENCE,
	);
	const groupColumns = useGroups();

	const collapseAllColumns = useCallback(() => {
		const columnKeys = groupColumns.map((column) => column.id ?? '');
		updateCollapsedColumns({
			...(collapsedColumnsByGroupBy ?? emptyColumnKeys),
			[groupBy]: columnKeys,
		});
	}, [groupBy, groupColumns, collapsedColumnsByGroupBy, updateCollapsedColumns]);

	const expandAllColumns = useCallback(() => {
		updateCollapsedColumns({
			...(collapsedColumnsByGroupBy ?? emptyColumnKeys),
			[groupBy]: [],
		});
	}, [collapsedColumnsByGroupBy, groupBy, updateCollapsedColumns]);

	return {
		collapseAllColumns,
		expandAllColumns,
	};
};
