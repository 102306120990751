import React, { type ReactNode } from 'react';
import Tooltip from '@atlaskit/tooltip';
import type { Selection } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import {
	NodeSelectionWrapper,
	ParticipantBadge,
} from '@atlassian/jira-business-collaboration/src/ui/node-selection/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const CardBadge = ({
	selections,
	children,
	position,
}: {
	children?: ReactNode;
	selections?: Selection[];
	position: string;
}) => {
	const { formatMessage } = useIntl();

	if (!selections) {
		return <>{children}</>;
	}

	const cardSelection = selections[0];

	const tooltipContent =
		selections.length > 1 ? (
			<>{selections.map(({ user }, i) => i !== 0 && <div key={user.name}>{user.name}</div>)}</>
		) : null;

	const otherSelectionsContent =
		selections.length > 1
			? formatMessage(messages.moreSelections, {
					count: selections.length - 1,
				})
			: null;

	return (
		<NodeSelectionWrapper color={cardSelection.color}>
			<Tooltip content={tooltipContent} position="top">
				<ParticipantBadge color={cardSelection.color} position={position}>
					{cardSelection.user.name}
					{otherSelectionsContent}
				</ParticipantBadge>
				{children}
			</Tooltip>
		</NodeSelectionWrapper>
	);
};
