/**
 * @generated SignedSource<<71ddf9d2617369e701b98c92ff7cd5c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_workManagementBoard_Board_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery">;
  readonly " $fragmentType": "src_workManagementBoard_Board_jiraQuery";
};
export type src_workManagementBoard_Board_jiraQuery$key = {
  readonly " $data"?: src_workManagementBoard_Board_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_workManagementBoard_Board_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_workManagementBoard_Board_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "src_workManagementBoard_BoardViewRootWithSmartCard_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "f306d2bac39b1b76dee8655e4114b37e";

export default node;
