/**
 * @generated SignedSource<<ef0b99f9017efaa30907b7dcce3d41c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type card_workManagementBoard_CardCoverWrapper_jiraQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_jiraQuery">;
  readonly " $fragmentType": "card_workManagementBoard_CardCoverWrapper_jiraQuery";
};
export type card_workManagementBoard_CardCoverWrapper_jiraQuery$key = {
  readonly " $data"?: card_workManagementBoard_CardCoverWrapper_jiraQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"card_workManagementBoard_CardCoverWrapper_jiraQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "card_workManagementBoard_CardCoverWrapper_jiraQuery",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardCover_workManagementBoard_jiraQuery"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "2c96cbcf016ff830f5bda56d3d5d389e";

export default node;
