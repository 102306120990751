import memoize from 'lodash/memoize';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getCascadingSelectIcons } from '../field-types/cascading-select/icon/index.tsx';
import { getDevSummaryIcons } from '../field-types/dev-summary/icon/index.tsx';
import { getGoalsIcons } from '../field-types/goals/icon/index.tsx';
import { getGroupIcons } from '../field-types/group/icon/index.tsx';
import { getParentIcon } from '../field-types/parent/icon/index.tsx';
import { getProjectIcons } from '../field-types/project/icon/index.tsx';
import { getRadioSelectIcon } from '../field-types/radio-select/icon/index.tsx';
import { getResolutionIcons } from '../field-types/resolution/icon/index.tsx';
import { getStatusCategoryIcons } from '../field-types/status-category/icon/index.tsx';
import { getTeamIcons } from '../field-types/team/icon/index.tsx';
import { getVersionIcons } from '../field-types/version/icon/index.tsx';
import { getLegacyFieldIcons } from './legacy-icons/index.tsx';
import type { GetIconFunction, IconSize } from './types.tsx';

export const getFieldIcons = memoize<GetIconFunction>((size: IconSize) => {
	const spacing =
		size === 'medium' && isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')
			? 'spacious'
			: 'none';

	const legacyFieldIcons = getLegacyFieldIcons({ size, spacing });

	return {
		...legacyFieldIcons,
		...getVersionIcons(size, spacing),
		...getParentIcon(size, spacing),
		...getDevSummaryIcons(size, spacing),
		...getTeamIcons(size, spacing),
		...getGoalsIcons(size, spacing),
		...getResolutionIcons(size, spacing),
		...getGroupIcons(size, spacing),
		...getStatusCategoryIcons(size, spacing),
		...getRadioSelectIcon(size, spacing),
		...getProjectIcons(size, spacing),
		...getCascadingSelectIcons(size, spacing),
	};
});
