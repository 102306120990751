import uniqBy from 'lodash/uniqBy';
import {
	CATEGORY_TYPE,
	FIX_VERSIONS_TYPE,
	GOALS_CF_TYPE,
	PARENT_FIELD_TYPE,
	SPRINT_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformValuesData } from '../../common/utils/transform-field-values/index.tsx';
import type { TransformedInitialData } from '../../common/utils/transform-field-values/types.tsx';
import type { HydratedValues } from './types.tsx';

export const transformHydratedValues = (data: HydratedValues): TransformedInitialData => {
	return transformValuesData(data);
};

export const mergeHydratedValues = (
	initialData: TransformedInitialData,
	hydratedValues: TransformedInitialData,
) => ({
	labels: {
		totalCount: initialData.labels.totalCount,
		options: uniqBy(
			[...hydratedValues.labels.options, ...initialData.labels.options],
			({ value }) => value,
		),
	},
	components: {
		totalCount: initialData.components.totalCount,
		options: uniqBy(
			[...hydratedValues.components.options, ...initialData.components.options],
			({ value }) => value,
		),
	},
	priority: {
		totalCount: initialData.priority.totalCount,
		options: uniqBy(
			[...hydratedValues.priority.options, ...initialData.priority.options],
			({ value }) => value,
		),
	},
	users: {
		totalCount: initialData.users.totalCount,
		options: uniqBy(
			[...hydratedValues.users.options, ...initialData.users.options],
			({ value }) => value,
		),
	},
	issuetype: {
		totalCount: initialData.issuetype.totalCount,
		options: uniqBy(
			[...hydratedValues.issuetype.options, ...initialData.issuetype.options],
			({ value }) => value,
		),
	},
	status: {
		totalCount: initialData.status.totalCount,
		options: uniqBy(
			[...hydratedValues.status.options, ...initialData.status.options],
			({ value }) => value,
		),
	},
	// hydrate values endpoint doesn't support categories
	[CATEGORY_TYPE]: initialData[CATEGORY_TYPE],
	[SPRINT_TYPE]: {
		totalCount: initialData[SPRINT_TYPE].totalCount,
		options: uniqBy(
			[...hydratedValues[SPRINT_TYPE].options, ...initialData[SPRINT_TYPE].options],
			({ value }) => value,
		),
	},
	[FIX_VERSIONS_TYPE]: {
		totalCount: initialData[FIX_VERSIONS_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[FIX_VERSIONS_TYPE].options || []),
				...(initialData[FIX_VERSIONS_TYPE].options || []),
			],
			({ value }) => value,
		),
	},
	[PARENT_FIELD_TYPE]: {
		totalCount: initialData[PARENT_FIELD_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[PARENT_FIELD_TYPE].options || []),
				...(initialData[PARENT_FIELD_TYPE].options || []),
			],
			({ value }) => value,
		),
	},
	[GOALS_CF_TYPE]: {
		totalCount: initialData[GOALS_CF_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[GOALS_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up native_goals_integration_on_list_view
				...(initialData[GOALS_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up native_goals_integration_on_list_view
			],
			({ value }) => value,
		),
	},
	[TEAMS_PLATFORM_CF_TYPE]: {
		totalCount: initialData[TEAMS_PLATFORM_CF_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[TEAMS_PLATFORM_CF_TYPE].options || []),
				...(initialData[TEAMS_PLATFORM_CF_TYPE].options || []),
			],
			({ value }) => value,
		),
	},
});
