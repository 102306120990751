import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
	COMPOUND_OPERATOR_AND,
	OPERAND_EMPTY,
	OPERATOR_EQUALS,
	OPERATOR_GT_EQUALS,
	OPERATOR_IN,
	OPERATOR_LT,
	creators,
	print,
	type ValueOperand,
} from '@atlaskit/jql-ast';
import type { Filters as FilterType } from '../common/types.tsx';
import { EMPTY_FILTER_VALUE } from '../constants.tsx';

type Jql = string;
type GetShouldConvertCustomField = (fieldName: string) => Boolean;

const START_DATE_JQL = 'Start date[Date]';
const EMPTY = 'EMPTY';

const getQuotedFieldName = (fieldName: string) => `"${fieldName}"`;

const getFieldNameForJqlRaw = (fieldName: string) => {
	// custom fields are indexed by their ids and only work with JQL when referenced as such `cf[id]`.
	if (fieldName.indexOf('customfield_') === 0) {
		const customFieldId = fieldName.split('customfield_')[1];
		return `cf[${customFieldId}]`;
	}

	// date range jql will be formatted to e.g. "Start date[Date]" >= "2021-12-01" AND due < "2021-12-31"
	// the "to" fieldName for the date range will be transformed to "due" in the "serialiseJql" function
	// where "const toFieldName = fieldId === 'dateRange' ? 'due' : fieldName;"
	if (fieldName === 'dateRange') {
		return START_DATE_JQL;
	}

	return fieldName;
};

const getFieldNameForJql = (fieldName: string) =>
	getQuotedFieldName(getFieldNameForJqlRaw(fieldName));

const getFieldIdFromJql = (
	rawFieldName: string,
	getShouldConvertCustomField?: GetShouldConvertCustomField,
) => {
	const fieldName = trim(rawFieldName, '"');

	const shouldConvertCustomField = getShouldConvertCustomField
		? getShouldConvertCustomField(fieldName)
		: true;

	// `cf[id]` -> `customfield_${id}`.
	if (fieldName.indexOf('cf[') === 0 && shouldConvertCustomField) {
		const extraction = new RegExp(/cf\[(.+)\]/).exec(fieldName);

		if (!extraction) {
			return fieldName;
		}

		const fieldId = extraction[1];
		return `customfield_${fieldId}`;
	}

	const startDateJql = START_DATE_JQL;

	// update this if we add a start date or due date filter
	if (fieldName === startDateJql || fieldName === 'due') {
		return 'dateRange';
	}

	return fieldName;
};

const sanitizeWord = (word: string) => word.replace(/(["\\])/g, '\\$1');
const sanitizeWordOld = (word: string) => word.replace(/'/g, "\\'");

export const serialiseJql = (filters: FilterType): Jql => {
	const jqlQuery = creators.query();

	Object.entries(filters)
		.filter(
			([, options]) =>
				options != null &&
				((Array.isArray(options) && options.length > 0) ||
					(!Array.isArray(options) &&
						typeof options === 'object' &&
						(options.value == null || !isEmpty(options.value)))),
		)
		.forEach(([fieldId, options]) => {
			const fieldName = getFieldNameForJqlRaw(fieldId);

			if (!Array.isArray(options) && typeof options === 'object' && !isEmpty(options?.value)) {
				// one additional day will be added to jql 'to' date based on selected date, e.g. when due date is "2021-12-30"
				// date range jql will be formatted to e.g. "Start date[Date]" >= "2021-12-01" AND due < "2021-12-31"
				const toFieldName = fieldId === 'dateRange' ? 'due' : fieldName;

				if (options.value.to != null) {
					const toDate = formatInTimeZone(
						addDays(new Date(options.value.to), 1),
						'UTC',
						'yyyy-MM-dd',
					);
					jqlQuery.appendClause(
						creators.terminalClause(
							creators.field(toFieldName),
							creators.operator(OPERATOR_LT),
							creators.valueOperand(toDate),
						),
						COMPOUND_OPERATOR_AND,
					);
				}

				if (options.value.from != null) {
					jqlQuery.appendClause(
						creators.terminalClause(
							creators.field(fieldName),
							creators.operator(OPERATOR_GT_EQUALS),
							creators.valueOperand(options.value.from),
						),
						COMPOUND_OPERATOR_AND,
					);
				}

				return;
			}

			if (Array.isArray(options)) {
				const operands = options
					.map((option) => {
						if (option.isEmptyOption) {
							return creators.valueOperand(OPERAND_EMPTY);
						}
						if (option.value) {
							// Currently, the AST does not sanatize all values correctly (no \ or JQL keywords), so we need to manually wrap in quotes
							// We can remove this once https://jplat.jira.atlassian.cloud/browse/EM-6563 is implemented
							return creators.byText.valueOperand(`"${sanitizeWord(option.value)}"`);
						}
						return null;
					})
					.filter((operand): operand is ValueOperand => operand != null);

				if (operands.length === 1) {
					jqlQuery.appendClause(
						creators.terminalClause(
							creators.field(fieldName),
							creators.operator(OPERATOR_EQUALS),
							operands[0],
						),
						COMPOUND_OPERATOR_AND,
					);
				} else if (operands.length > 1) {
					jqlQuery.appendClause(
						creators.terminalClause(
							creators.field(fieldName),
							creators.operator(OPERATOR_IN),
							creators.listOperand(operands),
						),
						COMPOUND_OPERATOR_AND,
					);
				}
			}
		});
	const jast = creators.jast(jqlQuery);
	return print(jast, { operatorCase: 'upper', printWidth: null });
};

export const serialiseJqlOld = (filters: FilterType): Jql => {
	const pairs = Object.entries(filters)
		.filter(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([, options]: [any, any]) =>
				options != null &&
				((Array.isArray(options) && options.length > 0) ||
					(!Array.isArray(options) &&
						typeof options === 'object' &&
						(options.value == null || !isEmpty(options.value)))),
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.map(([fieldId, options]: [any, any]) => {
			const fieldName = getFieldNameForJql(fieldId);

			if (!Array.isArray(options) && typeof options === 'object' && !isEmpty(options?.value)) {
				// one additional day will be added to jql 'to' date based on selected date, e.g. when due date is "2021-12-30"
				// date range jql will be formatted to e.g. "Start date[Date]" >= "2021-12-01" AND due < "2021-12-31"
				const toFieldName = fieldId === 'dateRange' ? getQuotedFieldName('due') : fieldName;

				let toDate;

				if (options.value.to) {
					toDate = formatInTimeZone(addDays(new Date(options.value.to), 1), 'UTC', 'yyyy-MM-dd');
				} else {
					toDate = options.value.to;
				}

				if (options.value.from != null && options.value.to != null) {
					return `${fieldName} >= ${options.value.from} AND ${toFieldName} < ${toDate}`;
				}

				if (options.value.from != null) {
					return `${fieldName} >= ${options.value.from}`;
				}

				return `${toFieldName} < ${toDate}`;
			}

			if (Array.isArray(options)) {
				const values = options
					.map((option) =>
						// make sure we don't wrap EMPTY with quotation marks
						option.value === EMPTY_FILTER_VALUE ? EMPTY : `'${sanitizeWordOld(option.value)}'`,
					)
					.join(',');
				if (options.length > 1) {
					return `${fieldName} in (${values})`;
				}
				if (options.length === 1) {
					return `${fieldName} = ${values}`;
				}
			}
			return null;
		});
	return pairs.join(' AND ');
};

const extractField = (statement: string) => {
	let fieldId;

	const dateOperatorRegex = />=|</;
	if (dateOperatorRegex.test(statement)) {
		const operator = statement.includes('>=') ? ' >= ' : ' < ';
		[fieldId] = statement.split(operator);
	}

	if (statement.includes(' = ')) {
		[fieldId] = statement.split(/ = /);
	}

	if (statement.includes(' in ')) {
		[fieldId] = statement.split(' in ');
	}

	if (fieldId) {
		return getFieldIdFromJql(fieldId);
	}
};

// this is used in performance analytics as additional "filter" addribute,
// to measure the impact of various JQL terms on our TTI
export const extractAnalyticsFilterDataFromJQL = (jql?: string | null) => {
	if (!jql) {
		return {};
	}

	return jql
		.split(/ AND | OR /)
		.map(extractField)
		.reduce((acc: { [key: string]: boolean }, curr) => {
			if (curr) {
				acc[curr] = true;
			}
			return acc;
		}, {});
};
