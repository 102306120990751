import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import {
	FILTER_PARAM,
	STORY_POINT_ESTIMATE_ALIAS_FIELD_ID,
	STORY_POINTS_ALIAS_FIELD_ID,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { useIssueTypeFieldConfig } from '@atlassian/jira-business-entity-project/src/services/issue-type-field-config/index.tsx';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { getErrorType } from '@atlassian/jira-business-error-handling/src/utils/get-error-type/index.tsx';
import { fetchComponents } from '@atlassian/jira-business-fetch-components/src/index.tsx';
import { UserResolver } from '@atlassian/jira-business-fetch-users/src/services/get-users/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { User } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { CATEGORY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useQueryParam, useRouter } from '@atlassian/react-resource-router';
import type { FieldConfig, AvatarOption } from '../../common/types.tsx';
import { hydrateFilterValues } from '../../services/hydrate-values/index.tsx';
import { transformHydratedValues } from '../../services/hydrate-values/utils.tsx';
import { useProjectStatuses } from '../../services/project-statuses/main.tsx';
import { useGroupItemValues } from '../group-item-values/index.tsx';
import type { Resolvers, Option, FieldConfigProps } from '../types.tsx';
import type { LabelSuggestions, FieldConfigOptionsDataType, FieldOptionsData } from './types.tsx';
import {
	getFilterFieldConfigAndResolvers,
	transformIssueType,
	transformPriority,
	transformStatuses,
	transformUsers,
	transformLabels,
	transformLabelSuggestions,
} from './utils.tsx';

type LocalFieldConfigStore = {
	initialUsers: {
		data: User[];
		isLoading: boolean;
	};
	initialLabels: {
		data: string[];
		isLoading: boolean;
	};
	initialComponents: {
		data: FieldConfigOptionsDataType;
		isLoading: boolean;
	};
	initialCategories: {
		data: Option[];
		isLoading: boolean;
	};
	projectKey: string | null;
};

const MAX_NUMBER_OF_OPTIONS = 10;
const useFilterMapper = true;
const EMPTY_ARRAY: Array<string> = [];
const EMPTY_USER_ARRAY: User[] = [];
const EMPTY_COMPONENT_ARRAY: FieldConfigOptionsDataType = [];
const EMPTY_CATEGORY_ARRAY: Option[] = [];

export const createInitialStoreData = () => ({
	initialUsers: { data: EMPTY_USER_ARRAY, isLoading: false },
	initialLabels: { data: EMPTY_ARRAY, isLoading: false },
	initialComponents: { data: EMPTY_COMPONENT_ARRAY, isLoading: false },
	initialCategories: { data: EMPTY_CATEGORY_ARRAY, isLoading: false },
	projectKey: null,
});

export const localStore: LocalFieldConfigStore = createInitialStoreData();

const useForceUpdate = () => {
	const [, setValue] = useState<number>(0);

	return useCallback(() => setValue((value) => value + 1), []);
};

export const useFieldConfig = ({
	allowedFieldTypes,
	allowedAliasFieldId,
}: FieldConfigProps): {
	fieldConfig: FieldConfig;
	resolvers: Resolvers;
	loading: boolean;
} => {
	const { formatMessage } = useIntl();
	const [userResolver] = useState(new UserResolver());
	const forceUpdate = useForceUpdate();

	const [priorityFieldConfig] = useIssueTypeFieldConfig({
		screen: 'VIEW',
		issueTypeId: 'default',
		fieldId: 'priority',
	});

	const { data: issueTypeData, loading: loadingIssueTypesAndFields } = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const formattedCategoryOptions = useMemo(() => {
		const categoryOptions =
			issueTypeData.fields.find((field) => field.type === CATEGORY_TYPE)?.fieldConfig
				?.selectOptions ?? [];
		return categoryOptions.map(({ value }) => ({
			label: value,
			value,
		}));
	}, [issueTypeData]);

	const { statuses, loading: loadingStatuses } = useProjectStatuses();

	const [
		{
			match: {
				params: { projectKey },
			},
		},
	] = useRouter();

	const cloudId = useCloudId();
	const [jqlURLParam] = useQueryParam(FILTER_PARAM);
	const jqlURLParamRef = useRef(jqlURLParam);
	const hydratedUsers = useRef<AvatarOption[] | null>(null);

	const fetchInitialUsers = useCallback(async () => {
		localStore.initialUsers.isLoading = true;

		let users: User[] = [];
		const hydratedValues = jqlURLParamRef.current
			? await hydrateFilterValues(cloudId, jqlURLParamRef.current)
			: null;

		if (hydratedValues !== null) {
			const transformedHydratedValues = transformHydratedValues(hydratedValues);
			hydratedUsers.current = transformedHydratedValues.users.options;
		}
		const url = `/rest/api/3/user/search?maxResults=${MAX_NUMBER_OF_OPTIONS}&query=`;
		users = await fetchJson<User[]>(url);
		localStore.initialUsers = { data: users, isLoading: false };
		forceUpdate();
		return users;
	}, [cloudId, forceUpdate]);

	const fetchInitialLabels = useCallback(async () => {
		const url = `/rest/api/3/label?maxResults=${MAX_NUMBER_OF_OPTIONS}`;
		try {
			localStore.initialLabels.isLoading = true;
			const response = await fetchJson<{
				values: string[];
			}>(url);
			const labels = response.values;
			localStore.initialLabels = { data: labels, isLoading: false };
			forceUpdate();
			return labels;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireErrorAnalytics({
				meta: {
					id: 'fetchFilterInitialLabels',
					packageName: 'jiraBusinessFilters',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to fetch initial labels',
					errorType: getErrorType(err),
				},
				error: err,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		localStore.initialLabels = { data: EMPTY_ARRAY, isLoading: false };
		forceUpdate();
		return EMPTY_ARRAY;
	}, [forceUpdate]);

	const fetchUsers = useCallback(
		async (query = '') => {
			if (projectKey != null) {
				const url = `/rest/api/2/user/assignable/search?project=${projectKey}&maxResults=${MAX_NUMBER_OF_OPTIONS}&query=${query}`;
				try {
					const response = await fetchJson<User[]>(url);
					return response;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					fireErrorAnalytics({
						meta: {
							id: 'searchFilterUsers',
							packageName: 'jiraBusinessFilters',
							teamName: 'wanjel',
						},
						attributes: {
							message: 'Failed to search users',
							errorType: getErrorType(err),
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
			return EMPTY_USER_ARRAY;
		},
		[projectKey],
	);

	const fetchProjectComponents = useCallback(
		async (query = '') => {
			if (projectKey != null) {
				try {
					const response = await fetchComponents({
						inputValue: query,
						autoCompleteUrl: `/rest/api/3/project/${projectKey}/component`,
						useFilterMapper,
					});
					return response;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					fireErrorAnalytics({
						meta: {
							id: 'fetchFilterComponents',
							packageName: 'jiraBusinessFilters',
							teamName: 'wanjel',
						},
						attributes: {
							message: 'Failed to fetch components',
							errorType: getErrorType(err),
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
			return [];
		},
		[projectKey],
	);

	const fetchLabels = useCallback(
		(query = '') =>
			fetchJson<LabelSuggestions>(`/rest/api/1.0/labels/suggest?query=${query}`).catch((err) => {
				fireErrorAnalytics({
					meta: {
						id: 'searchFilterLabels',
						packageName: 'jiraBusinessFilters',
						teamName: 'wanjel',
					},
					attributes: {
						message: 'Failed to search labels',
						errorType: getErrorType(err),
					},
					error: err,
					sendToPrivacyUnsafeSplunk: true,
				});
			}),
		[],
	);

	const fetchProjectCategories = useCallback(
		(query = '') => {
			// TODO Replace with back end fetch API
			const filteredCategoriesQuery = formattedCategoryOptions?.filter(
				(
					categoryOption:
						| Option
						| {
								label: string;
								value: string;
						  },
				) => categoryOption.label.toLowerCase().startsWith(query.toLowerCase()),
			);
			return Promise.resolve(filteredCategoriesQuery ?? []);
		},
		[formattedCategoryOptions],
	);

	const {
		fetch: fetchInitialStoryPointsData,
		data: initialStoryPoints,
		loading: loadingStoryPoints,
	} = useGroupItemValues({
		groupFieldId: STORY_POINTS_ALIAS_FIELD_ID,
	});

	const {
		fetch: fetchInitialStoryPointsEstimateData,
		data: initialStoryPointsEstimate,
		loading: loadingStoryPointsEstimate,
	} = useGroupItemValues({
		groupFieldId: STORY_POINT_ESTIMATE_ALIAS_FIELD_ID,
	});

	useEffect(() => {
		if (allowedAliasFieldId != null && allowedAliasFieldId.has(STORY_POINTS_ALIAS_FIELD_ID)) {
			fetchInitialStoryPointsData();
		}
	}, [allowedAliasFieldId, fetchInitialStoryPointsData]);

	useEffect(() => {
		if (
			allowedAliasFieldId != null &&
			allowedAliasFieldId.has(STORY_POINT_ESTIMATE_ALIAS_FIELD_ID)
		) {
			fetchInitialStoryPointsEstimateData();
		}
	}, [allowedAliasFieldId, fetchInitialStoryPointsEstimateData]);

	useEffect(() => {
		if (
			projectKey != null &&
			// Initially fetch for everything if projectKey is updated or users is empty
			(localStore.projectKey !== projectKey || localStore.initialUsers.data === EMPTY_USER_ARRAY)
		) {
			if (localStore.initialUsers.isLoading === false) {
				localStore.projectKey = projectKey;
				fetchInitialUsers();
			}
		}
	}, [
		fetchInitialUsers,
		fetchProjectComponents,
		projectKey,
		fetchInitialLabels,
		fetchProjectCategories,
		forceUpdate,
	]);

	const { data: initialUsers, isLoading: isLoadingUsers } = localStore.initialUsers;
	const { data: initialLabels, isLoading: isLoadingLabels } = localStore.initialLabels;
	const { data: initialComponents, isLoading: isLoadingComponents } = localStore.initialComponents;
	const { data: initialCategories, isLoading: isLoadingCategories } = localStore.initialCategories;

	const fieldOptionsData = useMemo<FieldOptionsData>(
		() => ({
			priority: transformPriority(priorityFieldConfig),
			issuetype: transformIssueType(issueTypeData),
			status: transformStatuses(statuses),
			users: transformUsers(initialUsers),
			labels: transformLabels(initialLabels),
			components: initialComponents,
			[CATEGORY_TYPE]: initialCategories,
			[STORY_POINTS_ALIAS_FIELD_ID]: initialStoryPoints?.options || [],
			[STORY_POINT_ESTIMATE_ALIAS_FIELD_ID]: initialStoryPointsEstimate?.options || [],
		}),
		[
			priorityFieldConfig,
			issueTypeData,
			statuses,
			initialUsers,
			initialLabels,
			initialComponents,
			initialCategories,
			initialStoryPoints?.options,
			initialStoryPointsEstimate?.options,
		],
	);

	fieldOptionsData.users = useMemo(() => {
		if (hydratedUsers.current === null) {
			return fieldOptionsData.users;
		}
		return uniqBy(
			[...(hydratedUsers.current ?? []), ...(fieldOptionsData.users ?? [])],
			({ value }) => value,
		);
	}, [fieldOptionsData]);

	const { fieldConfig, resolvers } = getFilterFieldConfigAndResolvers({
		formatMessage,
		allowedFieldTypes,
		allowedAliasFieldId,
		allFields: issueTypeData.fields,
		fieldOptionsData,
		fetchUsers: (query?: string) => fetchUsers(query).then((users) => transformUsers(users || [])),
		fetchLabels: (query?: string) =>
			fetchLabels(query).then((labels) => (labels ? transformLabelSuggestions(labels) : [])),
		fetchComponents: (query?: string) =>
			fetchProjectComponents(query).then((components) => components),
		fetchCategories: (query?: string) =>
			fetchProjectCategories(query).then((categoriesResponse) => categoriesResponse),
		userResolver,
	});

	const fetching =
		isLoadingUsers ||
		isLoadingLabels ||
		isLoadingComponents ||
		isLoadingCategories ||
		loadingStoryPoints ||
		loadingStoryPointsEstimate;

	return {
		fieldConfig,
		resolvers,
		loading: loadingIssueTypesAndFields || loadingStatuses || fetching,
	};
};
