import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholderText: {
		id: 'business-issue-create.inline-create-form.placeholder-text',
		defaultMessage: 'What needs to be done?',
		description: 'Placeholder text in the summary field to prompt the user to enter a summary.',
	},
	createButton: {
		id: 'business-issue-create.inline-create-form.create-button',
		defaultMessage: 'Create',
		description: 'Text on the create button.',
	},
	createIssueTypes: {
		id: 'business-issue-create.inline-create-form.create-issue-types',
		defaultMessage: 'Create issue type',
		description:
			'Dropdown menu item. Clicking this will open a modal for the user to create an issue types.',
	},
	createIssueTypesIssueTermRefresh: {
		id: 'business-issue-create.inline-create-form.create-issue-types-issue-term-refresh',
		defaultMessage: 'Create work type',
		description:
			'Dropdown menu item. Clicking this will open a modal for the user to create an issue types.',
	},
	selectIssueTypeButton: {
		id: 'business-issue-create.inline-create-form.select-issue-type-button',
		defaultMessage: 'Select issue type. {issueType} currently selected.',
		description: 'Accessible label on the select issue type button.',
	},
	manageIssueTypes: {
		id: 'business-issue-create.inline-create-form.manage-issue-types',
		defaultMessage: 'Manage types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the project issue types.',
	},
	selectIssueTypeButtonIssueTermRefresh: {
		id: 'business-issue-create.inline-create-form.select-issue-type-button-issue-term-refresh',
		defaultMessage: 'Select work type. {issueType} currently selected.',
		description: 'Accessible label on the select issue type button.',
	},
	loadingIssueCreateForm: {
		id: 'business-issue-create.inline-create-form.loading-issue-types',
		defaultMessage: 'Loading issue create form',
		description:
			'Accessibility label for the spinner that is displayed when the issue create form is loading.',
	},
	loadingIssueCreateFormIssueTermRefresh: {
		id: 'business-issue-create.inline-create-form.loading-issue-types-issue-term-refresh',
		defaultMessage: 'Loading issue create form',
		description:
			'Accessibility label for the spinner that is displayed when the issue create form is loading.',
	},
});
