import { useCallback, useEffect } from 'react';
import { HIDE_DONE_ITEMS_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import {
	type UpdateQueryParamAction,
	useViewPreference,
} from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import {
	StatusCategoryTypes,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { SelectValue, LozengeOption, Filters } from '../../common/types.tsx';
import { useQuickFilters } from '../quick-filters/index.tsx';

export const useHideDoneItems = () => {
	const [hideDoneItems, persistHideDoneItems] = useViewPreference(HIDE_DONE_ITEMS_PREFERENCE);

	const setHideDoneItems = useCallback(
		(hide: boolean, action: UpdateQueryParamAction = 'push') =>
			persistHideDoneItems(hide ? 'true' : undefined, action),
		[persistHideDoneItems],
	);

	return [hideDoneItems === 'true', setHideDoneItems] as const;
};

export const useInferHideDoneItemsWithFilters = ({ filters }: { filters: Filters }) => {
	const [hideDoneItems, setHideDoneItems] = useHideDoneItems();
	const { quickFilters } = useQuickFilters();

	useEffect(() => {
		if (hideDoneItems) {
			const isFilteringByDoneStatus =
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(filters && (filters.status as SelectValue<LozengeOption>))?.find(
					(status: LozengeOption) =>
						Number(status.categoryId) === StatusCategoryIds[StatusCategoryTypes.DONE],
				);

			if (isFilteringByDoneStatus || quickFilters?.DONE) {
				setHideDoneItems(false, 'replace');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, quickFilters?.DONE]);
};
