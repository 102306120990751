import { useMemo } from 'react';
import { RANK_ALIAS_FIELD_ID } from '@atlassian/jira-business-constants/src/index.tsx';
import { GROUPING_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import {
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	STATUS_TYPE,
	SUMMARY_TYPE,
	PARENT_TYPE,
	TIME_TRACKING_TYPE,
	TIME_ESTIMATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useFilterFieldIds } from '../board-filters/index.tsx';
import { useSelectedFields } from '../fields-preference/index.tsx';

const SYSTEM_FIELDS = [
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	PRIORITY_TYPE,
	RANK_ALIAS_FIELD_ID,
	STATUS_TYPE,
	SUMMARY_TYPE,
];

export const useFieldIds = () => {
	// use the raw grouping view preference instead of the group-by
	// helpers because it contains the category field id (oh god why)
	const [groupByFieldId] = useViewPreference(GROUPING_PREFERENCE);
	const selectedFields = useSelectedFields();
	const filteredFieldIds = useFilterFieldIds();

	return useMemo(() => {
		const fieldIds = new Set<string>(SYSTEM_FIELDS);
		if (groupByFieldId) {
			fieldIds.add(groupByFieldId);
		}
		for (const fieldId of filteredFieldIds) {
			fieldIds.add(fieldId);
		}
		for (const fieldId of Object.keys(selectedFields)) {
			fieldIds.add(fieldId);
		}

		// The formatting of the timeoriginalestimate relies on the timetrackingsettings field within timetracking: FUN 1015
		if (fieldIds.has(TIME_ESTIMATE_TYPE)) {
			fieldIds.add(TIME_TRACKING_TYPE);
		}

		return Array.from(fieldIds);
	}, [filteredFieldIds, groupByFieldId, selectedFields]);
};
