import {
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import {
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { JiraBusinessAvailableFieldsListView } from './__generated_apollo__/JiraBusinessAvailableFieldsListView';
import { DEFAULT } from './constants.tsx';
import {
	ISSUE_TYPES_AND_FIELDS_TIMELINE_QUERY,
	ISSUE_TYPES_AND_FIELDS_LIST_QUERY,
	ISSUE_TYPES_AND_FIELDS_CALENDAR_QUERY,
	ISSUE_TYPES_AND_FIELDS_BOARD_QUERY,
	ISSUE_TYPES_AND_FIELDS_FORM_QUERY,
	ISSUE_TYPES_AND_FIELDS_FORM_SUBMIT_QUERY,
} from './gql.tsx';
import type {
	BusinessIssueField,
	BusinessIssueType,
	BusinessIssueFieldConfig,
	BusinessSelectOption,
} from './types.tsx';

export const emptyResponse = {
	fields: [],
	issueTypes: [],
} as const;

const emptyArray: Array<{
	fields: Array<BusinessIssueField>;
	issueType: BusinessIssueType;
}> = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nodeToFieldConfig = (node: any, issueType: any): BusinessIssueField => {
	const fieldConfig: BusinessIssueFieldConfig = {
		isRequired: !!node.fieldConfig?.isRequired,
		isEditable: !!node.fieldConfig?.isEditable,
	};

	if (node.type === 'priority') {
		fieldConfig.options = node.priorities.nodes;
		// Set middle priority as default if backend doesn't return any
		if (node.priority) {
			fieldConfig.defaultPriorityId = node.priority.priorityId;
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		} else if (fieldConfig.options.length > 0) {
			fieldConfig.defaultPriorityId =
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				node.priorities.nodes[Math.floor(fieldConfig.options.length / 2)].priorityId;
		}
	}

	if (node.type === 'issuetype') {
		fieldConfig.options = node.issueTypes.nodes;
		fieldConfig.avatarId = issueType.avatarId;
		fieldConfig.avatarUrl = issueType.avatar?.medium;
	}

	if (node.type === 'issuelinks' && node.issueLinkTypeRelations) {
		// 'is blocked by' / 'blocks' is used as the default issue link type
		fieldConfig.defaultIssueLinkTypeId =
			node.issueLinkTypeRelations.nodes.find(
				// @ts-expect-error - TS7006 - Parameter 'linkNode' implicitly has an 'any' type.
				(linkNode) => linkNode.relationName === 'is blocked by',
			)?.linkTypeId ||
			// take the first awailable otherwise
			node.issueLinkTypeRelations.nodes[0]?.linkTypeId;
	}

	if (node.type === MULTI_SELECT_CF_TYPE || node.type === SELECT_CF_TYPE) {
		const fieldOptions: Array<BusinessSelectOption> = [];
		// convert so that it can be used by the select field
		// @ts-expect-error - TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'optionId' implicitly has an 'any' type.
		node.fieldOptions.nodes.forEach(({ value, optionId }) => {
			fieldOptions.push({
				value,
				label: value,
				optionId,
			});
		});
		fieldConfig.selectOptions = fieldOptions;
	}

	if (node.type === MULTI_CHECKBOXES_CF_TYPE) {
		const fieldOptions: Array<BusinessSelectOption> = [];
		// convert so that it can be used by the select field
		// @ts-expect-error - TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'optionId' implicitly has an 'any' type.
		node.fieldOptions.nodes.forEach(({ value, optionId }) => {
			fieldOptions.push({
				value,
				label: value,
				optionId,
			});
		});

		fieldConfig.selectOptions = fieldOptions;
	}

	if (node.type === CATEGORY_TYPE) {
		// @ts-expect-error - TS2304 - Cannot find name 'SelectOption'.
		const fieldOptions: Array<SelectOption> = [];
		// convert so that it can be used by the select field

		// @ts-expect-error - TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'optionId' implicitly has an 'any' type.
		node.fieldOptions.nodes.forEach(({ value, optionId, color }) => {
			fieldOptions.push({
				value,
				label: value,
				optionId,
				color: color?.colorKey ?? null,
			});
		});

		fieldConfig.selectOptions = fieldOptions;
	}

	return {
		id: node.fieldId,
		aliasFieldId: node.aliasFieldId,
		name: node.name,
		type: node.type,
		fieldConfig,
		issueTypes: [],
		__typename: node.__typename,
	};
};

/**
 * @deprecated This method aggregates field configuration ignoring each issue type may have a different
 * configuration. Use transformResultToTypesWithFields instead.
 * Remove in https://jbusiness.atlassian.net/browse/JET-1804
 */
export const transformResult = (
	data?: JiraBusinessAvailableFieldsListView | null,
): {
	fields: BusinessIssueField[];
	issueTypes: BusinessIssueType[];
} => {
	if (data && data.jiraBusinessAvailableFields && data.jiraBusinessAvailableFields.edges) {
		const issueFieldsById: {
			[key: string]: BusinessIssueField;
		} = {};
		const issueTypesById: {
			[key: string]: BusinessIssueType;
		} = {};
		data.jiraBusinessAvailableFields.edges
			.map((edge) => (edge && edge.node) || {})
			.forEach(
				// @ts-expect-error - TS2345 - Argument of type '({ issueType, fields, isDefaultIssueType, }: { issueType: {    name: string;    issueTypeId: string;    type: string;    avatarId: string;    hierarchy?: {        level: number;    };}; fields: {    edges: {        nodes: [];    }[];}; isDefaultIssueType: boolean; }) => void' is not assignable to parameter of type '(value: {}, index: number, array: {}[]) => void'.
				({
					issueType,
					fields,
					isDefaultIssueType,
				}: {
					issueType: {
						name: string;
						issueTypeId: string;
						type: string;
						avatarId: string;
						avatar?: {
							medium: string;
						};
						hierarchy?: {
							level: number;
						};
					};
					fields: {
						edges: {
							nodes: [];
						}[];
					};
					isDefaultIssueType: boolean;
				}) => {
					issueTypesById[issueType.issueTypeId] = {
						id: issueType.issueTypeId,
						isDefault: isDefaultIssueType,
						name: issueType.name,
						subtask: issueType.type === 'SUB_TASK',
						avatarId: issueType.avatarId,
						avatarUrl: issueType.avatar?.medium ?? null,
						hierarchyLevel: issueType.hierarchy?.level ?? 0,
					};
					fields.edges
						// @ts-expect-error - TS2551 - Property 'node' does not exist on type '{ nodes: []; }'. Did you mean 'nodes'?
						.map((edge) => (edge && edge.node) || {})
						.forEach((node) => {
							if (node.fieldId) {
								if (!issueFieldsById[node.fieldId]) {
									issueFieldsById[node.fieldId] = nodeToFieldConfig(node, issueType);
								}
								issueFieldsById[node.fieldId].issueTypes =
									issueFieldsById[node.fieldId].issueTypes || [];
								// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
								issueFieldsById[node.fieldId].issueTypes.push(issueType.issueTypeId);
							}
						});
				},
			);

		return {
			fields: Object.keys(issueFieldsById).map((id) => issueFieldsById[id]),
			issueTypes: Object.keys(issueTypesById).map((id) => issueTypesById[id]),
		};
	}

	// @ts-expect-error - TS2322 - Type '{ readonly fields: readonly []; readonly issueTypes: readonly []; }' is not assignable to type '{ fields: BusinessIssueField[]; issueTypes: BusinessIssueType[]; }'.
	return emptyResponse;
};

export const transformResultToTypesWithFields = (
	data?: JiraBusinessAvailableFieldsListView | null,
): {
	issueType: BusinessIssueType;
	fields: BusinessIssueField[];
}[] => {
	if (data && data.jiraBusinessAvailableFields && data.jiraBusinessAvailableFields.edges) {
		return data.jiraBusinessAvailableFields.edges
			.map((edge) => (edge && edge.node) || {})
			.map(
				// @ts-expect-error - TS2345 - Argument of type '({ issueType, fields, isDefaultIssueType, }: { issueType: {    name: string;    issueTypeId: string;    type: string;    avatarId: string;    hierarchy?: {        level: number;    };}; fields: {    edges: {        nodes: [];    }[];}; isDefaultIssueType: boolean; }) => { issueType: { id: string; isDefault: boolean;...' is not assignable to parameter of type '(value: {}, index: number, array: {}[]) => { issueType: { id: string; isDefault: boolean; name: string; subtask: boolean; avatarId: string; hierarchyLevel: number; }; fields: BusinessIssueField[]; }'.
				({
					issueType,
					fields,
					isDefaultIssueType,
				}: {
					issueType: {
						name: string;
						issueTypeId: string;
						type: string;
						avatarId: string;
						avatar?: {
							medium: string;
						};
						hierarchy?: {
							level: number;
						};
					};
					fields: {
						edges: {
							nodes: [];
						}[];
					};
					isDefaultIssueType: boolean;
				}) => ({
					issueType: {
						id: issueType.issueTypeId,
						isDefault: isDefaultIssueType,
						name: issueType.name,
						subtask: issueType.type === 'SUB_TASK',
						avatarId: issueType.avatarId,
						avatarUrl: issueType.avatar?.medium ?? '',
						hierarchyLevel: issueType.hierarchy?.level ?? 0,
					},
					fields: fields.edges
						// @ts-expect-error - TS2551 - Property 'node' does not exist on type '{ nodes: []; }'. Did you mean 'nodes'?
						.map((edge) => (edge && edge.node) || {})
						.map((node) => nodeToFieldConfig(node, issueType)),
				}),
			);
	}
	return emptyArray;
};

export const JWMQueryByRouteMap = {
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM]: {
		view: 'Form',
		query: ISSUE_TYPES_AND_FIELDS_FORM_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT]: {
		view: 'FormSubmit',
		query: ISSUE_TYPES_AND_FIELDS_FORM_SUBMIT_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR]: {
		view: 'Calendar',
		query: ISSUE_TYPES_AND_FIELDS_CALENDAR_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR_EMBED]: {
		view: 'CalendarEmbed',
		query: ISSUE_TYPES_AND_FIELDS_CALENDAR_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST]: {
		view: 'List',
		query: ISSUE_TYPES_AND_FIELDS_LIST_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST_EMBED]: {
		view: 'ListEmbed',
		query: ISSUE_TYPES_AND_FIELDS_LIST_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE]: {
		view: 'Timeline',
		query: ISSUE_TYPES_AND_FIELDS_TIMELINE_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE_EMBED]: {
		view: 'TimelineEmbed',
		query: ISSUE_TYPES_AND_FIELDS_TIMELINE_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD]: {
		view: 'Board',
		query: ISSUE_TYPES_AND_FIELDS_BOARD_QUERY,
	},
	[ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD_EMBED]: {
		view: 'BoardEmbed',
		query: ISSUE_TYPES_AND_FIELDS_BOARD_QUERY,
	},
	[DEFAULT]: {
		view: 'Common',
		query: ISSUE_TYPES_AND_FIELDS_LIST_QUERY,
	},
} as const;
