import { memo } from 'react';
import { useSubscribe } from '@atlassian/jira-graphql-subscription/src/services/subscribe/index.tsx';
import { ISSUE_CREATED_EVENT } from '../../constants.tsx';
import type { IssueSubscriptionProps } from '../../types.tsx';
import { getResponseError } from '../../utils.tsx';
import { JWM_CREATE_ISSUE_SUBSCRIPTION } from './gql.tsx';
import type { CreateIssueResponse } from './types.tsx';

const operationName = 'JwmIssueCreatedSubscription';

export const CreateIssueSubscription = memo<IssueSubscriptionProps>(
	({ cloudId, projectId, onReceive, onError }: IssueSubscriptionProps) => {
		useSubscribe<CreateIssueResponse>(
			{
				query: JWM_CREATE_ISSUE_SUBSCRIPTION,
				variables: {
					cloudId,
					projectId,
				},
				operationName,
			},
			{
				next: (value) => {
					const error = getResponseError(value);
					if (error) {
						onError(error, operationName);
					} else {
						const payload = value.data?.jira?.onIssueCreatedByProject;
						if (payload) {
							onReceive({
								type: ISSUE_CREATED_EVENT,
								payload,
							});
						}
					}
				},
			},
		);

		return null;
	},
);
