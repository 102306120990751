import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notAvailableMessage: {
		id: 'work-management-board.board.column.transition-zones.not-available-message',
		defaultMessage: 'A workflow rule prevents you from moving the issue this way.',
		description: 'Message for when the drop zone for an issue is restricted',
	},
	notAvailableMessageIssueTermRefresh: {
		id: 'work-management-board.board.column.transition-zones.not-available-message-issue-term-refresh',
		defaultMessage: 'A workflow rule prevents you from moving the work item this way.',
		description: 'Message for when the drop zone for an issue is restricted',
	},
});
