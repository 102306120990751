import qs from 'query-string';
import { getErrorType } from '@atlassian/jira-business-error-handling/src/utils/get-error-type/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Component, FetchComponentsResponse, ComponentOption } from './types.tsx';
import { mapComponentsToOptions, mapComponentsToFilterOptions } from './utils.tsx';

export const fetchComponents = ({
	inputValue,
	autoCompleteUrl,
	useFilterMapper,
	queryParameters,
}: {
	inputValue: string;
	autoCompleteUrl: string;
	useFilterMapper?: boolean;
	queryParameters?: Record<string, string>;
}) => {
	const mapperFunction =
		useFilterMapper === true ? mapComponentsToFilterOptions : mapComponentsToOptions;
	const query = qs.stringify({ query: inputValue, ...queryParameters });
	return performGetRequest(`${autoCompleteUrl}?${query}`)
		.then((response: FetchComponentsResponse) => mapperFunction(response.values))
		.catch((error) => {
			fireErrorAnalytics({
				meta: {
					id: 'fetchProjectComponents',
					packageName: 'jiraBusinessFetchComponents',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to fetch jwm project components',
					errorType: getErrorType(error),
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		});
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Component, ComponentOption };
